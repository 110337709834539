<template>
  <v-form ref="formJobID" :disabled="!isEditing">
    <v-card color="cards" :loading="working">
      <v-toolbar flat color="navBar">
        <JobTag :jobType="jobObject.type" />
        <v-spacer></v-spacer>
        <v-btn
          icon
          v-if="
            (jobStates.includes('artwork') && userRole == 'Designer') ||
            userRoles.includes(userRole)
          "
          @click="isEditing = !isEditing"
        >
          <v-fade-transition leave-absolute>
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-fade-transition>
        </v-btn>
      </v-toolbar>

      <v-card-item :title="'Job ID ' + jobObject.jobID"> </v-card-item>
      <v-divider></v-divider>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="11" xs="11" sm="11" md="11" lg="6" xl="6">
            <v-row justify="center" align="center">
              <v-col cols="12" xs="11" sm="11" md="11" lg="6" xl="6">
                <v-select
                  label="Select Job type"
                  colors="primary"
                  v-model="type"
                  bg-color="navBar"
                  variant="outlined"
                  :items="
                    jobObject.state == 'artwork'
                      ? ['New Job']
                      : ['New Job', 'Repeat Job', 'Plate Only']
                  "
                  :rules="requiredRule"
                ></v-select>
                <v-autocomplete
                  label="Select Agent"
                  colors="primary"
                  v-model="agent"
                  bg-color="navBar"
                  variant="outlined"
                  :items="agents"
                  :loading="working"
                  item-title="name"
                  :rules="requiredRule"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xs="11" sm="11" md="11" lg="6" xl="6">
                <v-autocomplete
                  label="Select Factory/Plant"
                  colors="primary"
                  v-model="factory"
                  bg-color="navBar"
                  variant="outlined"
                  :items="factories"
                  :loading="working"
                  item-title="name"
                  :rules="requiredRule"
                  item-value="id"
                ></v-autocomplete>
                <v-autocomplete
                  label="Select CtP Location"
                  colors="primary"
                  v-model="location"
                  bg-color="navBar"
                  variant="outlined"
                  :items="locations"
                  :loading="working"
                  item-title="name"
                  :rules="requiredRule"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-autocomplete
              label="Select Customer"
              colors="primary"
              v-model="customer"
              bg-color="navBar"
              variant="outlined"
              :items="customers"
              :loading="customersLoading"
              item-title="name"
              item-value="id"
              @update:search="getCustomers"
              @update:modelValue="updateCustomerCode"
              no-data-text="start typing to search"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="6">
            <v-textarea
              label="Description"
              rows="4"
              counter="260"
              v-model="description"
            ></v-textarea>
            <v-row no-gutters>
              <v-col cols="11" xs="11" sm="11" md="11" lg="6" xl="6">
                <div class="text-body-2 mx-4">{{ customerCode }}</div>
                <div class="text-caption mx-4">{{ customerOrganization }}</div>
              </v-col>
              <v-col cols="11" xs="11" sm="11" md="11" lg="6" xl="6">
                <div class="text-body-2 text-right">
                  Created by
                  <p class="text-caption">
                    {{ jobObject.createdBy }} <br />{{ jobObject.created }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          v-if="userRole == 'Administrator' || userRole == 'Manager'"
          :disabled="!isEditing"
          @click="deleteJob"
        >
          Send to trash
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            (jobStates.includes('artwork') && userRole == 'Designer') ||
            userRoles.includes(userRole)
          "
          :loading="working"
          :disabled="!isEditing"
          @click="validateJob(job)"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";

import DialogModal from "@/components/common/dialogModal";
import JobTag from "@/components/job/jobTag";

import { firestore, updateOffSet, deleteArtwork } from "@/firebase";
import { collection, query, startAt } from "firebase/firestore";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";

export default {
  props: {
    jobObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    jobStates: ["artwork", "repro", "osl", "vps", "ctp", "plateout"],
    userRoles: ["Administrator", "Manager", "Moderator"],
    urlPath: "",
    working: true,
    isEditing: false,
    userRole: "",
    factories: [],
    locations: [],
    customers: [],
    agents: [],
    type: "",
    factory: "",
    location: "",
    customersLoading: false,
    customer: "",
    agent: "",
    customerCode: "",
    customerOrganization: "",
    jobID: [],
    description: "",
    dialog: false,
    dialogData: [
      {
        title: "",
        icon: "",
        text: "",
        redirect: "",
      },
    ],
    requiredRule: [(v) => !!v || "This is required"],
    structualIDRules: [
      (value) =>
        value.every((v) => v.length === 0 || v.length >= 4) ||
        "Must be more than 4 characters long.",
    ],
  }),
  components: {
    DialogModal,
    JobTag,
  },
  async created() {
    this.urlPath = this.$route.path;
    this.working = true;
    this.userRole = localStorage.userRole;

    try {
      await this.getFactories();
      await this.getLocations();
      await this.getCustomerShortList();
      await this.getAgents();
      this.type = this.jobObject.type;
      this.factory = this.jobObject.factory;
      this.location = this.jobObject.location;
      this.customer = this.jobObject.customer;
      this.agent = this.jobObject.agent;
      this.jobID = this.jobObject.jobID;
      this.description = this.jobObject.description;
      this.updateCustomerCode();
      this.working = false;
    } catch (error) {
      this.showDialog({
        title: "error",
        icon: "mdi-close-circle-outline",
        text: error,
        redirect: null,
      });
    }
  },
  methods: {
    async getFactories() {
      const q = query(collection(firestore, "factories"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = {
          name: doc.data().name,
          id: doc.data().id,
        };
        this.factories.push(data);
      });
      return;
    },
    async getLocations() {
      const q = query(collection(firestore, "locations"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = {
          name: doc.data().name,
          id: doc.data().id,
          code: doc.data().code,
          organization: doc.data().organization,
        };
        this.locations.push(data);
      });
      return;
    },
    async getCustomerShortList() {
      const q = query(
        collection(firestore, "customers"),
        orderBy("id"),
        startAt(this.jobObject.customer),
        limit(10)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = {
          name: doc.data().name,
          id: doc.data().id,
          code: doc.data().code,
          organization: doc.data().organization,
        };
        this.customers.push(data);
      });
      return;
    },
    async getCustomers(val) {
      if (val && val.length > 2) {
        bus.emit("loadingState", true);
        this.customersLoading = true;

        const q = query(
          collection(firestore, "customers"),
          orderBy("name"),
          startAt(val.toUpperCase()),
          limit(10)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
          this.customersLoading = false;
          bus.emit("loadingState", false);
        } else {
          this.customers = [];
          querySnapshot.forEach((doc) => {
            const data = {
              name: doc.data().name,
              id: doc.data().id,
              code: doc.data().code,
              organization: doc.data().organization,
            };
            this.customers.push(data);
          });

          this.customersLoading = false;
          bus.emit("loadingState", false);
        }
      }
    },
    async getAgents() {
      const q = query(collection(firestore, "agents"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = {
          name: doc.data().name,
          id: doc.data().id,
        };
        this.agents.push(data);
      });
      return;
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateCustomerCode() {
      let foundObject = this.customers.find((obj) => obj.id === this.customer);

      if (foundObject) {
        this.customerCode = "Customer Code: " + foundObject.code;
        this.customerOrganization = "Sales Organization: " + foundObject.organization;
      } else {
        this.customerCode = "";
        this.customerOrganization = "";
      }
    },
    async validateJob() {
      const { valid } = await this.$refs.formJobID.validate();
      if (valid) this.updateJob();
    },
    async updateJob() {
      this.working = true;
      bus.emit("loadingState", true);
      this.showDialog({
        title: "Updating",
        icon: "mdi-note-text",
        text: "Updating Job",
        redirect: null,
      });

      let sendData = {
        state: "artwork",
        type: this.type,
        jobID: this.jobID,
        factory: this.factory,
        location: this.location,
        customer: this.customer,
        agent: this.agent,
        description: this.description,
      };

      await updateOffSet(sendData).then((result) => {
        bus.emit("loadingState", false);
        this.working = false;
        this.showDialog({
          title: result.data.response,
          icon: result.data.icon,
          text: result.data.message,
          redirect: "reload",
        });
      });
    },
    showDialog(data) {
      this.dialogData = data;
      this.dialog = true;
    },
    async deleteJob() {
      this.working = true;
      bus.emit("loadingState", true);
      this.dialogData.title = "Deleting";
      this.dialogData.text = "Sending Job to Trash";
      this.dialogData.icon = "mdi-delete";
      this.dialog = true;

      await deleteArtwork({
        jobID: this.jobObject.jobID,
      }).then((result) => {
        bus.emit("loadingState", false);
        this.working = false;
        this.showDialog({
          title: result.data.response,
          icon: result.data.icon,
          text: result.data.message,
          redirect: "/",
        });
        this.dialog = true;
      });
    },
  },
};
</script>

<style></style>
