<template>
    <v-row justify="end" class="ma-1">
        <v-col cols="auto">
            <v-btn large color="primary" prepend-icon="mdi-plus" @click="newClick">
                New {{ type }}
            </v-btn>
        </v-col>
    </v-row>
    <v-row justify="center">
        <v-dialog v-model="dialogPopup" transition="dialog-bottom-transition" max-width="600">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                </v-toolbar>
                <v-list class="mx-4 mt-6">
                    <v-text-field :label="type + ' name'" required v-model="name" :rules="nameRules"></v-text-field>
                    <v-text-field v-if="this.types == 'materials'" label="type" required v-model="brand"
                        :rules="nameRules"></v-text-field>
                    <v-text-field label="description" required v-model="description" :rules="nameRules"></v-text-field>
                </v-list>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-sheet :color="enabled ? 'success' : 'error'" class="rounded-xl">
                            <v-switch class="mx-8" :label="enabled ? 'Shown to users' : 'Not shown to users'" hide-details
                                inset v-model="enabled"></v-switch>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-btn color="primary" @click="dialogBtn" variant="outlined" class="mx-16 my-6" :loading="working">
                    {{ buttonText }}
                </v-btn>
            </v-card>
        </v-dialog>
        <v-col cols="11" xs="11" sm="11" md="8" lg="11" xl="8">
            <v-data-table :headers="headers" :items="variables" v-model:items-per-page="itemsPerPage"
                :items-per-page-options="[{ value: 10, title: '10' }, { value: 25, title: '25' }, { value: 50, title: '50' }]"
                @update:page="setConditions" item-value="name" class="elevation-1" hover>
                <template v-slot:[`item.enabled`]="{ item }">
                    <v-icon size="small" color="success" v-if="item.enabled">
                        mdi-circle
                    </v-icon>
                    <v-icon size="small" color="error" v-if="!item.enabled">
                        mdi-circle
                    </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon size="small" class="me-2" @click="editVariable(item)">
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog">
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
import DialogModal from "@/components/common/dialogModal";
import bus from "vue3-eventbus";

import { firestore, addvariable } from "@/firebase";
import { collection, query } from "firebase/firestore";
import { doc, setDoc, getDocs, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";

export default {
    data: () => ({
        working: false,
        types: "",
        type: "",
        dialogPopup: false,
        dialogTitle: "",
        dialog: false,
        dialogData: [{
            title: "",
            icon: "",
            text: ""
        }],
        buttonText: "",
        name: "",
        brand: null,
        description: null,
        enabled: true,
        nameRules: [
            (v) => !!v || "This is required",
            (v) => (v && v.length >= 2) || "Must be more than 1 characters",
        ],
        variables: [],
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        lastDoc: null,
        noMore: false,
        editID: null,
    }),
    components: {
        DialogModal,
    },
    computed: {
        headers() {
            if (this.types == 'materials') {
                return [
                    {
                        align: "start",
                        key: "name",
                        sortable: false,
                        title: "Name",
                    },
                    { title: "Type", key: "brand", sortable: false },
                    { title: "Description", key: "description", sortable: false },
                    { title: "Enabled", key: "enabled", sortable: false },
                    { title: "Actions", key: "actions", sortable: false },
                ];
            } else {
                return [
                    {
                        align: "start",
                        key: "name",
                        sortable: false,
                        title: "Name",
                    },
                    { title: "Description", key: "description", sortable: false },
                    { title: "Enabled", key: "enabled", sortable: false },
                    { title: "Actions", key: "actions", sortable: false },
                ];
            }
        }
    },
    created() {
        this.working = true;
        bus.emit("loadingState", true);
        this.dialogTitle = "New " + this.types;
        this.noMore = false;
        this.lastDoc = null;
        this.variables = [];

        this.types = this.$route.path.split("/").slice(1).toString();
        if (this.types == 'factories') {
            this.type = "Factory";
        } else if (this.types == 'plateSizes') {
            this.type = "Plate Size";
        } else {
            this.type = this.types.slice(0, -1)
        }

        this.setConditions();
    },
    methods: {
        newClick() {
            this.dialogTitle = "New " + this.type;
            this.name = "";
            this.brand = null;
            this.description = null;
            this.enabled = true;
            this.buttonText = "Add";
            this.dialogPopup = true;
        },
        async setConditions() {
            const variableRef = collection(firestore, this.types);
            let q;

            if (this.noMore == false && this.lastDoc == null) {
                q = await query(variableRef, orderBy("name"), limit(50));
                this.getVariables(q);
            } else if (this.noMore == false && this.lastDoc != null) {
                q = query(
                    variableRef,
                    orderBy("name"),
                    startAfter(this.lastDoc),
                    limit(50)
                );
                this.getVariables(q);
            } else {
                console.log("No more");
            }
        },
        async getVariables(q) {
            const querySnapshot = await getDocs(q);

            if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
                this.noMore = true;
            } else {
                this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc) => {
                    const data = {
                        name: doc.data().name,
                        brand: doc.data().brand != undefined ? doc.data().brand : null,
                        description: doc.data().description == undefined || doc.data().description == '' || doc.data().description == null ? " - " : doc.data().description,
                        enabled: doc.data().enabled,
                        id: doc.data().id,
                    };
                    this.variables.push(data);
                });
            }

            this.working = false;
            bus.emit("loadingState", false);
        },
        dialogBtn() {
            if (this.dialogTitle == "Edit " + this.type) {
                this.addVariable("Updating", this.editID);
            } else {
                this.addVariable("Adding", null);
            }
        },
        async addVariable(nameType, ID) {
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = nameType;
            this.dialogData.text = nameType + " Variable";
            this.dialogData.icon = "mdi-backburger";
            this.dialog = true;

            if (this.types == "plateSizes") {
                this.description = parseFloat(this.description);
            }

            await addvariable({
                id: ID,
                type: this.types,
                name: this.name,
                brand: this.brand,
                description: this.description,
                enabled: this.enabled,
            }).then((result) => {
                this.dialogData.title = result.data.response;
                this.dialogPopup = false;
                this.name = "";
                this.brand = null;
                this.description = null;
                this.enabled = true;
                this.noMore = false;
                this.lastDoc = null;
                this.variables = [];
                this.setConditions();
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialog = true;
                setTimeout(() => this.dialog = false, 2000);
            });
        },
        editVariable(item) {
            this.dialogTitle = "Edit " + this.type;
            this.buttonText = "Update";
            this.name = item.name;
            this.brand = item.brand;
            this.description = item.description;
            this.enabled = item.enabled;
            this.dialogPopup = true;
            this.editID = item.id;
        },
    },
};
</script>

<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform 0.2s ease-in-out;
}
</style>