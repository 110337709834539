<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-btn
        class="ml-2"
        large
        color="secondary"
        prepend-icon="mdi-plus"
        @click="updateApp"
        :loading="working"
      >
        Set App for Update
      </v-btn>
      <v-data-table
        :headers="headers"
        :items="logs"
        v-model:page="page"
        :loading="working"
        item-key="docID"
        class="elevation-1"
        @update:page="loadMore"
        :items-per-page="pageSize"
        :items-per-page-options="[
          { value: 10, title: '10' },
          { value: 50, title: '50' },
          { value: 100, title: '100' },
        ]"
        :mobile="this.$vuetify.display.mobile"
      >
        <template v-slot:[`item.icon`]="{ item }">
          <v-icon :icon="item.icon" :color="item.color"></v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import bus from "vue3-eventbus";

import { firestore, setUpdate } from "@/firebase";
import { collection, query } from "firebase/firestore";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";
import moment from "moment";

export default {
  data: () => ({
    version: process.env.npm_package_version,
    working: true,
    docRef: null,
    lastDoc: null,
    noMore: false,
    logs: [],
    pageSize: 10,
    page: 1,
    pageCount: 0,
    headers: [
      {
        title: "Time",
        align: "start",
        key: "timestamp",
        width: "18%",
      },
      { title: "Level", key: "icon", width: "1%" },
      { title: "Event message", key: "message" },
    ],
    isInitialLoad: true,
  }),
  created() {
    bus.emit("loadingState", true);
    this.getLogsSetup();
    console.log(process.env.npm_package_version);
  },
  methods: {
    async updateApp() {
      this.working = true;
      bus.emit("loadingState", true);

      try {
        await setUpdate().then((result) => {
          bus.emit("loadingState", false);
          this.working = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getLogsSetup() {
      const logsRef = collection(firestore, "logs");
      let q;

      if (this.lastDoc == null) {
        q = await query(logsRef, orderBy("timestamp", "desc"), limit(this.pageSize * 12));
        this.getLogs(q);
      } else if (this.noMore == false && this.lastDoc != null) {
        q = query(
          logsRef,
          orderBy("timestamp", "desc"),
          startAfter(this.lastDoc),
          limit(this.pageSize)
        );
        this.getLogs(q);
      } else {
        console.log("No more");
      }
    },
    async getLogs(q) {
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
        this.noMore = true;
      } else {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((doc) => {
          const data = {
            response: doc.data().response,
            icon: doc.data().icons,
            color: this.getColor(doc.data().icons),
            message: doc.data().message,
            timestamp: this.formatDateWithTime(doc.data().timestamp.toDate()),
          };
          this.logs.push(data);
        });
      }

      this.working = false;
      bus.emit("loadingState", false);
    },
    loadMore() {
      if (!this.working && !this.noMore) {
        this.getLogsSetup();
      }
    },
    formatDateWithTime(timeStamp) {
      var m = moment(timeStamp);
      var mFormatted = m.format("DD-MM-YYYY - hh:mm:ss a");
      return mFormatted;
    },
    getColor(icon) {
      if (
        icon == "mdi-fast-forward" ||
        icon == "mdi-plus-circle" ||
        icon == "mdi-plus-circle-multiple"
      ) {
        return "green";
      } else if (
        icon == "mdi-rewind" ||
        icon == "mdi-close-circle-outline" ||
        icon == "mdi-database-alert"
      ) {
        return "red";
      } else if (icon == "mdi-update") {
        return "orange";
      } else if (icon == "mdi-palette") {
        return "purple";
      } else if (icon == "mdi-database-marker") {
        return "blue";
      } else if (icon == "mdi-note-plus") {
        return "#556B2F";
      } else if (icon == "mdi-merge") {
        return "indigo";
      } else if (icon == "mdi-check-all") {
        return "primary";
      } else if (icon == "mdi-wallet") {
        return "teal";
      } else if (icon == "mdi-spellcheck") {
        return "success";
      } else if (icon == "mdi-delete") {
        return "error";
      } else {
        return;
      }
    },
  },
};
</script>

<style></style>
