<template>
    <div class="card-container">
        <v-card v-for="card in cards" :key="card.id" :color="card.color">
            <div class="text-button mx-2">{{ card.id }}</div>
            <div class="text-caption text-center">{{ card.jobID }}</div>
        </v-card>
    </div>
</template>

<script>
import bus from "vue3-eventbus";

import { firestore } from "@/firebase";
import { collection, query, startAt } from "firebase/firestore";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";

export default {
    data: () => ({
        connection: true,
        cards: []
    }), async created() {
        const q = query(collection(firestore, "films"), orderBy("docID"));
        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                jobID: data.jobID == null || data.jobID == undefined || data.jobID == '' ? "Empty" : data.jobID,
            };
        });

        for (let i = 0; i < 26; i++) {
            for (let j = 1; j <= 20; j++) {
                const id = String.fromCharCode(65 + i) + String(j).padStart(2, '0');
                const index = docs.findIndex(doc => doc.id === id);

                if (index !== -1) {
                    this.cards.push({
                        id,
                        jobID: docs[index].jobID,
                        color: docs[index].jobID == 'Empty' ? 'cards' : 'info'
                    })
                } else {
                    this.cards.push({
                        id,
                        jobID: "",
                        color: 'cards'
                    })
                }
            }
        }

        bus.emit("loadingState", false);
    },
    methods: {
    }
}
</script>

<style scoped>
.card-container {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    /* 26 columns */
    grid-gap: 3px;
    /* adjust as needed */
}
</style>