<template>
  <v-card>
    <v-card-title><v-checkbox label="QC Checked" v-model="checked"></v-checkbox></v-card-title>
    <v-card-text>
      <NoInternet v-if="connection" />
      <div v-else class="progress-container">
        <v-progress-circular v-if="working" class="my-16" :size="70" :width="7" color="primary"
          indeterminate></v-progress-circular>
        <v-virtual-scroll v-else :items="saleOrders" height="350" item-height="50">
          <template v-slot:default="{ item }">
            <v-list-item>
              <v-list-item-title>
                <p class="text-overline">Sales Order: </p><strong>{{ item.saleOrder }}</strong>
              </v-list-item-title>
              <template v-slot:append>
                <v-btn :disabled="!checked" size="small" variant="tonal" append-icon="mdi-merge" color="repro"
                  @click="merge(item.saleOrder)">
                  Merge
                </v-btn>
              </template>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </div>
    </v-card-text>
  </v-card>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import DialogModal from "@/components/common/dialogModal";
import NoInternet from "@/components/common/noInternet";

import { firestore, mergeArtwork } from "@/firebase";
import { collection, query } from "firebase/firestore";
import { doc, getDocs, getDoc, where, orderBy, limit } from "firebase/firestore";

export default {
  props: ["itemObject"],
  data: () => ({
    working: true,
    connection: false,
    checked: false,
    saleOrders: [],
    dialog: false,
    dialogData: [{
      title: "",
      icon: "",
      text: ""
    }],
  }),
  components: {
    NoInternet,
    DialogModal,
  },
  created()
  {
    this.getSalesOrders();
  },
  methods: {
    async getSalesOrders()
    {
      this.working = true;
      bus.emit("loadingState", true);
      const jobsRef = collection(firestore, "jobs");
      const q = query(jobsRef, where("currState", "==", "repro"), orderBy("modified", "desc"));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.metadata.fromCache)
      {
        this.connection = true;
      } else
      {
        for (let i = 0; i < querySnapshot.docs.length; i++)
        {
          this.saleOrders.push({
            state: querySnapshot.docs[i].data().currState,
            saleOrder: querySnapshot.docs[i].data().SO,
          });
        }
      }

      this.working = false;
      bus.emit("loadingState", false);
    },
    async merge(saleOrder)
    {
      this.working = true;
      bus.emit("loadingState", true);
      this.showDialog({
        title: "Merging",
        icon: "mdi-merge",
        text: "Merging Artwork Charges",
        redirect: null
      });

      try
      {
        const result = await mergeArtwork({
          jobID: this.itemObject.jobID,
          docID: this.itemObject.docID,
          saleOrder: saleOrder,
        });

        this.showDialog({
          title: result.data.response,
          icon: result.data.icon,
          text: result.data.message,
          redirect: "/artworks/artwork"
        });
      } catch (error)
      {
        this.showDialog({
          title: "Error",
          icon: "mdi-alert",
          text: "An error occurred while merging the artwork.",
          redirect: null,
        });
      } finally
      {
        this.working = false;
        bus.emit("loadingState", false);
        this.dialog = true;
      }
    },
    showDialog(data)
    {
      this.dialogData = data;
      this.dialog = true;
    }
  },
}
</script>

<style scoped>
.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>