<template>
  <v-row justify="center" align="center" no-gutters class="mt-2">
    <v-col cols="auto">
      <p class="text-caption">{{ stageObject.user }} - {{ stageObject.time }}</p>
    </v-col>
    <v-col cols="1"> </v-col>
    <v-col
      cols="auto"
      class="text-center"
      v-for="(text, index) in stageObject.text"
      :key="index"
    >
      <div class="text-caption ml-1">{{ text }}</div>
    </v-col>
  </v-row>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";

export default {
  props: ["stageObject"],
  components: {
    //UserTimeStrip
  },
};
</script>

<style></style>
