<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" sm="6" md="4">
      <v-file-input label="Select customer list" @change="onFileChange"></v-file-input>
    </v-col>
  </v-row>
  <v-data-table :headers="headers" :items="items" :loading="working" />
  <v-btn @click="addCustomersFunc" color="primary" :loading="working" :disabled="done">
    Send to database
  </v-btn>
</template>

<script>
import bus from "vue3-eventbus";
import readXlsxFile from 'read-excel-file'

import { firestore, addCustomers } from "@/firebase";

export default {
  data() {
    return {
      working: false,
      added: 0,
      done: false,
      items: [],
      headers: [
        { title: 'Name', key: 'name' },
        { title: 'Code', key: 'code' },
        { title: 'Organization', key: 'organization' },
        { title: 'Status', key: 'status' }
      ]
    }
  },
  created() {
    this.working = false;
    bus.emit("loadingState", false);
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0]
      readXlsxFile(file).then((rows) => {
        // Skip the header row (`rows[0]`)
        this.items = rows.slice(1).map((row) => ({
          name: row[0],
          code: row[1],
          organization: row[2],
          status: ''
        }))
      })
    },
    async addCustomersFunc() {
      this.working = true;
      bus.emit("loadingState", true);
      if (this.added < this.items.length) {
        this.items[this.added].status = 'sending';

        await addCustomers({
          name: this.items[this.added].name.toLocaleUpperCase(),
          code: this.items[this.added].code,
          organization: this.items[this.added].organization.toLocaleUpperCase(),
        }).then((result) => {
          console.log(result);
          if (result.data.response == 'success') {
            this.items[this.added].status = result.data.message;
            this.added++;
            this.addCustomersFunc();
          } else {
            this.items[this.added].status = result.data.message;
            this.working = false;
            bus.emit("loadingState", false);
          }
        }).catch((error) => {
          console.log("Error code - " + error.code);
          console.log("Error message - " + error.message);
          console.log("Error details - " + error.details);
          this.working = false;
          bus.emit("loadingState", false);
        });

      } else {
        this.done = true;
        this.working = false;
        bus.emit("loadingState", false);
      }
    }
  }
}
</script>
