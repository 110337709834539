<template>
    <v-data-table :height="tableHeight" class="bg-navBar my-data-table" :headers="headers" :items="rows"
        v-model:items-per-page="itemsPerPage" item-key="SO" :loading="working" v-model:page="page" @update:page="loadMore"
        item-selectable="selectable" fixed-header
        :items-per-page-options="[{ value: 10, title: '10' }, { value: 50, title: '50' }, { value: 100, title: '100' }, { value: 250, title: '250' }]"
        item-value="name" hover>
        <template v-slot:[`item.amount`]="{ item }">
            <div class="text-end">
                {{ item.amount }} USD
            </div>
        </template>
        <template v-slot:[`footer.prepend`]>
            <v-row justify="start">
                <v-col cols="auto">
                    <v-btn class="ml-3" variant="tonal" size="small" :loading="working" :disabled="!noMore"
                        @click="downloadExcel">
                        Download loaded
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import moment from "moment";
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

import DialogModal from "@/components/common/dialogModal";
import { firestore } from "@/firebase";
import { collection, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore";
import { doc, where, getDoc } from "firebase/firestore";

export default {
    data: () => ({
        working: true,
        tableHeight: window.innerHeight * 0.82,
        headers: [
            { title: 'Type', key: 'type' },
            { title: 'Created', key: 'created', width: 110 },
            { title: 'Completed', key: 'modified' },
            { title: 'Sales Order', key: 'SO' },
            { title: 'Job ID', key: 'jobID' },
            { title: 'PO', key: 'PO' },
            { title: 'Description', key: 'description' },
            { title: 'Agent', key: 'agent' },
            { title: 'Customer', key: 'customer' },
            { title: 'Factory', key: 'factory' },
            { title: 'Location', key: 'location' },
            { title: 'Total Mins', key: 'totalMins' },
            { title: 'Rate (USD)', key: 'rate' },
            { title: 'Billable Mins', key: 'billableMins' },
            { title: 'Amount', key: 'amount' },
        ],
        rows: [],
        page: 1,
        itemsPerPage: 100,
        noMore: false,
        lastDoc: null,
        reportName: null,
        dialog: false,
        dialogData: [
            {
                title: "",
                icon: "",
                text: "",
            },
        ],
    }),
    components: {
        DialogModal,
    },
    computed: {
        pageCount() {
            return Math.ceil(this.rows.length / this.itemsPerPage)
        },
    },
    async created() {
        this.working = true;
        bus.emit("loadingState", true);
        this.setConditions();
    },
    methods: {
        async setConditions() {
            bus.emit("loadingState", true);
            const jobsRef = collection(firestore, "reportCharges", this.$route.params.reportID, "report");
            let q;

            if (this.noMore == false && this.lastDoc == null) {
                q = await query(jobsRef, orderBy("modified", "desc"), limit(this.itemsPerPage * 4));
                this.getJobs(q);
            } else if (this.noMore == false && this.lastDoc != null) {
                q = await query(jobsRef, orderBy("modified", "desc"), startAfter(this.lastDoc), limit(this.itemsPerPage * 3));
                this.getJobs(q);
            } else {
                this.working = false;
                bus.emit("loadingState", false);
            }
        },
        async getJobs(q) {
            const querySnapshot = await getDocs(q);

            if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
                this.noMore = true;
            } else {
                this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    this.rows.push(querySnapshot.docs[i].data());
                }
            }

            if (querySnapshot.docs.length <= (this.itemsPerPage * 4)) {
                this.loadMore();
            }
            this.working = false;
            bus.emit("loadingState", false);
        },
        loadMore() {
            if (this.page >= (this.pageCount - 1)) {
                this.setConditions();
            }
        },
        downloadExcel() {
            this.working = true;
            const wb = utils.book_new();
            const formattedRows = this.rows.map(row => {
                let formattedRow = {};
                this.headers.forEach(header => {
                    formattedRow[header.title] = row[header.key];
                });
                return formattedRow;
            });
            const ws = utils.json_to_sheet(formattedRows, { header: this.headers.map(header => header.title), cellStyles: true });
            ws['!cols'] = this.headers.map(() => ({ wch: 12 })); // optional - sets a default column width
            utils.book_append_sheet(wb, ws, 'Sheet1');
            const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
            const data = new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' });
            saveAs(data, this.$route.params.reportID + '.xlsx');
            this.working = false;
        },
        s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
    }
}
</script>

<style></style>
