<template>
  <v-row justify="end" class="ma-1">
    <v-col cols="auto">
      <v-btn large color="primary" prepend-icon="mdi-plus" to="/jobs/create">
        New Job
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {

}
</script>

<style>

</style>