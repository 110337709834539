<template>
  <NoInternet v-if="connection" />
  <v-row v-if="!connection" justify="end" align="start">
    <v-col cols="auto">
      <v-btn color="primary" @click="createCustomer">Create New</v-btn>
    </v-col>
  </v-row>
  <NoContent
    v-if="empty"
    componentColor="primary"
    icon="mdi-alert-box"
    text="No Customers"
  />
  <Spinner v-if="working && customers.length == 0" componentColor="primary" />
  <v-row justify="center">
    <v-col cols="12" v-for="(customer, index) in customers" :key="index">
      <v-card class="mx-auto" border flat color="cards">
        <v-card-text class="text-medium-emphasis pa-6">
          <v-row justify="start" align="center" no-gutters>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="customer.name"
                :disabled="!customer.editing"
                label="Customer Name"
                variant="outlined"
                :rules="[rules.required, rules.minLength(2)]"
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
          </v-row>
          <v-row justify="center" align="start">
            <v-col cols="12" md="6">
              <v-combobox
                chips
                multiple
                rounded
                variant="outlined"
                label="Email To"
                v-model="customer.to"
                :disabled="!customer.editing"
              >
                <template v-slot:selection="data">
                  <v-chip :selected="data.selected" class="chip-class" size="large">
                    {{ data.item.title }}
                  </v-chip>
                </template>
              </v-combobox>
              <v-combobox
                chips
                multiple
                rounded
                variant="outlined"
                label="Email CC"
                v-model="customer.cc"
                :disabled="!customer.editing"
              >
                <template v-slot:selection="data">
                  <v-chip :selected="data.selected" class="chip-class" size="large">
                    {{ data.item.title }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                rounded
                label="Email Subject"
                variant="outlined"
                v-model="customer.subject"
                :disabled="!customer.editing"
                :rules="[rules.required]"
              ></v-text-field>
              <v-row justify="space-between" align="center">
                <v-col cols="auto">
                  <v-switch
                    v-model="customer.active"
                    inset
                    color="success"
                    :label="customer.active ? 'ACTIVE' : 'DISABLED'"
                    :disabled="!customer.editing"
                  ></v-switch>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="!customer.editing"
                    color="primary"
                    variant="flat"
                    @click="editCustomer(customer)"
                    >Edit</v-btn
                  >
                  <v-btn
                    v-else
                    color="primary"
                    variant="tonal"
                    @click="editCustomer(customer)"
                    >Close</v-btn
                  >
                  <v-btn
                    class="ml-2"
                    color="success"
                    variant="flat"
                    @click="
                      customer.isNew
                        ? saveNewCustomer(customer)
                        : updateCustomer(customer)
                    "
                    :disabled="!customer.editing"
                  >
                    {{ customer.isNew ? "Save" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import Spinner from "@/components/common/spinner";
import NoContent from "@/components/common/noJobs";
import NoInternet from "@/components/common/noInternet";
import DialogModal from "@/components/common/dialogModal";

import { firestore } from "@/firebase";
import { collection, getDocs, doc, addDoc, updateDoc } from "firebase/firestore";

export default {
  data: () => ({
    working: true,
    connection: false,
    empty: false,
    customers: [],
    dialog: false,
    dialogData: {},
    rules: {
      required: (value) => !!value || "Required.",
      minLength: (min) => (value) => value.length >= min || `Min ${min} characters`,
    },
  }),
  components: {
    Spinner,
    NoContent,
    NoInternet,
    DialogModal,
  },
  created() {
    bus.emit("loadingState", true);
    this.getCustomers();
  },
  methods: {
    async getCustomers() {
      try {
        const customersRef = collection(firestore, "automation", "invoice", "customers");
        const customersSnapshot = await getDocs(customersRef);

        if (customersSnapshot.metadata.fromCache) {
          this.connection = true;
        } else if (customersSnapshot.empty) {
          this.empty = true;
        } else {
          this.customers = customersSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            editing: false, // Add editing state
          }));
        }
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      } finally {
        this.working = false;
        bus.emit("loadingState", false);
      }
    },
    editCustomer(customer) {
      customer.editing = !customer.editing;
    },
    async updateCustomer(customer) {
      if (!customer.name || customer.name.length < 2) {
        this.showDialog(
          "Error",
          "mdi-alert-circle-outline",
          "Name cannot be empty or less than 2 characters."
        );
        return;
      }
      if (!customer.subject) {
        this.showDialog("Error", "mdi-alert-circle-outline", "Subject cannot be empty.");
        return;
      }

      this.showDialog("Updating", "mdi-cloud-sync", "Updating Customer details");
      try {
        this.working = true;

        // Get a reference to the customer document
        const customerDocRef = doc(
          firestore,
          "automation",
          "invoice",
          "customers",
          customer.id
        );

        // Update the customer data in Firestore
        await updateDoc(customerDocRef, {
          name: customer.name,
          to: customer.to,
          cc: customer.cc,
          subject: customer.subject,
          active: customer.active,
        });

        // Disable editing mode after successful update
        customer.editing = false;
        this.dialog = false;

        bus.emit("loadingState", false);
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      } finally {
        this.working = false;
      }
    },
    createCustomer() {
      const newCustomer = {
        name: "",
        to: [],
        cc: [],
        subject: "",
        active: true,
        editing: true,
        isNew: true,
      };
      this.customers.unshift(newCustomer);
    },
    async saveNewCustomer(customer) {
      if (!customer.name || customer.name.length < 2) {
        this.showDialog(
          "Error",
          "mdi-alert-circle-outline",
          "Name cannot be empty or less than 2 characters."
        );
        return;
      }
      if (!customer.subject) {
        this.showDialog("Error", "mdi-alert-circle-outline", "Subject cannot be empty.");
        return;
      }

      this.showDialog("Saving", "mdi-cloud-sync", "Saving New Customer details");
      try {
        this.working = true;

        // Add the new customer data to Firestore
        const customerRef = collection(firestore, "automation", "invoice", "customers");
        await addDoc(customerRef, {
          name: customer.name,
          to: customer.to,
          cc: customer.cc,
          subject: customer.subject,
          active: customer.active,
        });

        // Disable editing mode after successful save
        customer.editing = false;
        customer.isNew = false;
        this.dialog = false;

        bus.emit("loadingState", false);
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      } finally {
        this.working = false;
      }
    },
    showDialog(title, icon, message, action = null) {
      this.dialogData = {
        title: title,
        icon: icon,
        text: message,
        redirect: action,
      };
      this.dialog = true;
    },
  },
};
</script>
