<template>
  <v-form class="mt-4" ref="formVPS" :disabled="working">
    <v-row justify="center" align="center">
      <v-col cols="12" lg="12" xl="12">
        <v-card color="cards">
          <!------------------------------------------------------------------------------------------------------------------- VPS----->
          <v-toolbar flat color="navBar" title="IMPOSITION / VPS"
            ><Completer :jobObject="jobObject"
          /></v-toolbar>
          <v-row justify="center" align="center" no-gutter class="mr-4 mt-4">
            <v-col cols="8">
              <v-row justify="start" align="center" class="mx-4" no-gutters>
                <v-col
                  cols="auto"
                  v-for="item in checkItems"
                  :key="item"
                  :style="{ 'margin-bottom': '-16px' }"
                >
                  <v-checkbox
                    class="mx-3 my-0"
                    density="compact"
                    v-model="item.state"
                    :label="item.label"
                    :value="item.label"
                    color="success"
                    :rules="requiredRule"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-select
                label="Select Task"
                colors="primary"
                v-model="task"
                bg-color="navBar"
                variant="outlined"
                :items="taskList"
                :rules="requiredRule"
              ></v-select>
              <v-textarea
                label="Note"
                rows="3"
                counter="260"
                v-model="note"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row justify="center" class="mx-2 mb-2">
            <v-col cols="12" lg="6">
              <v-card color="vps" rounded class="rounded-xl" variant="tonal">
                <v-row no-gutters>
                  <v-col cols="auto" class="mt-6">
                    <GripperSVG class="mx-2" :height="80"></GripperSVG>
                  </v-col>
                  <v-col cols="9">
                    <v-col class="text-left">
                      <span class="text-h2 font-weight-light" v-text="gripper"></span>
                      <span class="subheading font-weight-light me-1"> mm</span>
                    </v-col>
                    <v-slider
                      v-model="gripper"
                      color="primary"
                      track-color="grey"
                      min="0"
                      max="99"
                      :step="1"
                      label="Imposition Gripper"
                      :rules="requiredRule"
                    >
                      <template v-slot:prepend>
                        <v-btn
                          size="small"
                          variant="text"
                          icon="mdi-minus"
                          color="error"
                          @click="decrement('mm')"
                        ></v-btn>
                      </template>

                      <template v-slot:append>
                        <v-btn
                          size="small"
                          variant="text"
                          icon="mdi-plus"
                          color="success"
                          @click="increment('mm')"
                        ></v-btn>
                      </template>
                    </v-slider>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" lg="6">
              <v-row>
                <v-col cols="12">
                  <v-col class="text-left">
                    <span class="text-h2 font-weight-light" v-text="mins"></span>
                    <span class="subheading font-weight-light me-1"> mins</span>
                  </v-col>
                  <v-slider
                    v-model="mins"
                    color="primary"
                    track-color="grey"
                    min="0"
                    max="1000"
                    :step="10"
                    label="Charge"
                  >
                    <template v-slot:prepend>
                      <v-btn
                        size="small"
                        variant="text"
                        icon="mdi-minus"
                        color="error"
                        @click="decrement('mins')"
                      ></v-btn>
                    </template>

                    <template v-slot:append>
                      <v-btn
                        size="small"
                        variant="text"
                        icon="mdi-plus"
                        color="success"
                        @click="increment('mins')"
                      ></v-btn>
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              class="px-4"
              @click="sendBackFunction"
              prepend-icon="mdi-arrow-collapse-left"
              variant="tonal"
              color="success"
              :loading="working"
            >
              Send back to Outsource
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="px-4"
              @click="validate"
              append-icon="mdi-arrow-collapse-right"
              variant="flat"
              :loading="working"
              color="success"
            >
              Send for CtP
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// SnackBar //////// -->
  <div class="text-center">
    <v-snackbar
      v-model="snackbar"
      multi-line
      :timeout="timeout"
      color="primary"
      location="bottom right"
    >
      {{ text }}
    </v-snackbar>
  </div>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import DialogModal from "@/components/common/dialogModal";
import GripperSVG from "@/components/job/gripperSVG";
import Completer from "@/components/job/completer";

import bus from "vue3-eventbus";

import { addVPS, sendBack } from "@/firebase";

export default {
  props: ["jobObject"],
  data: () => ({
    working: true,
    dialog: false,
    dialogData: [
      {
        title: "",
        icon: "",
        text: "",
      },
    ],
    requiredRule: [(v) => !!v || "This is required"],
    checkItems: [
      { label: "Job Version", state: false },
      { label: "Cutter Number", state: false },
      { label: "Print Size", state: false },
      { label: "UPs", state: false },
      { label: "Customer IC", state: false },
      { label: "Design & Text", state: false },
      { label: "Expiry dates", state: false },
      { label: "Colour Bar", state: false },
      { label: "Face against reverse", state: false },
      { label: "Mask & Overlaps", state: false },
      { label: "Colours as per ticket", state: false },
      { label: "Double/Single Cut", state: false },
      { label: "SO/PO Station number", state: false },
      { label: "Emboss, Deboss & Foil", state: false },
      { label: "FG Number", state: false },
      { label: "Spot UV", state: false },
      { label: "REG Marks", state: false },
      { label: "Bleed", state: false },
      { label: "Trapping", state: false },
      { label: "Overprint", state: false },
      { label: "Colour Code", state: false },
      { label: "Barcode", state: false },
    ],
    task: null,
    taskList: ["VPS Check", "New Plan", "New Cutter", "Date Change", "Others"],
    note: "",
    mins: 0,
    gripper: 0,
    snackbar: false,
    text: `Please go up and add a JOB ID first to submit VPS`,
    timeout: 2000,
  }),
  components: {
    DialogModal,
    GripperSVG,
    Completer,
  },
  created() {
    this.working = false;
  },
  methods: {
    decrement(type) {
      if (type == "mm") {
        this.gripper--;
      } else {
        this.mins--;
      }
    },
    increment(type) {
      if (type == "mm") {
        this.gripper++;
      } else {
        this.mins++;
      }
    },
    async validate() {
      if (
        this.jobObject.jobID == "" ||
        this.jobObject.jobID == null ||
        this.jobObject.jobID == undefined
      ) {
        this.text = "Please go up and add a JOB ID first to submit";
        this.snackbar = true;
      } else if (
        this.jobObject.agent == "" ||
        this.jobObject.agent == null ||
        this.jobObject.agent == undefined
      ) {
        this.text = "Please go up and add an Agent to submit";
        this.snackbar = true;
      } else {
        const { valid } = await this.$refs.formVPS.validate();
        if (valid) this.addStageFun();
      }
    },
    async addStageFun() {
      this.working = true;
      bus.emit("loadingState", true);
      this.dialogData.title = "Updating";
      this.dialogData.text = "Sending Job to CTP";
      this.dialogData.icon = "mdi-note-text";
      this.dialog = true;

      await addVPS({
        saleOrder: this.jobObject.saleOrder,
        task: this.task,
        note: this.note,
        gripper: this.gripper,
        mins: this.mins,
      }).then((result) => {
        bus.emit("loadingState", false);
        this.working = false;
        this.dialogData.title = result.data.response;
        this.dialogData.icon = result.data.icon;
        this.dialogData.text = result.data.message;
        this.dialogData.redirect = "reload";
        this.dialog = true;
      });
    },
    async sendBackFunction() {
      this.working = true;
      bus.emit("loadingState", true);
      this.dialogData.title = "Updating";
      this.dialogData.text = "Sending back to OSL";
      this.dialogData.icon = "mdi-backburger";
      this.dialog = true;

      await sendBack({
        saleOrder: this.jobObject.saleOrder,
      }).then((result) => {
        bus.emit("loadingState", false);
        this.working = false;
        this.dialogData.title = result.data.response;
        this.dialogData.icon = result.data.icon;
        this.dialogData.text = result.data.message;
        this.dialogData.redirect = "/jobs/osl";
        this.dialog = true;
      });
    },
  },
};
</script>

<style>
.custom-class {
  color: rgba(var(--v-theme-info));
}
</style>
