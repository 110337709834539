<template>
    <div id="webviewer" ref="viewer"></div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import ComPDFKitViewer from '@compdfkit/webviewer';
import bus from "vue3-eventbus";
import { storage } from '@/firebase';
import { ref as firebaseRef, getDownloadURL } from 'firebase/storage';

// Props
const props = defineProps({
    pdfUrl: {
        type: String,
        required: true
    }
});

// Refs
const viewer = ref(null);
let docViewer = null;

// Initialize ComPDFKit Viewer
const initComPDFKitViewer = (pdfUrl) => {
    ComPDFKitViewer.init({
        path: '/',
        pdfUrl: pdfUrl,  // dynamic URL
        license: 'NjZkODFlNjBhZDYxOA==' // Replace with your actual license
    }, viewer.value).then((core) => {
        docViewer = core.docViewer;
        const { UI } = core;

        UI.setActiveToolMode('toolMenu-View');
        UI.disableElements('toolMenu-View');
        UI.disableElements('toolMenu-Annotation');
        UI.disableElements('toolMenu-Form');
        UI.disableElements('toolMenu-Sign');
        UI.disableElements('toolMenu-Security');
        UI.disableElements('toolMenu-Compare');
        UI.disableElements('toolMenu-Editor');
        UI.disableElements('toolMenu-Document');
        UI.disableElements('openFileButton');
        UI.disableElements('toolMenu-button');

        docViewer.addEvent('documentloaded', () => {
            console.log('ComPDFKit Web Demo initialized');
            bus.emit("loadingState", false);
        });
    });
};

// Function to get authenticated Firebase URL
const getAuthenticatedPdfUrl = async (pdfUrl) => {
    const pdfRef = firebaseRef(storage, pdfUrl);
    return await getDownloadURL(pdfRef);
};

// Initialize on mount
onMounted(async () => {
    const pdfUrlAuth = await getAuthenticatedPdfUrl(props.pdfUrl);
    initComPDFKitViewer(pdfUrlAuth);
});

// Watch for changes in pdfUrl prop and reinitialize viewer
watch(() => props.pdfUrl, async (newUrl) => {
    if (newUrl) {
        const pdfUrlAuth = await getAuthenticatedPdfUrl(newUrl);
        initComPDFKitViewer(pdfUrlAuth);
    }
});
</script>

<style>
#webviewer {
    height: 100%;
    width: 100%;
    overflow: hidden;
}
</style>

