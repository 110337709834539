<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-card color="cards" class="mt-4">
        <v-card-text>
          <v-form ref="form" :disabled="working">
            <v-row justify="center">
              <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <v-row justify="center" align="center">
                  <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <v-select label="Select Job type" colors="primary" v-model="type" bg-color="navBar"
                      variant="outlined" :items="withoutSO
                        ? ['New Job', 'Repeat Job']
                        : ['New Job', 'Repeat Job', 'Plate Only']
                        " :rules="requiredRule"></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <v-autocomplete label="Select Factory/Plant" colors="primary" v-model="factory" bg-color="navBar"
                      variant="outlined" :items="factories" :loading="working" item-title="name" :rules="requiredRule"
                      item-value="id"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-autocomplete label="Select Agent" colors="primary" v-model="agent" bg-color="navBar"
                  variant="outlined" :items="agents" :loading="working" item-title="name" item-value="id"
                  :rules="requiredRule"></v-autocomplete>
                <v-autocomplete label="Select Customer" colors="primary" v-model="customer" bg-color="navBar"
                  variant="outlined" :items="customers" :loading="customersLoading" item-title="name" item-value="id"
                  @update:search="getCustomers" @update:modelValue="updateCustomerCode"
                  :rules="agent == 'T7LVhEWAHvX2X6DMSUcy' ? [] : requiredRule"
                  no-data-text="start typing to search"></v-autocomplete>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <div class="text-body-1 mx-4">{{ customerCode }}</div>
                    <div class="text-body-1 mx-4">{{ customerOrganization }}</div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <v-autocomplete label="Select CtP Location" colors="primary" v-model="location" bg-color="navBar"
                      variant="outlined" :items="locations" :loading="working" item-title="name" :rules="requiredRule"
                      item-value="id"></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <v-row>
                  <v-col>
                    <v-text-field v-model="saleOrder" label="Sales Order" :rules="withoutSO ? [] : salesOrderRules"
                      :disabled="withoutSO" @input="cleanNumericInput('saleOrder')"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-checkbox label="Create without SO number for artwork" v-model="withoutSO"></v-checkbox>
                  </v-col>
                </v-row>
                <v-combobox chips multiple label="Job ID" v-model="jobID"
                  :rules="withoutSO ? jobIDRulesSingle : jobIDRulesMultiple"
                  :hint="withoutSO ? 'Leave this blank to assign next Job ID in range' : 'Type and press enter'"
                  @input="cleanNumericInput('jobID')"></v-combobox>
                <v-combobox chips multiple label="Purchase Order" v-model="purchaseOrder" :rules="purchaseOrderRules"
                  @input="cleanNumericInput('purchaseOrder')"></v-combobox>
                <v-combobox chips multiple label="Structual ID" v-model="structualID"
                  :rules="structualIDRules"></v-combobox>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="6">
                <v-textarea label="Description" rows="3" counter="260" v-model="description"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="px-12" color="success" variant="flat" size="large" @click="validate" :loading="working">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";

import DialogModal from "@/components/common/dialogModal";

import { firestore, storage, createOffSet } from "@/firebase";
import { collection, endAt, query, startAt } from "firebase/firestore";
import { doc, setDoc, getDocs, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default {
  data: () => ({
    working: true,
    type: null,
    factory: null,
    factories: [],
    location: null,
    locations: [],
    customersLoading: false,
    customer: null,
    customers: [],
    agent: null,
    customerCode: "",
    customerOrganization: "",
    agents: [],
    withoutSO: false,
    withJobID: false,
    saleOrder: null,
    purchaseOrder: [],
    jobID: [],
    structualID: [],
    description: null,
    requiredRule: [(v) => !!v || "This is required"],
    salesOrderRules: [
      (v) => !!v || 'Sales Order number is required',
      (v) => (v.length === 6 || v.length === 7) || 'Must be 6 or 7 digits long',
      (v) => /^\d+$/.test(v) || 'Sales Order must be numbers only',
      (v) => (v.length === 7 ? v.startsWith('9') || "7-digit Sales Order must start with '9'" : true),
    ],
    purchaseOrderRules: [
      (value) =>
        value.every((v) => v.length === 8) || 'PO numbers must be 8 digits long.',
    ],
    jobIDRulesMultiple: [
      (value) =>
        value.every((v) => v.length === 5 || v.length === 6) || 'Must be 5 or 6 digits long.',
    ],
    jobIDRulesSingle: [
      (value) =>
        value.every((v) => v.length === 5 || v.length === 6) || 'Must be 5 or 6 digits long.',
      (value) => value.length <= 1 || 'Create per Job ID when creating for artwork.',
    ],
    structualIDRules: [
      (value) =>
        value.every((v) => v.length >= 4) || "Must be more than 4 characters long.",
    ],
    dialog: false,
    dialogData: [
      {
        title: "",
        icon: "",
        text: "",
      },
    ],
  }),
  components: {
    DialogModal,
  },
  watch: {
    withoutSO() {
      if (this.withoutSO == true && this.type == "Plate Only") {
        this.type = null;
      }
    },
  },
  async created() {
    this.working = true;
    bus.emit("loadingState", true);

    try {
      await this.getFactories();
      await this.getLocations();
      await this.getAgents();
    } catch (error) {
      this.dialogData.title = "error";
      this.dialogData.icon = "mdi-close-circle-outline";
      this.dialogData.text = error;
      this.dialogData.redirect = "/";
      this.dialog = true;
    }
    this.working = false;
    bus.emit("loadingState", false);
  },
  methods: {
    cleanNumericInput(fieldName) {
      if (Array.isArray(this[fieldName])) {
        // Field is an array (e.g., jobID, purchaseOrder)
        this[fieldName] = this[fieldName].map((item) =>
          item.replace(/\D/g, '')
        );
      } else if (typeof this[fieldName] === 'string') {
        // Field is a string (e.g., saleOrder)
        this[fieldName] = this[fieldName].replace(/\D/g, '');
      }
    },
    updateCustomerCode() {
      let foundObject = this.customers.find((obj) => obj.id === this.customer);

      if (foundObject) {
        this.customerCode = "Customer Code: " + foundObject.code;
        this.customerOrganization = "Sales Organization: " + foundObject.organization;
      } else {
        this.customerCode = "";
        this.customerOrganization = "";
      }
    },
    async getFactories() {
      const q = query(collection(firestore, "factories"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
        return;
      } else {
        querySnapshot.forEach((doc) => {
          const data = {
            name: doc.data().name,
            id: doc.data().id,
          };
          this.factories.push(data);
        });
        return;
      }
    },
    async getLocations() {
      const q = query(collection(firestore, "locations"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
        return;
      } else {
        querySnapshot.forEach((doc) => {
          const data = {
            name: doc.data().name,
            id: doc.data().id,
          };
          this.locations.push(data);
        });
        return;
      }
    },
    async getCustomers(val) {
      if (val && val.length > 2) {
        bus.emit("loadingState", true);
        this.customersLoading = true;

        const q = query(
          collection(firestore, "customers"),
          orderBy("name"),
          startAt(val.toUpperCase()),
          limit(10)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
          this.customersLoading = false;
          bus.emit("loadingState", false);
        } else {
          this.customers = [];
          querySnapshot.forEach((doc) => {
            const data = {
              name: doc.data().name,
              id: doc.data().id,
              code: doc.data().code,
              organization: doc.data().organization,
            };
            this.customers.push(data);
          });

          this.customersLoading = false;
          bus.emit("loadingState", false);
        }
      }
    },
    async getAgents() {
      const q = query(collection(firestore, "agents"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
        return;
      } else {
        querySnapshot.forEach((doc) => {
          const data = {
            name: doc.data().name,
            id: doc.data().id,
          };
          this.agents.push(data);
        });
        return;
      }
    },
    async validate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) this.createJob();
    },
    async createJob() {
      if (this.working == false) {
        this.working = true;
        bus.emit("loadingState", true);
        this.dialogData.title = "Creating";
        this.dialogData.icon = "mdi-note-plus";
        this.dialogData.text = "Creating Job";
        this.dialogData.redirect = null;
        this.dialog = true;

        await createOffSet({
          withoutSO: this.withoutSO,
          type: this.type,
          saleOrder: this.saleOrder,
          purchaseOrder: this.purchaseOrder,
          factory: this.factory,
          location: this.location,
          customer: this.customer,
          agent: this.agent,
          jobID: this.jobID,
          structualID: this.structualID,
          description: this.description,
        }).then((result) => {
          bus.emit("loadingState", false);
          this.working = false;
          this.dialogData.title = result.data.response;
          this.dialogData.icon = result.data.icon;
          this.dialogData.text = result.data.message;
          this.dialogData.redirect = "/";
          this.dialog = true;
        });
      }
    },
  },
};
</script>

<style></style>
