<template>
  <v-row justify="start">
    <v-col cols="12" md="12">
      <v-data-table :headers="headers" :items="invoices" v-model:page="page" :loading="loading" item-key="docID"
        item-value="docID" class="elevation-1" @page-count="pageCount = $event"
        :items-per-page-options="[10, 20, 25, 50]" @click:row="onRowClick" @update:page="loadMore"
        :mobile="this.$vuetify.display.mobile" :item-class="getRowClass">
        <template v-slot:[`item.state`]="{ item }">
          <v-chip variant="flat" :color="item.state == 'verification' ? 'warn' : 'error'" dark>{{
            item.state
          }}</v-chip>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="slideUp" transition="dialog-bottom-transition" fullscreen>
      <v-card color="cards">
        <v-toolbar color="navBar">
          <v-btn icon="mdi-close" @click="slideUp = false"></v-btn>

          <v-toolbar-title>{{ slideUpTitle }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn text="Delete" variant="text" color="error" @click="deleteInvoices()"></v-btn>
            <v-btn text="Send for Approval" variant="tonal" color="green" @click="sendInvoice"></v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-row no-gutters>
          <v-col cols="12" md="7">
            <ComPDFViewer :pdfUrl="selectedPdfUrl" />
          </v-col>
          <v-col cols="12" md="5">
            <v-form ref="form" class="mt-2 mx-4" @submit.prevent="validateForm">
              <!-- Purchase Order & Invoice Date  Field -->
              <v-row align="center" class="mt-2">
                <v-col cols="12" md="6">
                  <v-text-field v-model="formData.purchaseOrder" label="Purchase Order" hide-details variant="outlined"
                    :rules="[rules.required]" :error="hasError('purchaseOrder')" density="compact"></v-text-field>

                  <v-chip class="mt-2 mx-1" color="info" v-for="(data, index) in chipsData.purchaseOrder" :key="index"
                    density="compact" size="small" :rounded="index === 0 ? 12 : 0"
                    :prepend-icon="index === 0 ? `mdi-google` : `mdi-react`" @click="formData.purchaseOrder = data">
                    {{ data || '∅' }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="formData.invoiceDate" label="Invoice Date" hide-details variant="outlined"
                    :rules="[rules.required]" :error="hasError('invoiceDate')" density="compact"></v-text-field>

                  <v-chip class="mt-2 mx-1" color="info" v-for="(data, index) in chipsData.invoiceDate" :key="index"
                    density="compact" size="small" :rounded="index === 0 ? 12 : 0"
                    :prepend-icon="index === 0 ? `mdi-google` : `mdi-react`" @click="formData.invoiceDate = data">
                    {{ data || '∅' }}
                  </v-chip>
                </v-col>
              </v-row>

              <!-- Invoice ID & currency Field -->
              <v-row align="start" class="mt-4" no-gutters>
                <v-col cols="12" md="5">
                  <v-text-field v-model="formData.invoiceID" label="Invoice ID" hide-details variant="outlined"
                    :rules="[rules.required]" :error="hasError('invoiceID')" density="compact"></v-text-field>

                  <v-chip class="mt-2 mx-1" color="info" v-for="(data, index) in chipsData.invoiceID" :key="index"
                    density="compact" size="small" :rounded="index === 0 ? 12 : 0"
                    :prepend-icon="index === 0 ? `mdi-google` : `mdi-react`" @click="formData.invoiceID = data">
                    {{ data || '∅' }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="4" class="px-2">
                  <v-autocomplete v-model="formData.currency" :items="currencies" label="Currency" hide-details variant="outlined"
                    :rules="[rules.required]" :error="hasError('currency')" density="compact"></v-autocomplete>
                  <v-chip class="mt-2 mx-1" color="info" v-for="(data, index) in chipsData.currency" :key="index"
                    density="compact" size="small" :rounded="index === 0 ? 12 : 0"
                    :prepend-icon="index === 0 ? `mdi-google` : `mdi-react`" @click="formData.currency = data">
                    {{ data || '∅' }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="formData.quantity" label="Quantity" variant="outlined" hide-details type="number" :error="hasError('quantity')" :rules="[rules.minQty, rules.maxQty]" min="1" max="99" density="compact">
                  </v-text-field>
                  <v-chip class="mt-2 mx-1" color="info" v-for="(data, index) in chipsData.quantity" :key="index"
                    density="compact" size="small" :rounded="index === 0 ? 12 : 0"
                    :prepend-icon="index === 0 ? `mdi-google` : `mdi-react`" @click="formData.quantity = data">
                    {{ data || '∅' }}
                  </v-chip>
                </v-col>
              </v-row>

              <v-divider class="mt-8"></v-divider>

              <!-- Ship To Name Field -->
              <v-row align="center" class="mt-4">
                <v-col cols="12">
                  <v-text-field v-model="formData.shipToName" label="Ship To Name" hide-details variant="outlined"
                    :rules="[rules.required]" :error="hasError('shipToName')" density="compact"></v-text-field>
                  <v-chip class="mt-2 mx-1" color="info" v-for="(data, index) in chipsData.shipToName" :key="index"
                    density="compact" size="small" :rounded="index === 0 ? 12 : 0"
                    :prepend-icon="index === 0 ? `mdi-google` : `mdi-react`" @click="formData.shipToName = data">
                    {{ data || '∅' }}
                  </v-chip>
                </v-col>
              </v-row>

              <!-- Supplier Name Field -->
              <v-row align="center" class="mt-4">
                <v-col cols="12">
                  <v-text-field v-model="formData.supplierName" label="Supplier Name" hide-details variant="outlined"
                    :rules="[rules.required]" :error="hasError('supplierName')" density="compact"></v-text-field>
                  <v-chip class="mt-2 mx-1" color="info" v-for="(data, index) in chipsData.supplierName" :key="index"
                    density="compact" size="small" :rounded="index === 0 ? 12 : 0"
                    :prepend-icon="index === 0 ? `mdi-google` : `mdi-react`" @click="formData.supplierName = data">
                    {{ data || '∅' }}
                  </v-chip>
                </v-col>
              </v-row>

              <v-divider class="mt-8"></v-divider>

              <!-- Total Tax & Amount Field -->
              <v-row align="center" class="mt-4">
                <v-col cols="12" md="6">
                  <v-text-field v-model="formData.totalTaxAmount" label="Total Tax Amount" hide-details
                    variant="outlined" :rules="[rules.required]" :error="hasError('totalTaxAmount')"></v-text-field>
                  <v-chip class="mt-2 mx-1" color="info" v-for="(data, index) in chipsData.totalTaxAmount" :key="index"
                    density="compact" size="small" :rounded="index === 0 ? 12 : 0"
                    :prepend-icon="index === 0 ? `mdi-google` : `mdi-react`" @click="formData.totalTaxAmount = data">
                    {{ data || '∅' }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="formData.totalAmount" label="Total Amount" hide-details variant="outlined"
                    :rules="[rules.required]" :error="hasError('totalAmount')"></v-text-field>
                  <v-chip class="mt-2 mx-1" color="info" v-for="(data, index) in chipsData.totalAmount" :key="index"
                    density="compact" size="small" :rounded="index === 0 ? 12 : 0"
                    :prepend-icon="index === 0 ? `mdi-google` : `mdi-react`" @click="formData.totalAmount = data">
                    {{ data || '∅' }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleting" persistent>
      <v-card color="cards" class="mx-auto" max-width="300">
        <v-card-text class="text-h5 font-weight-bold text-center">
          <LottieAnim :height="300" :width="230" lottieFile="delete" />
          Deleting invoice
        </v-card-text>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sending" persistent>
      <v-card color="cards" class="mx-auto" max-width="300">
        <v-card-text class="text-h5 font-weight-bold text-center">
          <LottieAnim :height="300" :width="230" lottieFile="ai1" />
          Verifying and sending invoice
        </v-card-text>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-dialog v-model="noteDialog" persistent>
      <v-card color="cards" class="mx-auto" min-width="400px" title="Add note">
        <v-card-text>
          <v-textarea v-model="note" :messages="noteResponse" counter="90" :rules="[rules.required, rules.length(90)]"
            rows="3"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="noteDialog = false">Cancel</v-btn>
          <v-btn @click="sendAgain">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" @close="dialog = false" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import moment from "moment";
import DialogModal from "@/components/common/dialogModal";
import ComPDFViewer from '@/components/common/pdf/comPDFViewer';
import LottieAnim from '@/components/common/lottieAnim';
import { firestore, storage, verifyPurchaseInvoice, deleteInvoice } from "@/firebase";
import { query, where, orderBy, startAfter, limit } from "firebase/firestore";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";

export default {
  data() {
    return {
      userRole: localStorage.userRole,
      invoices: [],
      headers: [
        { title: "Purchase Order", sortable: true, value: "purchaseOrder" },
        { title: "Date", sortable: true, value: "invoiceDate" },
        { title: "Supplier Name", value: "supplierName" },
        { title: "To", value: "shipToName" },
        { title: "Total", value: "totalAmount" },
        { title: "Type", sortable: true, value: "type" },
        { title: "Uploaded", sortable: true, value: "created" },
        { title: "Created By", value: "createdBy" },
        { title: "State", value: "state" },
      ],
      page: 1,
      pageCount: 0,
      loading: false,
      imageUrls: [],
      imageLoading: false,
      slideUp: false,
      slideUpTitle: "",
      slideUpDocID: null,
      selectedPdfUrl: null,
      showLightbox: false,
      lastDoc: null,
      noMore: false,
      cache: {},
      dialog: false,
      dialogData: {},
      sending: false,
      deleting: false,
      formData: {},
      chipsData: {},
      currencies: ['LKR', 'USD', 'EUR', 'GBP', 'AUD', 'CAD', 'INR', 'JPY'],
      rules: {
        length: len => v => (v || '').length <= len || `Note too long, max ${len} characters`,
        required: v => !!v || 'Field is required',
        minQty: v => v >= 1 || 'Value must be at least 1',
        maxQty: v => v <= 99 || 'Value must be at most 99'
      },
      noteDialog: false,
      noteResponse: "",
      note: null,
    };
  },
  components: {
    DialogModal,
    ComPDFViewer,
    LottieAnim,
  },
  computed: {
    computedHeight() {
      return `calc(100vh - 88px)`;
    }
  },
  async mounted() {
    await this.fetchInvoices();
  },
  created() {
    this.userRole = localStorage.userRole;
    this.btnVisible = ["Administrator", "Manager"].includes(this.userRole);
  },
  methods: {
    async fetchInvoices() {
      if (this.noMore) return;

      this.loading = true;
      bus.emit("loadingState", true);

      let q = query(
        collection(firestore, "purchaseInvoices"),
        where("state", "in", ["verification", "no data"]),
        orderBy("created", "desc"),
        limit(300)
      );

      if (this.lastDoc) {
        q = query(q, startAfter(this.lastDoc));
      }

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        this.noMore = true;
      } else {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const newInvoices = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const createdByName = await this.getName(data.createdBy, "users");
            const fileSize = parseInt(data.fileSize);

            return {
              docID: doc.id,
              ...data,
              created: this.formatDateWithTime(data.created.toDate()),
              createdBy: createdByName,
              fileSize: isNaN(fileSize) ? 0 : fileSize,
            };
          })
        );

        this.invoices = [...this.invoices, ...newInvoices];
      }

      this.loading = false;
      bus.emit("loadingState", false);
    },
    async onRowClick(_, row) {
      this.slideUpTitle = "Purchase Order " + row.item.purchaseOrder;
      this.slideUpDocID = row.item.docId;

      this.formData = {
        purchaseOrder: row.item.purchaseOrder,
        invoiceDate: row.item.invoiceDate,
        invoiceID: row.item.invoiceID,
        shipToName: row.item.shipToName,
        supplierName: row.item.supplierName,
        totalTaxAmount: row.item.totalTaxAmount,
        totalAmount: row.item.totalAmount,
        currency: row.item.currency,
        quantity: row.item.quantity,
      };

      this.chipsData = {
        purchaseOrder: [
          row.item.googleData?.purchaseOrder || '',
          row.item.openAIData?.purchaseOrder || ''
        ],
        invoiceDate: [
          row.item.googleData?.invoiceDate || '',
          row.item.openAIData?.invoiceDate || ''
        ],
        invoiceID: [
          row.item.googleData?.invoiceID || '',
          row.item.openAIData?.invoiceID || ''
        ],
        shipToName: [
          row.item.googleData?.shipToName || '',
          row.item.openAIData?.shipToName || ''
        ],
        supplierName: [
          row.item.googleData?.supplierName || '',
          row.item.openAIData?.supplierName || ''
        ],
        totalTaxAmount: [
          row.item.googleData?.totalTaxAmount || '',
          row.item.openAIData?.totalTaxAmount || ''
        ],
        totalAmount: [
          row.item.googleData?.totalAmount || '',
          row.item.openAIData?.totalAmount || ''
        ],
        currency: [
          row.item.googleData?.currency || '',
          row.item.openAIData?.currency || ''
        ],
        quantity: [
          row.item.googleData?.totalQuantity || '',
          row.item.openAIData?.totalQuantity || ''
        ]
      };

      this.selectedPdfUrl = row.item.PDF;
      this.slideUp = true;
    },
    async deleteInvoices() {
      try {
        this.deleting = true;

        const result = await deleteInvoice({
          invoiceType: "purchaseInvoices",
          selectedInvoices: [this.slideUpDocID],
        });

        if (result.data.response === "success") {
          this.showDialog(
            "success",
            "mdi-check-circle-outline",
            result.data.message,
            "close"
          );
          this.invoices = [];
          this.slideUpDocID = null;
          this.noMore = false;
          this.page = 1;
          this.pageCount = 0;
          this.imageUrl = null;
          await this.fetchInvoices();
          this.slideUp = false;
        } else {
          this.showDialog(
            "error",
            result.data.icon,
            result.data.message || "An error occurred while approving invoices.",
            "close"
          );
        }
      } catch (error) {
        console.error("Error during approval process:", error);
        this.showDialog(
          "error",
          "mdi-close-circle-outline",
          "An unexpected error occurred. Please try again.",
          "close"
        );
      }
    },
    async getName(document, collection) {
      if (!document) return " - ";

      if (this.cache[document]) return this.cache[document];

      const docRef = doc(firestore, collection, document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) return " - ";

        this.cache[document] = docSnap.data().name;
        return this.cache[document];
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      }
    },
    async getCustomer(document) {
      if (!document) return " - ";

      if (this.cache[document]) return this.cache[document];

      const docRef = doc(firestore, "automation", "invoice", "customers", document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) return " - ";

        this.cache[document] = docSnap.data().name;
        return this.cache[document];
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      }
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    sendInvoice() {
      if (this.validateForm) {
        this.sending = true;
        this.verifyInvoice();
      }
    },
    sendAgain() {
      if (this.validateForm) {
        this.noteDialog = false;
        this.sending = true;
        this.verifyInvoice();
      }
    },
    async cancelNote() {
      this.noteDialog = false;
      this.invoices = [];
      this.slideUpDocID = null;
      this.noMore = false;
      this.page = 1;
      this.pageCount = 0;
      this.imageUrl = null;
      await this.fetchInvoices();
      this.slideUp = false;
    },
    async verifyInvoice() {
      const result = await verifyPurchaseInvoice({
        purchaseOrder: this.formData.purchaseOrder,
        totalAmount: this.formData.totalAmount,
        quantity: this.formData.quantity,
        currency: this.formData.currency,
        supplierName: this.formData.supplierName,
        invoiceDate: this.formData.invoiceDate,
        invoiceID: this.formData.invoiceID,
        shipToName: this.formData.shipToName,
        note: this.note,
        docID: this.slideUpDocID,
      });

      if (result.data.response === "note") {
        this.noteResponse = result.data.message;
        this.noteDialog = true;
      } else if (result.data.response === "success") {
        this.invoices = [];
        this.slideUpDocID = null;
        this.noMore = false;
        this.page = 1;
        this.pageCount = 0;
        this.imageUrl = null;
        this.slideUp = false;
        this.showDialog(
          "success",
          "mdi-check-circle-outline",
          "Sent for approval",
          "close"
        );
        await this.fetchInvoices();
      } else {
        this.showDialog(
          "error",
          result.data.icon,
          result.data.message || "An error occurred while verifying.",
          "close"
        );
        this.slideUpDocID = null;
        this.noMore = false;
        this.page = 1;
        this.pageCount = 0;
        this.imageUrl = null;
        this.slideUp = false;
        await this.fetchInvoices();
      }
      this.sending = false;
    },
    hasError(field) {
      return !this.formData[field];
    },
    showDialog(title, icon, message, action) {
      this.sending = false;
      this.deleting = false;
      this.dialog = false;
      this.dialogData = {
        title: title,
        icon: icon,
        text: message,
        redirect: action,
      };
      this.dialog = true;
    },
    formatDateWithTime(timeStamp) {
      return moment(timeStamp).format("DD/MM/YYYY - hh:mm a");
    },
    loadMore() {
      if (this.page >= this.pageCount - 1) {
        this.fetchInvoices();
      }
    },
    getRowClass(item) {
      if (item.state === 'verification') {
        return 'row-verification';
      } else if (item.state === 'error') {
        return 'row-error';
      } else {
        return ''; // default class or no class
      }
    },
  },
};
</script>

<style scoped>
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-wrapper {
  position: relative;
  overflow-y: auto;
  padding-right: 4px;
}

.image-wrapper img {
  width: 100%;
  height: auto;
}

.row-verification {
  background-color: #ffeb3b;
  /* yellow */
}

.row-error {
  background-color: #f44336;
  /* red */
}
</style>
