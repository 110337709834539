<template>
  <v-container>
    <v-form ref="emailForm" v-model="valid" lazy-validation>
      <v-text-field
        v-model="emailData.to"
        label="Recipient Email"
        :rules="[rules.required, rules.email]"
        required
      ></v-text-field>

      <v-text-field
        v-model="emailData.subject"
        label="Subject"
        :rules="[rules.required]"
        required
      ></v-text-field>

      <!-- QuillEditor component for HTML Email Body -->
      <quill-editor
        v-model="html"
        :options="editorOptions"
        @input="updateHtmlContent"
      ></quill-editor>

      <v-btn class="mt-5" color="primary" :disabled="!valid" @click="sendEmail"
        >Send Email</v-btn
      >
    </v-form>
    <h5>{{ this.html }}</h5>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">Email Sent</v-card-title>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import bus from "vue3-eventbus";
import { firestore } from "@/firebase";
import { collection, addDoc } from "firebase/firestore";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css"; // Import the theme you want

export default {
  name: "SendEmail",
  components: {
    QuillEditor,
  },
  data() {
    return {
      valid: false,
      emailData: {
        to: "",
        subject: "",
        html: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true;
          return "Must be a valid e-mail.";
        },
      },
      html: "dfsafvadsfr",
      dialog: false,
      editorOptions: {
        theme: "snow",
      },
    };
  },
  created() {
    bus.emit("loadingState", false);
  },
  methods: {
    updateHtmlContent(content) {
      this.html = content;
      console.log(content);
    },
    async sendEmail() {
      try {
        await addDoc(collection(firestore, "outbox"), {
          from: "PPM Workflow <workflow@printcarepremedia.com>",
          to: this.emailData.to,
          message: {
            subject: this.emailData.subject,
            html: this.emailData.html, // HTML content from Quill
          },
        });
        this.dialog = true;
        this.$refs.emailForm.reset();
      } catch (error) {
        console.error("Error sending email: ", error);
        this.showDialog("Failed to send email. Please try again.");
      }
    },
    showDialog(message) {
      // Assuming you have some custom method to show dialogs
      alert(message); // Replace with your dialog method
    },
  },
};
</script>
