<template>
  <v-row justify="center">
    <v-col cols="12" md="4">
      <v-select
        v-model="selectedCustomer"
        :items="customers"
        item-value="id"
        item-title="name"
        label="Select Customer"
        outlined
      ></v-select>
    </v-col>
    <v-col cols="12" md="5">
      <v-file-input
        multiple
        v-model="files"
        label="Select PDF files"
        accept="application/pdf"
        @change="handleFileSelection"
        @click:clear="clearFiles"
      ></v-file-input>
    </v-col>
    <v-col cols="12" md="3">
      <v-btn
        block
        color="primary"
        size="x-large"
        @click="uploadFiles"
        :disabled="uploadDisabled"
        >Upload Files</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="fileTable"
        item-class="getRowClass"
        items-per-page="50"
        :items-per-page-options="[
          { value: 50, title: '50' },
          { value: 100, title: '100' },
          { value: 200, title: '200' },
        ]"
        :mobile="this.$vuetify.display.mobile"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="item.statusColor" dark>{{ item.status }}</v-chip>
        </template>
        <template v-slot:[`item.progress`]="{ item }">
          <v-progress-linear
            color="primary"
            :indeterminate="item.progress"
          ></v-progress-linear>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import { firestore, storage, createInvoice } from "@/firebase";
import DialogModal from "@/components/common/dialogModal";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

export default {
  data() {
    return {
      files: [],
      fileTable: [],
      headers: [
        { title: "File Name", value: "name" },
        { title: "Status", value: "status" },
        { title: "Progress", value: "progress" },
      ],
      dialog: false,
      dialogData: {},
      uploading: false,
      customers: [],
      selectedCustomer: null,
    };
  },
  components: {
    DialogModal,
  },
  created() {
    bus.emit("loadingState", false);
    this.getCustomers();
  },
  computed: {
    uploadDisabled() {
      return this.uploading || !this.selectedCustomer || this.files.length === 0;
    },
  },
  methods: {
    async getCustomers() {
      try {
        const customersRef = collection(firestore, "automation", "invoice", "customers");
        const customersQuery = query(customersRef, orderBy("name", "asc"));
        const customersSnapshot = await getDocs(customersQuery);

        if (!customersSnapshot.empty) {
          this.customers = customersSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
        }
      } catch (error) {
        console.error("Error fetching customers: ", error);
      }
    },
    handleFileSelection() {
      this.fileTable = [];
      this.fileTable = this.files.map((file) => ({
        name: file.name,
        file: file,
        status: "Pending",
        statusColor: "grey",
        progress: false,
      }));
    },
    clearFiles() {
      this.files = [];
      this.fileTable = [];
    },
    async uploadFiles() {
      try {
        this.uploading = true;
        let uploadCount = 0; // Initialize upload counter

        for (const fileEntry of this.fileTable) {
          this.updateFileStatus(fileEntry, "Uploading", "blue", true);

          uploadCount++; // Increment upload counter

          // After every 2 uploads, add a delay of 30 seconds
          if (uploadCount % 10 === 0) {
            await this.delay(5000); // 5 seconds delay
          }

          // Read file as base64
          const base64FileData = await this.readFileAsBase64(fileEntry.file);

          try {
            const result = await createInvoice({
              fileName: fileEntry.name,
              fileData: base64FileData,
              customerId: this.selectedCustomer,
            });

            if (result.data.response === "success") {
              this.updateFileStatus(fileEntry, "Uploaded", "green", false);
            } else {
              this.updateFileStatus(
                fileEntry,
                `Failed: ${result.data.message}`,
                "red",
                false
              );
            }
          } catch (error) {
            console.error("Upload failed:", error);
            this.updateFileStatus(fileEntry, `Failed: ${error.message}`, "red", false);
          }
        }
        this.uploading = false;
      } catch (error) {
        this.uploading = false;
        console.error("Error creating document:", error);
        this.handleError(error);
      }
    },
    readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          const base64FileData = fileReader.result.split(",")[1]; // Get base64 data
          resolve(base64FileData);
        };
        fileReader.onerror = (error) => {
          console.error("File reading failed:", error);
          reject(error);
        };
      });
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    updateFileStatus(fileEntry, status, color, state) {
      fileEntry.status = status;
      fileEntry.statusColor = color;
      fileEntry.progress = state;
    },
    getRowClass(item) {
      return {
        "blue--text": item.statusColor === "blue",
        "green--text": item.statusColor === "green",
        "red--text": item.statusColor === "red",
      };
    },
    handleError(error) {
      this.uploading = false;
      this.dialogData = {
        title: "Error",
        icon: "mdi-close-circle-outline",
        text: error.message,
        redirect: "/",
      };
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.blue--text {
  color: blue !important;
}

.green--text {
  color: green !important;
}

.red--text {
  color: red !important;
}
</style>
