<template>
  <v-card class="mt-4" color="action">
    <v-toolbar flat density="compact" color="action" title="Redo Job"></v-toolbar>
    <v-row justify="center" class="mb-6">
      <div class="text-center">
        <v-icon class="mb-2" color="success" :icon="stageObject.icon" size="64"></v-icon>
        <div class="text-h6 font-weight-bold mx-16">{{ stageObject.text }}</div>
      </div>
    </v-row>
    <UserTimeStrip :stageObject="stageObject" />
  </v-card>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";
export default {
  props: ["stageObject"],
  components: {
    UserTimeStrip,
  },
};
</script>

<style></style>
