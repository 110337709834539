<template>
    <v-form class="mt-4" ref="formCTP" :disabled="working">
        <v-row justify="center" align="center">
            <v-col cols="12" lg="12" xl="12">
                <v-card color="cards">
                    <v-toolbar flat color="navBar" title="CTP"><Completer :jobObject="jobObject" /></v-toolbar>
                    <v-row justify="center" align="center" class="mx-4 mt-4" no-gutters>
                        <v-col cols="12" lg="9">
                            <v-row justify="center" no-gutters>
                                <v-col cols="auto" v-for="item in checkItems" :key="item">
                                    <v-checkbox class="mx-1" density="compact" v-model="item.state" :label="item.label"
                                        :value="item.label" color="success" :rules="requiredRule"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn class="px-4" @click="sendBackFunction" prepend-icon="mdi-arrow-collapse-left" variant="tonal"
                            :loading="working" color="success">
                            Send back to VPS
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="px-4" @click="validate" append-icon="mdi-arrow-collapse-right" variant="flat"
                            :loading="working" color="success">
                            Send for Plateout
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { useRoute } from "vue-router";
import DialogModal from "@/components/common/dialogModal";
import GripperSVG from "@/components/job/gripperSVG";
import Completer from "@/components/job/completer";

import bus from "vue3-eventbus";

import { addCTP, sendBack } from "@/firebase";
import { firestore, storage, updateOffSet, addRepro } from "@/firebase";
import { collection, query } from "firebase/firestore";
import { doc, getDocs, where, getDoc, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";


export default {
    props: ["jobObject"],
    data: () => ({
        working: false,
        dialog: false,
        dialogData: [{
            title: "",
            icon: "",
            text: ""
        }],
        checkItems: [{ label: "Colours as per ticket", state: false }, { label: "Expiry dates", state: false }, { label: "Barcode", state: false },
        { label: "SO/PO Station number", state: false }, { label: "Customer IC", state: false }, { label: "REG Marks", state: false },
        { label: "Screen rulling & Angles", state: false }, { label: "Job version", state: false }, { label: "Cutter No", state: false }, { label: "Design & Text", state: false },
        { label: "UPs", state: false }],
        requiredRule: [(v) => !!v || "This is required"],
    }),
    components: {
        DialogModal,
        GripperSVG,
        Completer
    },
    created() {
        this.working = false;
    },
    methods: {
        async validate() {
            const { valid } = await this.$refs.formCTP.validate();
            if (valid) this.addStageFun();
        },
        async addStageFun() {
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = "Updating";
            this.dialogData.text = "Setting Job for Plateout";
            this.dialogData.icon = "mdi-note-text";
            this.dialog = true;

            await addCTP({
                saleOrder: this.jobObject.saleOrder,
            }).then((result) => {
                bus.emit("loadingState", false);
                this.working = false;
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialogData.redirect = "/jobs/ctp";
                this.dialog = true;
            });
        },
        async sendBackFunction() {
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = "Updating";
            this.dialogData.text = "Sending back to VPS";
            this.dialogData.icon = "mdi-backburger";
            this.dialog = true;

            await sendBack({
                saleOrder: this.jobObject.saleOrder,
            }).then((result) => {
                bus.emit("loadingState", false);
                this.working = false;
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialogData.redirect = "/jobs/ctp";
                this.dialog = true;
            });
        },
    }

}
</script>

<style></style>