<template>
  <v-form class="mt-4" ref="formOSL" disabled>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card color="cards">
          <v-toolbar
            flat
            density="compact"
            color="navBar"
            title="FILM OUTSOURCE LIST"
          ></v-toolbar>
          <v-row
            :justify="$vuetify.display.mobile ? 'start' : 'center'"
            align="center"
            class="pt-4"
            no-gutters
          >
            <v-col
              v-for="(field, index) in fields"
              :key="index"
              cols="auto"
              :class="$vuetify.display.mobile ? '' : 'mx-3'"
            >
              <v-radio-group
                v-model="localStageObject[field.model]"
                inline
                :label="field.label"
              >
                <v-radio label="N/A" value="n/a" density="compact"></v-radio>
                <v-radio label="Old" value="old" density="compact" color="warn"></v-radio>
                <v-radio
                  label="New"
                  value="new"
                  density="compact"
                  color="success"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <UserTimeStrip :stageObject="localStageObject" />
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";

export default {
  props: ["stageObject"],
  components: {
    UserTimeStrip,
  },
  data() {
    return {
      localStageObject: { ...this.stageObject },
      fields: [
        { label: "Die Lines", model: "dieLines" },
        { label: "Emboss & Deboss", model: "emDeboss" },
        { label: "Foil", model: "foil" },
        { label: "Varnish", model: "varnish" },
        { label: "Special Gold", model: "specialGold" },
        { label: "UV Varnish Flexo", model: "uvVarnishFlexo" },
        { label: "UV Varnish Digital", model: "uvVarnishDigital" },
      ],
    };
  },
  methods: {},
  watch: {
    stageObject: {
      immediate: true,
      handler(newVal) {
        this.localStageObject = { ...newVal };
      },
    },
  },
};
</script>

<style></style>
