<template>
  <NoInternet v-if="!connection" />
  <NoContent v-if="connection && !working && jobs.length == 0" />
  <v-row justify="center" no-gutters>
    <v-col cols="12" v-for="job in jobs" :key="job.saleOrder">
      <ArtworkStrip
        v-if="job.state == 'artwork'"
        :jobObject="job"
        :cardColor="job.cardColor"
      />
      <JobStrip v-else :jobObject="job" :cardColor="job.cardColor" />
    </v-col>
  </v-row>
  <Spinner v-if="working" />
</template>

<script>
import { useRoute } from "vue-router";
import bus from "vue3-eventbus";
import moment from "moment";

import Spinner from "@/components/common/spinner";
import NoContent from "@/components/common/noJobs";
import NoInternet from "@/components/common/noInternet";
import JobStrip from "@/components/job/jobStrip";
import ArtworkStrip from "@/components/artwork/artworkStrip";

import { firestore } from "@/firebase";
import { collection, query } from "firebase/firestore";
import { doc, where, getDoc, getDocs } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";

export default {
  data: () => ({
    userRole: localStorage.userRole,
    connection: true,
    working: true,
    searchTerm: "",
    whereFilter: "",
    operator: "==",
    itemsPerPage: 10,
    jobs: [],
    noMore: false,
    lastDoc: false,
  }),
  components: {
    Spinner,
    NoContent,
    NoInternet,
    JobStrip,
    ArtworkStrip,
  },
  created() {
    const route = useRoute();
    this.userRole = localStorage.userRole;
    this.searchTerm = route.params.searchTerm;

    if (this.searchTerm.length == 5) {
      this.getArtwork();
    } else {
      this.searchDocuments();
    }
  },
  methods: {
    async searchDocuments() {
      let jobsCollection = collection(firestore, "jobs");
      let jobsQuery = query(jobsCollection);

      if (/^\d+$/.test(this.searchTerm)) {
        switch (this.searchTerm.length) {
          case 5:
            jobsQuery = query(
              jobsQuery,
              where("jobID", "array-contains", this.searchTerm)
            );
            break;
          case 6:
            jobsQuery = query(jobsQuery, where("SO", "==", this.searchTerm));
            break;
          case 7:
            jobsQuery = query(jobsQuery, where("SO", "==", this.searchTerm));
            break;
          case 8:
            jobsQuery = query(jobsQuery, where("PO", "array-contains", this.searchTerm));
            break;
        }
      } else {
        jobsQuery = query(jobsQuery, where("customer", "==", this.searchTerm));
      }

      if (this.userRole == "Agent") {
        const docRef = doc(firestore, "users", localStorage.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const agentIDs =
            docSnap.data().agents == undefined ? [] : docSnap.data().agents;
          jobsQuery = query(jobsQuery, where("agent", "in", agentIDs));
        }
      }

      jobsQuery = query(jobsQuery, orderBy("modified", "desc"));

      if (this.lastDoc) {
        jobsQuery = query(jobsQuery, startAfter(this.lastDoc), limit(20));
      } else {
        jobsQuery = query(jobsQuery, limit(30));
      }

      const querySnapshot = await getDocs(jobsQuery);

      if (querySnapshot.metadata.fromCache) {
        this.connection = false;
      } else if (querySnapshot.empty) {
        this.noMore = true;
      } else {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        for (let i = 0; i < querySnapshot.docs.length; i++) {
          const data = {
            situation: "jobs",
            cardColor: querySnapshot.docs[i].data().currState,
            state: querySnapshot.docs[i].data().currState,
            type: querySnapshot.docs[i].data().type,
            saleOrder: querySnapshot.docs[i].data().SO,
            description: querySnapshot.docs[i].data().description,
            purchaseOrder: querySnapshot.docs[i].data().PO,
            jobID: querySnapshot.docs[i].data().jobID,
            structualID: querySnapshot.docs[i].data().structualID,
            open: querySnapshot.docs[i].data().open,
            items: querySnapshot.docs[i].data().items,
            assigned: await this.getName(querySnapshot.docs[i].data().assigned, "users"),
            customer: await this.getName(
              querySnapshot.docs[i].data().customer,
              "customers"
            ),
            agent: await this.getName(querySnapshot.docs[i].data().agent, "agents"),
            factory: await this.getName(
              querySnapshot.docs[i].data().factory,
              "factories"
            ),
            location: await this.getName(
              querySnapshot.docs[i].data().location,
              "locations"
            ),
            created: this.formatDateTime(
              querySnapshot.docs[i].data().created,
              "DD MMM YYYY - HH:MM A"
            ),
            hold: querySnapshot.docs[i].data().hold,
            holded: this.formatDateTime(
              querySnapshot.docs[i].data().holded,
              "DD MMM YYYY - hh:mm A"
            ),
          };
          this.jobs.push(data);
        }
      }
      this.working = false;
      bus.emit("loadingState", false);
    },
    async getArtwork() {
      const docRefArtwork = doc(firestore, "artworks", this.searchTerm);
      const docSnapArtwork = await getDoc(docRefArtwork);
      if (docSnapArtwork.exists()) {
        const dataArtwork = {
          situation: "artworks",
          cardColor: docSnapArtwork.data().currState,
          state: docSnapArtwork.data().currState,
          type: docSnapArtwork.data().type,
          saleOrder: docSnapArtwork.data().SO,
          purchaseOrder: docSnapArtwork.data().PO,
          jobID: docSnapArtwork.data().jobID,
          structualID: docSnapArtwork.data().structualID,
          assigned: await this.getName(docSnapArtwork.data().assigned, "users"),
          customer: await this.getName(docSnapArtwork.data().customer, "customers"),
          agent: await this.getName(docSnapArtwork.data().agent, "agents"),
          factory: await this.getName(docSnapArtwork.data().factory, "factories"),
          location: await this.getName(docSnapArtwork.data().location, "locations"),
          created: this.formatDateTime(
            docSnapArtwork.data().created,
            "DD MMM YYYY - HH:MM A"
          ),
        };
        this.jobs.push(dataArtwork);
      } else {
        // document does not exist
        // .data() will be undefined
      }
      this.searchDocuments();
    },
    async getName(ID, type) {
      if (!ID) {
        return " - ";
      } else {
        const docRef = doc(firestore, type, ID);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            return docSnap.data().name;
          } else {
            return " - ";
          }
        } catch (error) {
          this.dialogtitle = "error";
          this.dialogIcon = "mdi-close-circle-outline";
          this.dialogText = error;
          this.dialog = true;
        }
      }
    },
    formatDateTime(timeStamp, format) {
      if (timeStamp != null) {
        var m = moment(timeStamp.toDate());
        var mFormatted = m.format(format);
        return mFormatted;
      } else {
        return " - ";
      }
    },
  },
};
</script>

<style scoped>
.fixed-element {
  position: sticky;
  position: -webkit-sticky;
  /* for Safari */
  top: 2em;
  z-index: 2;
}
</style>
async
