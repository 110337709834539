<template>
  <v-card class="mt-4" color="cards">
    <v-toolbar flat density="compact" color="navBar" title="PLATE OUT"></v-toolbar>
    <v-progress-linear indeterminate v-if="working"></v-progress-linear>
    <v-row justify="center" align="center" no-gutters class="mt-2">
      <v-col cols="12" md="4">
        <div class="text-center">
          <div class="text-h6 font-weight-light">
            Stocks Location: {{ stageObject.location }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="auto">
        <v-row justify="center" no-gutters>
          <v-col cols="auto" v-for="item in checkItems" :key="item">
            <v-checkbox
              class="mx-2"
              density="compact"
              v-model="item.state"
              :label="item.label"
              color="success"
              disabled
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <div class="text-center">
          <div class="text-h6 font-weight-light">
            Plate Size {{ stageObject.plateSize }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!working" justify="space-around" no-gutters>
      <v-col cols="11" md="5">
        <div class="text-h6 ma-4">USED MATERIAL</div>
        <v-sheet border v-for="item in used" :key="item" class="my-1" rounded="lg">
          <v-table density="compact" class="text-subtitle-2">
            <tbody>
              <tr align="right">
                <th>Material:</th>
                <td>{{ item.name }}</td>
              </tr>
              <tr align="right">
                <th>Qty Used:</th>
                <td>{{ item.count }}</td>
              </tr>
              <tr align="right">
                <th>Gripper (measured):</th>
                <td>{{ item.gripper ? item.gripper + " mm" : "Not Entered" }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-sheet>
      </v-col>
      <v-col cols="11" md="5">
        <div class="text-h6 ma-4">WASTE MATERIAL</div>
        <v-sheet border v-for="item in wasted" :key="item" class="my-1" rounded="lg">
          <v-table density="compact" class="text-subtitle-2">
            <tbody>
              <tr align="right">
                <th>Material:</th>
                <td>{{ item.name }}</td>
              </tr>
              <tr align="right">
                <th>Qty Waste:</th>
                <td>{{ item.count }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row v-if="!working" justify="center" class="ma-2">
      {{ stageObject.note }}
    </v-row>
    <UserTimeStrip :stageObject="stageObject" />
  </v-card>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";
import { firestore, storage, holdJob } from "@/firebase";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";

export default {
  props: ["stageObject"],
  components: {
    UserTimeStrip,
  },
  data: () => ({
    working: true,
    used: [],
    wasted: [],
    checkItems: [],
  }),
  created() {
    this.runInit();
  },
  methods: {
    async runInit() {
      this.working = true;
      this.checkItems = this.stageObject.checkItems;
      for (let i = 0; i < this.stageObject.used.length; i++) {
        const data = {
          name: await this.getMaterials(this.stageObject.used[i].id),
          gripper: this.stageObject.used[i].gripper,
          id: this.stageObject.used[i].id,
          count: this.stageObject.used[i].count,
        };
        this.used.push(data);
      }
      for (let i = 0; i < this.stageObject.wasted.length; i++) {
        const data = {
          name: await this.getMaterials(this.stageObject.wasted[i].id),
          id: this.stageObject.wasted[i].id,
          count: this.stageObject.wasted[i].count,
        };
        this.wasted.push(data);
      }
      this.working = false;
    },
    async getMaterials(ID) {
      const docRef = doc(firestore, "materials", ID);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return docSnap.data().name;
        } else {
          return " - ";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
