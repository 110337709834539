<template>
    <v-form ref="form">
        <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="8" lg="6" xl="4">
                <v-card color="cards">
                    <v-card-title>
                        Enter New Password
                    </v-card-title>
                    <v-card-text class="px-4 pt-8">
                        <v-text-field label="Type new password" v-model="password"
                            :rules="[rules.required, rules.min]" :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1"></v-text-field>
                        <v-text-field label="Re-type new password" v-model="confirmPassword"
                            :rules="[rules.required, rules.min]" :error="error" :error-messages="errorText" :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block color="primary" variant="flat" @click="validate">Change</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import { changePassword } from "@/firebase";
import DialogModal from "@/components/common/dialogModal";

export default {
    data: () => ({
        working: true,
        password: '',
        confirmPassword: '',
        show1: false,
        show2: false,
        dialog: false,
        dialogData: [{
            title: "",
            icon: "",
            text: ""
        }],
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
        },
        error: false,
        errorText: "",
    }),
    components: {
        DialogModal
    },
    created() {
        this.working = false;
        bus.emit("loadingState", false);
    },
    methods: {
        async validate() {
            if (this.password === this.confirmPassword) {
                this.error = false;
                this.errorText = "";
                const { valid } = await this.$refs.form.validate();
                if (valid) this.sendChange();
            } else {
                this.error = true;
                this.errorText = "Password does not match";
            }

        },
        async sendChange() {
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = "Changing";
            this.dialogData.text = "Changing password";
            this.dialogData.icon = "mdi-lock-reset";
            this.dialog = true;

            await changePassword(this.confirmPassword)
                .then(async (results) => {
                    bus.emit("loadingState", false);
                    this.working = false;
                    this.dialogData.title = "success";
                    this.dialogData.icon = "mdi-lock-check";
                    this.dialogData.text = "Password Changed";
                    this.dialogData.redirect = "/";
                    this.dialog = true;
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;

                    bus.emit("loadingState", false);
                    this.working = false;

                    if (errorCode == "auth/requires-recent-login") {
                        this.dialogData.title = "Error";
                        this.dialogData.icon = "mdi-lock-remove";
                        this.dialogData.text = "Please logout and login again to change password";
                        this.dialogData.redirect = "/";
                        this.dialog = true;
                    } else {
                        this.dialogData.title = "Error";
                        this.dialogData.icon = "mdi-alert-circle";
                        this.dialogData.text = errorMessage;
                        this.dialogData.redirect = "/";
                        this.dialog = true;
                    }
                });
        },
    }
}
</script>

<style></style>