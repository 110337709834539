<template>
  <v-row justify="end">
    <v-col v-if="['Administrator', 'Manager', 'Moderator', 'Designer'].includes(userRole)" cols="auto">
      <v-btn class="ml-2" color="primary" prepend-icon="mdi-plus" to="/jobs/create">
        Offset
      </v-btn>
      <v-btn class="ml-2" color="secondary" prepend-icon="mdi-plus" to="/jobs/create/flexo" disabled>
        Flexo
      </v-btn>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col cols="11" md="6">
      <v-img class="mx-auto mb-6" max-width="480" src="@/assets/ppmlogo.svg"></v-img>

      <div
        v-if="['Administrator', 'Manager', 'Moderator', 'Designer', 'CTP_Operator', 'Coordinator', 'Agent', 'Customer'].includes(userRole)">
        <v-autocomplete v-if="autoCompleteActive" autofocus label="Select Customer" colors="cards" v-model="search"
          bg-color="navBar" variant="outlined" :items="customers" :loading="customersLoading" item-title="name"
          item-value="id" @update:search="getCustomers" @update:modelValue="openSearch" :hint="hintText"
          no-data-text="start typing to search"></v-autocomplete>

        <v-text-field v-else :autofocus="autofocusActive" label="Search by Sales order, Job ID, PO or Customer name"
          append-inner-icon="mdi-magnify" single-line :error="errorState" @click:append-inner="openSearch"
          @keydown.enter="openSearch" v-model="search" variant="solo" bg-color="cards" :hint="hintText" persistent-hint>
          <template v-slot:append>
            <v-checkbox v-if="checkbox" v-model="directOpen" label="Open Job" hide-details
              @click="setCheckBox"></v-checkbox> </template></v-text-field>
      </div>
    </v-col>
  </v-row>
  <v-row no-gutters justify="end" v-if="
    this.$vuetify.display.mobile &&
    ['Administrator', 'Manager', 'Moderator', 'Designer'].includes(userRole)
  ">
    <v-col cols="auto">
      <v-btn class="ml-2" large color="neutral" prepend-icon="mdi-barcode-scan" to="/scanner">
        Scan
      </v-btn>
    </v-col>
  </v-row>
  <v-row justify="center" :class="this.$vuetify.display.mobile ? `px-1` : `px-16 mx-16`"
    v-if="['Administrator', 'Manager', 'Moderator', 'Designer', 'CTP_Operator', 'Coordinator', 'Agent'].includes(userRole)">
    <v-col v-for="(value, key) in jobStates" :key="key" cols="12" md="3">
      <v-alert border="start" variant="tonal" :color="key">
        <template v-slot:title>
          <v-progress-circular v-if="value == null" class="mb-2" :color="key" :size="25"
            indeterminate></v-progress-circular>
          <div class="text-h5" v-else>{{ value }}</div>
        </template>
        {{ displayNames[key] }}
      </v-alert>
    </v-col>
  </v-row>
  <div class="relative-height-div"></div>
  <Charts v-if="['Administrator', 'Manager', 'Moderator', 'Designer', 'CTP-Operator'].includes(userRole)" />
</template>

<script>
import bus from "vue3-eventbus";
import router from "@/router";
import Charts from "@/views/dashboard/charts";
import { auth, firestore, messaging } from "@/firebase";
import { getToken, onMessage } from "firebase/messaging";
import { getDocs, getDoc, updateDoc, setDoc, getCountFromServer } from "firebase/firestore";
import { doc, collection, query, where, orderBy, limit, startAt } from "firebase/firestore";

export default {
  components: {
    Charts,
  },
  data: () => ({
    working: true,
    userRole: localStorage.userRole,
    checkbox: false,
    directOpen: null,
    search: "",
    autoCompleteActive: false,
    autofocusActive: false,
    customersLoading: false,
    customers: [],
    hintText: "",
    errorState: false,
    jobStates: {
      artwork: null,
      repro: null,
      osl: null,
      vps: null,
      ctp: null,
      plateout: null,
      dispatch: null,
    },
    displayNames: {
      artwork: "Artwork",
      repro: "Repro",
      osl: "Outsource",
      vps: "Imposition/VPS",
      ctp: "CtP",
      plateout: "Plateout",
      dispatch: "Dispatch",
    },
  }),
  watch: {
    search: function (val) {
      val = val ? val.trim() : val; // Trim the input
      this.autofocusActive = true;
      this.errorState = false;
      this.checkbox = false;
      const hints = {
        5: "Search with Job ID",
        6: "Search with Sales Order number",
        7: "Search with Sales Order number",
        8: "Search with Production Order number",
        0: "",
      };

      if (val == null) {
        this.hintText = "";
        this.autoCompleteActive = false;
      } else {
        if (/^\d+$/.test(val)) {
          this.hintText =
            hints[val.length] ||
            "Unable to determine search type. Please check character length";
          if (val.length == 6 || val.length == 7) this.checkbox = true;
          this.autoCompleteActive = false;
        } else if (val.length >= 2) {
          this.hintText = "Type more than 3 characters to start searching customers";
          this.autoCompleteActive = true;
        } else {
          this.hintText = "";
          this.autoCompleteActive = false;
        }
      }
    },
  },
  created() {
    bus.emit("loadingState", true);
    this.working = true;
    this.userRole = localStorage.userRole;
    this.directOpen = localStorage.directOpen !== "false";
    localStorage.directOpen = this.directOpen.toString();

    if (this.userRole == "Agent") {
      this.getAgentAnalytics();
    } else {
      this.getJobCount();
    }

    this.retrieveFCMToken();
  },
  methods: {
    retrieveFCMToken() {
      const permission = Notification.permission;
      if (permission == "granted") {
        getToken(messaging, { vapidKey: 'BLRvshdbZJcwoxxt8iD4w0dchayn3JJAsHyK9llssSP6MeHupwhQ4jdIkeEzHMAkS4KG770jL2iqrMr3Y_nJGgE' })
          .then(async (currentToken) => {
            if (currentToken) {
              const userRef = doc(firestore, "users", localStorage.uid);
              const userDoc = await getDoc(userRef);

              let tokens = [];
              if (userDoc.exists()) {
                tokens = userDoc.data().token || [];
                tokens = [...new Set(tokens)];
                if (tokens.length >= 3) tokens.shift();
                tokens.push(currentToken);
                await updateDoc(userRef, { token: tokens });
              } else {
                await setDoc(userRef, { token: [currentToken] });
              }
            } else {
              console.log("No registration token available.");
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token.", err);
          });
      }
    },
    openSearch() {
      if (this.search != null) {
        this.search = this.search ? this.search.trim() : this.search; // Trim the input
        const routes = {
          5: `/jobs/search/${this.search}`,
          6: this.directOpen
            ? `/jobs/repro/checklist/${this.search}`
            : `/jobs/search/${this.search}`,
          7: this.directOpen
            ? `/jobs/repro/checklist/${this.search}`
            : `/jobs/search/${this.search}`,
          8: `/jobs/search/${this.search}`,
        };

        if (/^\d+$/.test(this.search)) {
          if (routes[this.search.length]) {
            router.push(routes[this.search.length]);
          } else if (this.search.length >= 1) {
            this.errorState = true;
          }
        } else {
          router.push(`/jobs/search/${this.search}`);
        }
      }
    },
    async getJobCount() {
      try {
        const docRef = doc(firestore, "analytics", "currStates");
        const docSnap = await getDoc(docRef);
        const docData = docSnap.data();

        const jobStateKeys = Object.keys(this.jobStates);
        jobStateKeys.forEach((key) => {
          this.jobStates[key] = docData[key];
        });
      } catch (error) {
        alert(error);
      }

      bus.emit("loadingState", false);
    },
    async getAgentAnalytics() {
      console.log(auth.currentUser.uid);
      const docRef = doc(firestore, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      let selectedAgents =
        docSnap.exists() && docSnap.data().agents ? docSnap.data().agents : [];

      const jobStateKeys = Object.keys(this.jobStates);
      for (const key of jobStateKeys) {
        this.jobStates[key] = await this.getCountForAgent(
          key === "artwork" ? "artworks" : "jobs",
          key,
          selectedAgents
        );
      }

      bus.emit("loadingState", false);
    },
    async getCountForAgent(collectionName, state, selectedAgents) {
      const coll = collection(firestore, collectionName);
      const conditions = [
        where("agent", "in", selectedAgents),
        where("currState", "==", state),
        ...(collectionName === "artworks" ? [where("merged", "==", false)] : []),
      ];
      const q = query(coll, ...conditions);

      const snapshot = await getCountFromServer(q);
      return snapshot.data().count;
    },
    setCheckBox() {
      this.directOpen = !this.directOpen;
      localStorage.directOpen = this.directOpen.toString();
    },
    async getCustomers(val) {
      if (val && val.length > 2) {
        bus.emit("loadingState", true);
        this.customersLoading = true;

        const q = query(
          collection(firestore, "customers"),
          orderBy("name"),
          startAt(val.toUpperCase()),
          limit(10)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
          this.customersLoading = false;
          bus.emit("loadingState", false);
        } else {
          this.customers = [];
          querySnapshot.forEach((doc) => {
            const data = {
              name: doc.data().name,
              id: doc.data().id,
              code: doc.data().code,
              organization: doc.data().organization,
            };
            this.customers.push(data);
          });

          this.customersLoading = false;
          bus.emit("loadingState", false);
        }
      }
    },
  },
};
</script>

<style scoped>
.fullPage {
  width: 100vw;
  height: 99.1vh;
}

.relative-height-div {
  height: 6vh;
  /* This makes the div's height 15% of the viewport height */
}
</style>
