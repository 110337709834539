<template>
    <NoInternet v-if="!connection" />
    <div v-else>
        <v-row justify="space-between">
            <v-col cols="12" md="3">
                <v-select label="Select CtP Location" colors="primary" v-model="listLocation" item-value="id"
                    bg-color="navBar" :loading="working" variant="outlined" :items="locations" :rules="requiredRule"
                    item-title="name" density="compact" @update:model-value="getInventory"></v-select>
            </v-col>
            <v-col cols="auto">
                <v-btn v-if="btnVisible" class="ml-2" large color="primary" prepend-icon="mdi-plus"
                    @click="dialogPopup = true">
                    Add/Remove Stocks
                </v-btn>
            </v-col>
        </v-row>
        <Spinner v-if="working" />
        <v-row v-else no-gutters>
            <v-col cols="12" xs="10" sm="10" md="4"  v-for="item in items" :key="item.id">
                <v-card class="ma-2" color="cards">
                    <v-card-title class="text-h6">
                        {{ item.name }}
                    </v-card-title>
                    <v-row justify="space-between">
                        <v-col cols="7" class="ml-4">
                            <p class="text-subtitle-1">{{ item.description }}</p>
                            <v-btn class="my-4" variant="outlined" size="small" @click="gotoLogs(item)">
                                See Logs
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-avatar class="mr-2" size="100" rounded="0">
                                <p class="text-h3 text-end font-weight-light">{{ item.quantity }}</p>
                            </v-avatar>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
    <v-dialog v-model="dialogPopup" transition="dialog-bottom-transition" max-width="800">
        <v-form ref="form" :disabled="working">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Add/Remove Stocks</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                    <v-row justify="space-around" class="mt-4" no-gutter>
                        <v-col cols="12" md="5">
                            <v-select label="Select CtP Location" colors="primary" v-model="location" item-value="id"
                                bg-color="navBar" variant="outlined" :items="locations" :rules="requiredRule"
                                item-title="name"></v-select>
                        </v-col>
                        <v-col cols="12" md="7">
                            <v-select label="Select material" colors="primary" v-model="material" bg-color="navBar"
                                variant="outlined" item-value="id" :items="materials" :rules="requiredRule"
                                item-title="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row justify="center" align="center">
                        <v-col cols="auto">
                            <v-btn variant="text" icon="mdi-minus" color="error" @click="decrement()"></v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <p class="text-h1 font-weight-light">{{ quantity }}</p>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn variant="text" icon="mdi-plus" color="success" @click="increment()"></v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-slider v-model="quantity" :color="color" track-color="info" class="align-center"
                                :max="500" :rules="isAdmin ? quantityRulesAdmin : quantityRulesModerator" :min="-500"
                                :step="1" />
                        </v-col>
                    </v-row>
                    <v-row class="mt-4">
                        <v-col cols="12">
                            <v-textarea label="Note" rows="3" counter="260" v-model="note"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-5" color="primary" variant="flat" :loading="working" width="400" @click="validate">
                        {{ btnText }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" @close="dialog = false" />
    </v-dialog>
</template>

<script>
import NoInternet from "@/components/common/noInternet";
import DialogModal from "@/components/common/dialogModal";
import Spinner from "@/components/common/spinner";
import bus from "vue3-eventbus";

import { firestore, addRemoveStock } from "@/firebase";
import { collection, query, startAt } from "firebase/firestore";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";

export default {
    data: () => ({
        working: false,
        connection: true,
        btnVisible: false,
        isAdmin: false,
        dialogPopup: false,
        listLocation: "rjoKKCBYPynZ5SaheJzr",
        location: null,
        locations: [],
        material: null,
        materials: [],
        requiredRule: [(v) => !!v || "This is required"],
        quantity: 0,
        note: null,
        dialog: false,
        dialogData: [{
            title: "",
            icon: "",
            text: ""
        }],
        items: [],
        quantityRulesAdmin: [
            (v) =>
                (v && v != 0) ||
                "Quantity cannot be zero",
        ],
        quantityRulesModerator: [
            (v) =>
                (v && v >= 1) ||
                "Quantity cannot be less than one",
        ],
    }),
    components: {
        Spinner,
        DialogModal,
        NoInternet,
    },
    computed: {
        color() {
            if (this.quantity == 0) return 'info'
            if (this.quantity > 1) return 'ctp'
            if (this.quantity < -1) return 'repro'
            return 'info'
        },
        btnText() {
            if (this.quantity >= 0) return 'Add'
            return 'Remove'
        }
    },
    created() {
        this.working = true;
        const userRole = localStorage.userRole;
        this.btnVisible = ["Administrator", "Manager", "Moderator"].includes(userRole);
        this.isAdmin = ["Administrator", "Manager"].includes(userRole);
        this.getLocations();
    },
    methods: {
        async getLocations() {
            const q = query(collection(firestore, "locations"), orderBy("name"));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.metadata.fromCache) {
                this.connection = false;
            } else {
                querySnapshot.forEach((doc) => {
                    const data = {
                        name: doc.data().name,
                        id: doc.data().id,
                    };
                    this.locations.push(data);
                });
            }
            this.getMaterial();
        },
        async getMaterial() {
            const materialRef = collection(firestore, "materials");
            let q;

            q = await query(materialRef, orderBy("name"));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.metadata.fromCache) {
                this.connection = false;
            } else {
                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const data = {
                        name: querySnapshot.docs[i].data().name,
                        description: querySnapshot.docs[i].data().description,
                        id: querySnapshot.docs[i].data().id,
                    };
                    this.materials.push(data);
                }
            }
            this.getInventory();
        },
        async getInventory() {
            this.items = [];
            this.working = true;
            bus.emit("loadingState", true);
            const inventoryRef = collection(firestore, "inventory", this.listLocation, "materials");
            let q;

            q = await query(inventoryRef, orderBy("id"));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.metadata.fromCache) {
                this.connection = false;
            } else {
                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const data = {
                        quantity: querySnapshot.docs[i].data().quantity,
                        description: querySnapshot.docs[i].data().description,
                        brand: querySnapshot.docs[i].data().brand,
                        name: await this.getFromArray("materials", querySnapshot.docs[i].data().id),
                        id: querySnapshot.docs[i].data().id,
                    };
                    this.items.push(data);
                }
            }
            this.working = false;
            bus.emit("loadingState", false);
        },
        getFromArray(type, ID) {
            let foundObject = this[type].find(item => item.id === ID);
            if (foundObject) {
                return foundObject.name
            } else {
                return "error";
            }
        },
        async validate() {
            const { valid } = await this.$refs.form.validate();
            if (valid) this.sendStock();
        },
        async sendStock() {
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = "Updating";
            this.dialogData.text = "Updating Stock";
            this.dialogData.icon = "mdi-database-refresh-outline";
            this.dialog = true;

            await addRemoveStock({
                location: this.location,
                material: this.material,
                quantity: this.quantity,
                note: this.note
            }).then((result) => {
                bus.emit("loadingState", false);
                this.working = false;
                this.dialogPopup = false;
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialogData.redirect = "Close";
                this.dialog = true;
                this.getInventory();
            });
        },
        gotoLogs(item) {
            this.$router.push({ path: `/inventory/plates/log/` + this.listLocation + `/${item.id}` });
        },
        decrement() {
            this.quantity--
        },
        increment() {
            this.quantity++
        },
    }
}
</script>

<style></style>