<template>
  <v-hover v-slot="{ isHovering, props }">
    <v-alert
      class="mb-2"
      border="start"
      border-color="cardColor"
      variant="tonal"
      :color="isHovering ? 'info' : cardColor"
      :subtitle="jobObject.customer"
      v-bind="props"
      :style="jobObject.merged ? { opacity: 0.4 } : { opacity: 1 }"
    >
      <v-row justify="space-between" no-gutters>
        <v-col cols="12" lg="3" @click="openJob(jobObject)">
          <p class="text-h6">
            <strong>{{ mainText }}</strong>
          </p>
          <p class="text-caption">{{ jobObject.customer }}</p>
          <div class="my-6"></div>
          <div>{{ subText }} <br /></div>
        </v-col>
        <v-col cols="12" lg="5" @click="openJob(jobObject)">
          <p class="text-body-2">
            <strong>Purchase Order: </strong> {{ jobObject.purchaseOrder.join(", ") }}
          </p>
          <p class="text-body-2"><strong>Created on: </strong>{{ jobObject.created }}</p>
          <p class="text-body-2"><strong>Factory: </strong>{{ jobObject.factory }}</p>
          <p class="text-body-2"><strong>Agent: </strong>{{ jobObject.agent }}</p>
          <p class="text-body-2">
            <strong>CtP Location: </strong>{{ jobObject.location }}
          </p>
        </v-col>
        <v-col cols="12" lg="2" class="d-flex flex-column" @click="openJob(jobObject)">
          <p class="text-caption"><strong>Description</strong></p>
          <p class="text-caption">{{ jobObject.description }}</p>
          <div class="mt-1" style="color: red" v-if="jobObject.hold">
            <v-icon icon="mdi-car-brake-hold" size="40"></v-icon>
            On hold from <br />
            {{ jobObject.holded }}
          </div>
        </v-col>
        <v-col cols="12" lg="2" align="end">
          <JobTag :jobType="jobObject.type" />
          <div class="my-10" @click="openJob(jobObject)"></div>
          <div v-if="userRole != 'Agent' && jobObject.merged">
            <template
              v-if="jobObject.assigned == ' - ' && jobObject.state != 'completed'"
            >
              <v-btn
                rounded
                prepend-icon="mdi-account-plus"
                variant="tonal"
                size="small"
                @click="confirmAction('assign')"
                >Assign to myself</v-btn
              >
            </template>
            <template
              v-if="jobObject.assigned != ' - ' && jobObject.state != 'completed'"
            >
              <v-chip
                closable
                size="small"
                variant="outlined"
                @click:close="confirmAction('unassign')"
                >{{ jobObject.assigned }}</v-chip
              >
            </template>
          </div>
        </v-col>
      </v-row>
    </v-alert>
  </v-hover>
  <v-dialog v-model="dialogConfirm">
    <v-card
      class="mx-auto"
      elevation="16"
      min-width="300"
      max-width="600"
      title="CONFIRM"
      color="cards"
    >
      <v-divider class="mt-3"></v-divider>
      <div class="py-12 text-center">
        <div class="text-h5 font-weight-bold mx-16">{{ dialogConfirmText }}</div>
      </div>
      <v-divider></v-divider>
      <div class="pa-4 text-end">
        <v-btn
          class="text-none"
          color="error"
          min-width="92"
          rounded
          variant="flat"
          @click="dialogConfirm = false"
        >
          No
        </v-btn>
        <v-btn
          class="ml-4 text-none"
          color="success"
          min-width="92"
          rounded
          variant="flat"
          @click="assignUnassign(jobObject.saleOrder)"
        >
          Yes
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import bus from "vue3-eventbus";
import DialogModal from "@/components/common/dialogModal";
import router from "@/router";
import { assignJob, unassignJob } from "@/firebase";

import JobTag from "@/components/job/jobTag";

export default {
  props: ["jobObject", "cardColor"],
  components: {
    JobTag,
    DialogModal,
  },
  data: () => ({
    sendID: "",
    mainText: "",
    subText: "",
    dialogConfirm: false,
    dialogConfirmText: "",
    dialog: false,
    dialogData: [
      {
        title: "",
        icon: "",
        text: "",
      },
    ],
    userRole: localStorage.userRole,
  }),
  created() {
    this.sendID = this.jobObject.saleOrder;
    this.mainText = "Sales Order : " + this.sendID;
    this.subText = "Job ID: " + this.jobObject.jobID;

    this.userRole = localStorage.userRole;
  },
  methods: {
    openJob(jobObject) {
      //router.replace(`${this.$route.path}/checklist/${jobObject.saleOrder}`);
      router.push(
        "/" + jobObject.situation + "/" + jobObject.state + "/checklist/" + this.sendID
      );
    },
    async confirmAction(type) {
      if (type == "assign") {
        this.dialogConfirmText = "Are you sure you want to assign your self to this job?";
        this.dialogConfirm = true;
      } else if (type == "unassign") {
        this.dialogConfirmText =
          "Are you sure you want to unassign your self from this job?";
        this.dialogConfirm = true;
      }
    },
    async assignUnassign(sendID) {
      this.dialogConfirm = false;
      bus.emit("loadingState", true);

      if (this.jobObject.assigned == " - ") {
        this.dialogData.title = "Assigning";
        this.dialogData.icon = "mdi-account-convert";
        this.dialogData.text = "Assigning Job to you";
        this.dialog = true;
        this.assign(sendID);
      } else {
        this.dialogData.title = "Unassigning";
        this.dialogData.icon = "mdi-account-remove";
        this.dialogData.text = "Unassigning Job from you";
        this.dialog = true;
        this.removeAssigned(sendID);
      }
    },
    async assign(sendID) {
      await assignJob({
        state: this.jobObject.state,
        sendID: sendID,
      }).then((result) => {
        this.dialog = false;
        bus.emit("loadingState", false);
        this.dialogData.title = result.data.response;
        this.dialogData.icon = result.data.icon;
        this.dialogData.text = result.data.message;
        this.dialogData.redirect = "Redirect";
        this.dialog = true;
      });
    },
    async removeAssigned(sendID) {
      await unassignJob({
        state: this.jobObject.state,
        sendID: sendID,
      }).then((result) => {
        this.dialog = false;
        bus.emit("loadingState", false);
        this.dialogData.title = result.data.response;
        this.dialogData.icon = result.data.icon;
        this.dialogData.text = result.data.message;
        this.dialogData.redirect = "Redirect";
        this.dialog = true;
      });
    },
  },
};
</script>

<style></style>
