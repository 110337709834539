<template>
  <NoInternet v-if="connection" />
  <div v-if="noSuchDoc">
    <NoContent :componentColor="state" />
  </div>
  <div v-else>
    <HoldForm
      v-if="
        !['Agent', 'Customer'].includes(userRole) &&
        jobDetailsReady &&
        state != 'completed'
      "
      :jobObject="job[0]"
    />
    <!-- ///////////// First Card ///////////////////// -->
    <JobFormOffset v-if="jobDetailsReady" :jobObject="job[0]" />

    <!-- ///////////// Stages ///////////////////// -->
    <v-row justify="center" no-gutters>
      <v-col cols="12" v-for="(stage, index) in stages" :key="index">
        <JobCard v-if="stage.stage == 'job'" :stageObject="stage" />
        <ArtworkCard v-if="stage.stage == 'artwork'" :stageObject="stage" />
        <ReproCard v-if="stage.stage == 'repro'" :stageObject="stage" />
        <OSLCard v-if="stage.stage == 'osl'" :stageObject="stage" />
        <VPSCard v-if="stage.stage == 'vps'" :stageObject="stage" />
        <CTPCard v-if="stage.stage == 'ctp'" :stageObject="stage" />
        <PlateoutCard v-if="stage.stage == 'plateout'" :stageObject="stage" />
        <StoreCard v-if="stage.stage == 'store'" :stageObject="stage" />
        <DispatchCard
          v-if="stage.stage == 'dispatch'"
          :stageObject="stage"
          :jobObject="job[0]"
        />
        <ActionCard v-if="stage.stage == 'action'" :stageObject="stage" />
        <RedoCard v-if="stage.stage == 'redo'" :stageObject="stage" />
        <TextCard v-if="stage.stage == 'text'" :stageObject="stage" />
        <ChargesCard
          v-if="stage.stage == 'charges'"
          :stageObject="stage"
          :jobObject="job[0]"
        />
        <DiscountCard
          v-if="stage.stage == 'discount'"
          :stageObject="stage"
          :jobObject="job[0]"
        />
        <plateSlipCard v-if="stage.stage == 'plateslip'" :stageObject="stage" />
        <HoldCard
          v-if="stage.stage == 'hold' || stage.stage == 'unhold'"
          :stageObject="stage"
        />
      </v-col>
    </v-row>

    <!-- ///////////// Last Card ///////////////////// -->
    <div
      v-if="
        ['Administrator', 'Manager', 'Moderator', 'Designer', 'CTP_Operator'].includes(
          userRole
        ) && fullyLoaded
      "
    >
      <LastCard
        v-if="!job[0].hold"
        :jobObject="job[0]"
        :uvVarnishFlexo="uvVarnishFlexo"
        :specialGold="specialGold"
        :plateOutExist="plateOutExist"
        :storeExist="storeExist"
        :slipID="slipID"
      />
    </div>
    <div v-if="['Coordinator'].includes(userRole) && fullyLoaded">
      <PlateSlipForm :jobObject="job[0]" />
    </div>
  </div>
  <spinner v-if="working" componentColor="primary" />

  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { useRoute } from "vue-router";
import moment from "moment";
import bus from "vue3-eventbus";
import router from "@/router";

import NoContent from "@/components/common/noJobs";
import Spinner from "@/components/common/spinner";
import NoInternet from "@/components/common/noInternet";
import DialogModal from "@/components/common/dialogModal";
import HoldForm from "@/components/job/holdForm";
import HoldCard from "@/components/job/holdCard";
import JobFormOffset from "@/components/offset_stages/jobForm";
import JobCard from "@/components/offset_stages/jobCard";
import ArtworkCard from "@/components/artwork/artworkCard";
import ReproCard from "@/components/offset_stages/reproCard";
import OSLCard from "@/components/offset_stages/oslCard";
import VPSCard from "@/components/offset_stages/vpsCard";
import CTPCard from "@/components/offset_stages/ctpCard";
import PlateoutCard from "@/components/offset_stages/plateoutCard";
import StoreCard from "@/components/offset_stages/storeCard";
import DispatchCard from "@/components/offset_stages/dispatchCard";
import ActionCard from "@/components/offset_stages/actionCard";
import RedoCard from "@/components/offset_stages/redoCard";
import TextCard from "@/components/offset_stages/textCard";
import ChargesCard from "@/components/job/chargesCard";
import DiscountCard from "@/components/job/discountCard";
import PlateSlipForm from "@/components/offset_stages/plateSlipForm";
import plateSlipCard from "@/components/offset_stages/plateSlipCard";
import LastCard from "@/components/job/lastCard";

import { firestore } from "@/firebase";
import { collection, query } from "firebase/firestore";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";

export default {
  data: () => ({
    userRole: localStorage.userRole,
    connection: false,
    working: true,
    jobDetailsReady: false,
    fullyLoaded: false,
    noSuchDoc: false,
    state: "",
    job: [],
    stages: [],
    dialog: false,
    dialogData: [
      {
        title: "",
        icon: "",
        text: "",
      },
    ],
    holdRules: [
      (v) => !!v || "Job reason is required",
      (v) =>
        (v && v.length >= 6 && v.length <= 250) || "Must be between 6 to 250 characters",
    ],
    slipID: null,
    uvVarnishFlexo: false,
    specialGold: false,
    plateOutExist: false,
    storeExist: false,
    cache: {},
  }),
  components: {
    Spinner,
    NoContent,
    NoInternet,
    HoldForm,
    HoldCard,
    JobFormOffset,
    JobCard,
    ArtworkCard,
    ReproCard,
    OSLCard,
    VPSCard,
    CTPCard,
    PlateoutCard,
    StoreCard,
    DispatchCard,
    ActionCard,
    RedoCard,
    TextCard,
    ChargesCard,
    DiscountCard,
    PlateSlipForm,
    plateSlipCard,
    LastCard,
    DialogModal,
  },
  created() {
    this.userRole = localStorage.userRole;
    this.state = this.$route.path.split("/").slice(2, 3).toString();
    this.working = true;
    bus.emit("loadingState", true);
    bus.on("reloadComponents", (reloadType) => {
      this[reloadType]();
    });

    this.getJobDetails();
  },
  methods: {
    reloadCards() {
      this.connection = false;
      this.jobDetailsReady = false;
      this.fullyLoaded = false;
      this.noSuchDoc = false;
      this.slipID = null;
      this.working = true;
      bus.emit("loadingState", true);
      this.getJobDetails();
    },
    reloadStages() {
      (this.stages = []), (this.connection = false);
      this.fullyLoaded = false;
      this.working = true;
      bus.emit("loadingState", true);
      this.getStages();
    },
    async getJobDetails() {
      let jobRef = doc(firestore, "jobs", this.$route.params.jobID);

      const docSnap = await getDoc(jobRef);
      const docData = docSnap.data();

      if (docSnap.exists()) {
        if (this.state == docData.currState) {
          this.job = [];
          this.job.push({
            situation: "jobs",
            type: docData.type,
            hold: docData.hold,
            state: docData.currState,
            nextState: docData.nextState,
            saleOrder: docData.SO,
            jobID: docData.jobID,
            purchaseOrder: docData.PO,
            structualID: docData.structualID,
            description: docData.description,
            customer: await docData.customer,
            factory: await docData.factory,
            location: await docData.location,
            agent: await docData.agent,
            merged: docData.merged,
            open: docData.open,
            items: docData.items ? docData.items : [],
            assigned: await this.getName(docData.assigned, "users"),
            createdBy: await this.getName(docData.createdBy, "users"),
            created: this.formatDateTime(docData.created, "DD MMM YYYY - HH:MM"),
          });
          this.jobDetailsReady = true;

          this.stages = [];
          this.getStages();
        } else {
          router.replace(
            `/jobs/${docData.currState}/checklist/${this.$route.params.jobID}`
          );
        }
      } else {
        console.log("No such document!");
        this.noSuchDoc = true;
        this.working = false;
        bus.emit("loadingState", false);
      }
    },
    async getStages() {
      try {
        let stagesRef = collection(firestore, "jobs", this.$route.params.jobID, "stages");
        let q;

        q = await query(stagesRef, orderBy("timestamp"));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.metadata.fromCache) {
          this.connection = true;
        } else {
          for (let i = 0; i < querySnapshot.docs.length; i++) {
            var data;
            if (querySnapshot.docs[i].data().stage == "artwork") {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                jobID: querySnapshot.docs[i].data().jobID,
                docID: querySnapshot.docs[i].data().docID,
                itemCode: querySnapshot.docs[i].data().itemCode,
                task: querySnapshot.docs[i].data().task,
                note: querySnapshot.docs[i].data().note,
                mins: querySnapshot.docs[i].data().mins,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "repro") {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                mins: querySnapshot.docs[i].data().mins,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "osl") {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                dieLines: querySnapshot.docs[i].data().dieLines,
                emDeboss: querySnapshot.docs[i].data().emDeboss,
                foil: querySnapshot.docs[i].data().foil,
                uvVarnishFlexo: querySnapshot.docs[i].data().uvVarnishFlexo,
                uvVarnishDigital: querySnapshot.docs[i].data().uvVarnishDigital,
                varnish: querySnapshot.docs[i].data().varnish,
                specialGold: querySnapshot.docs[i].data().specialGold,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
              this.uvVarnishFlexo =
                querySnapshot.docs[i].data().uvVarnishFlexo == "n/a" ? false : true;
              this.specialGold =
                querySnapshot.docs[i].data().specialGold == "n/a" ? false : true;
            } else if (querySnapshot.docs[i].data().stage == "vps") {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                task: querySnapshot.docs[i].data().task,
                note: querySnapshot.docs[i].data().note,
                mins: querySnapshot.docs[i].data().mins,
                gripper: querySnapshot.docs[i].data().gripper,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "ctp") {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "plateout") {
              this.plateOutExist = true;
              data = {
                stage: querySnapshot.docs[i].data().stage,
                location: await this.getName(
                  querySnapshot.docs[i].data().location,
                  "locations"
                ),
                checkItems: querySnapshot.docs[i].data().checkItems,
                plateSize: await this.getName(
                  querySnapshot.docs[i].data().plateSize,
                  "plateSizes"
                ),
                note: querySnapshot.docs[i].data().note,
                used: querySnapshot.docs[i].data().used,
                wasted: querySnapshot.docs[i].data().wasted,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "store") {
              this.storeExist = true;
              data = {
                stage: querySnapshot.docs[i].data().stage,
                film:
                  querySnapshot.docs[i].data().film == undefined
                    ? null
                    : querySnapshot.docs[i].data().film,
                gold:
                  querySnapshot.docs[i].data().gold == undefined
                    ? null
                    : querySnapshot.docs[i].data().gold,
                rack: querySnapshot.docs[i].data().rack,
                shelf: querySnapshot.docs[i].data().shelf,
                jobID: querySnapshot.docs[i].data().jobID,
                location: await this.getName(
                  querySnapshot.docs[i].data().location,
                  "locations"
                ),
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "dispatch") {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                slipID: querySnapshot.docs[i].data().slipID,
                confirmID: querySnapshot.docs[i].data().confirmID,
                note: querySnapshot.docs[i].data().note,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "action") {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                icon: querySnapshot.docs[i].data().icon,
                text: querySnapshot.docs[i].data().text,
                to: querySnapshot.docs[i].data().to,
                from: querySnapshot.docs[i].data().from,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "redo") {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                icon: querySnapshot.docs[i].data().icon,
                text: querySnapshot.docs[i].data().text,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
              this.slipID = querySnapshot.docs[i].data().slipID;
            } else if (querySnapshot.docs[i].data().stage == "charges") {
              data = {
                docID: querySnapshot.docs[i].id,
                stage: querySnapshot.docs[i].data().stage,
                report: querySnapshot.docs[i].data().report,
                charged: querySnapshot.docs[i].data().charged,
                mins: querySnapshot.docs[i].data().mins,
                rate: querySnapshot.docs[i].data().rate,
                amount: querySnapshot.docs[i].data().amount,
                discountApplied: querySnapshot.docs[i].data().discountApplied,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "discount") {
              data = {
                docID: querySnapshot.docs[i].id,
                stage: querySnapshot.docs[i].data().stage,
                afterDiscount: querySnapshot.docs[i].data().afterDiscount,
                beforeDiscount: querySnapshot.docs[i].data().beforeDiscount,
                discount: querySnapshot.docs[i].data().discount,
                discountType: querySnapshot.docs[i].data().discountType,
                reason: querySnapshot.docs[i].data().reason,
                discountApplied: querySnapshot.docs[i].data().discountApplied,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "plateslip") {
              data = {
                docID: querySnapshot.docs[i].id,
                stage: querySnapshot.docs[i].data().stage,
                plateSlip: querySnapshot.docs[i].data().plateSlip,
                selectedPlates: querySnapshot.docs[i].data().selectedPlates,
                reason: querySnapshot.docs[i].data().reason,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "text") {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                text: querySnapshot.docs[i].data().text,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (
              querySnapshot.docs[i].data().stage == "hold" ||
              querySnapshot.docs[i].data().stage == "unhold"
            ) {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                reason: querySnapshot.docs[i].data().reason,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else if (querySnapshot.docs[i].data().stage == "job") {
              data = {
                stage: querySnapshot.docs[i].data().stage,
                text: querySnapshot.docs[i].data().text,
                time: this.formatDateTime(
                  querySnapshot.docs[i].data().timestamp,
                  "DD MMM YYYY - hh:mm A"
                ),
                user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
              };
            } else {
              //
            }
            this.stages.push(data);
          }
        }

        this.fullyLoaded = true;
        this.working = false;
        bus.emit("loadingState", false);
      } catch (error) {
        this.handleError(error);
      }
    },
    async getName(document, collection) {
      if (!document) {
        return " - ";
      }

      const cacheKey = `${collection}${document}`;
      if (this.cache[cacheKey]) {
        return this.cache[cacheKey];
      }

      const docRef = doc(firestore, collection, document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          return " - ";
        }

        this.cache[cacheKey] = docSnap.data().name;
        return this.cache[cacheKey];
      } catch (error) {
        this.handleError(error);
      }
    },
    formatDateTime(timeStamp, format) {
      if (timeStamp != null) {
        var m = moment(timeStamp.toDate());
        var mFormatted = m.format(format);
        return mFormatted;
      } else {
        return " - ";
      }
    },
    handleError(error) {
      if (error.code == "permission-denied") {
        this.jobDetailsReady = false;
      }
      this.dialogData = {
        title: "error",
        icon: "mdi-close-circle-outline",
        text: error.message,
        redirect: "/",
      };
      this.dialog = true;
    },
  },
};
</script>

<style></style>
