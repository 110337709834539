<template>
  <v-row justify="start">
    <v-col cols="12" md="8">
      <v-text-field
        v-model="search"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
      <v-data-table
        v-model:search="search"
        :headers="headers"
        :items="invoices"
        v-model:page="page"
        :loading="loading"
        item-key="docID"
        item-value="docID"
        class="elevation-1"
        @page-count="pageCount = $event"
        :items-per-page-options="[10, 20, 25, 50]"
        @click:row="onRowClick"
        @update:page="loadMore"
        :mobile="this.$vuetify.display.mobile"
      >
        <template v-slot:[`item.state`]="{ item }">
          <v-chip :color="item.state == 'approved' ? 'success' : 'error'" dark>{{
            item.state
          }}</v-chip>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" md="4" class="image-container">
      <div class="image-wrapper">
        <v-progress-circular
          v-if="imageLoading"
          indeterminate
          color="primary"
          class="spinner"
          size="100"
          :width="10"
        />
        <div v-if="imageUrl != 'skipped'">
          <img
            v-if="imageUrl"
            :src="imageUrl"
            alt="Invoice Image"
            @click="showLightbox = true"
            class="clickable-image"
          />
          <vue-easy-lightbox
            :visible="showLightbox"
            :imgs="[imageUrl]"
            @hide="showLightbox = false"
          />
        </div>
        <div v-else>
          <v-icon
            class="clickable-image"
            icon="mdi-file-hidden"
            size="300"
            color="error"
          ></v-icon>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" @close="dialog = false" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import VueEasyLightbox from "vue-easy-lightbox";
import moment from "moment";
import DialogModal from "@/components/common/dialogModal";
import { firestore, storage } from "@/firebase";
import { query, where, orderBy, startAfter, limit } from "firebase/firestore";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";

export default {
  data() {
    return {
      search: "",
      invoices: [],
      headers: [
        { title: "Created", value: "created" },
        { title: "Created By", value: "createdBy" },
        { title: "Approved", value: "approved" },
        { title: "Approved By", value: "approvedBy" },
        { title: "Filename/Invoice No", value: "filename" },
        { title: "Customer", value: "customer" },
        { title: "State", value: "state" },
      ],
      page: 1,
      pageCount: 0,
      loading: false,
      imageLoading: false,
      imageUrl: null,
      showLightbox: false,
      lastDoc: null,
      noMore: false,
      cache: {},
      dialog: false,
      dialogData: {},
      selectedRow: null,
    };
  },
  components: {
    VueEasyLightbox,
    DialogModal,
  },
  async mounted() {
    await this.fetchInvoices();
  },
  methods: {
    async fetchInvoices() {
      if (this.noMore) return;

      this.loading = true;
      bus.emit("loadingState", true);

      let q = query(
        collection(firestore, "invoices"),
        where("state", "in", ["approved", "skipped"]),
        orderBy("created", "desc"),
        limit(300)
      );

      if (this.lastDoc) {
        q = query(q, startAfter(this.lastDoc));
      }

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        this.noMore = true;
      } else {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const newInvoices = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const createdByName = await this.getName(data.createdBy, "users");
            const approvedByName = await this.getName(data.approvedBy, "users");
            const customerName = await this.getCustomer(data.customer);

            return {
              docID: doc.id,
              ...data,
              created: this.formatDateWithTime(data.created.toDate()),
              createdBy: createdByName,
              approved: this.formatDateWithTime(data.approved.toDate()),
              approvedBy: approvedByName,
              customer: customerName,
            };
          })
        );

        this.invoices = [...this.invoices, ...newInvoices];
      }

      this.loading = false;
      bus.emit("loadingState", false);
    },
    async onRowClick(_, row) {
      this.selectedRow = row.item.docID;
      this.imageLoading = true;
      this.imageUrl = null;
      if (row.item.state == "approved") {
        try {
          const imageRef = ref(storage, row.item.approvedURL);
          this.imageUrl = await getDownloadURL(imageRef);
        } catch (error) {
          console.error("Error fetching image URL:", error);
        } finally {
          this.imageLoading = false;
        }
      } else {
        this.imageUrl = "skipped";
        this.imageLoading = false;
      }
    },
    async getName(document, collection) {
      if (!document) return " - ";

      if (this.cache[document]) return this.cache[document];

      const docRef = doc(firestore, collection, document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) return " - ";

        this.cache[document] = docSnap.data().name;
        return this.cache[document];
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      }
    },
    async getCustomer(document) {
      if (!document) return " - ";

      if (this.cache[document]) return this.cache[document];

      const docRef = doc(firestore, "automation", "invoice", "customers", document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) return " - ";

        this.cache[document] = docSnap.data().name;
        return this.cache[document];
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      }
    },
    showDialog(title, icon, message, action) {
      this.sending = false;
      this.dialog = false;
      this.dialogData = {
        title: title,
        icon: icon,
        text: message,
        redirect: action,
      };
      this.dialog = true;
    },
    formatDateWithTime(timeStamp) {
      return moment(timeStamp).format("DD-MM-YYYY - hh:mm:ss a");
    },
    loadMore() {
      if (this.page >= this.pageCount - 1) {
        this.fetchInvoices();
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84vh;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clickable-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
  display: block;
  margin: auto;
}

.highlighted-row {
  background-color: #e0f7fa; /* You can choose any color */
}
</style>
