<template>
    <v-form ref="formItem" :disabled="!isEditing">
        <v-card variant="tonal" :loading="working">
            <v-card-text>
                <v-row justify="center" align="center">
                    <v-col cols="12" lg="4">
                        <div class="text-body-1">
                            Job ID: {{ itemObject.jobID }}
                        </div>
                        <div class="text-h4">
                            {{ title }}
                        </div>
                        <br>
                        <div class="text-body-1">
                            Sales Order: {{ salesOrder }}
                        </div>
                        <br>
                        <br>
                        <div class="text-caption">
                            {{ createdBy }}
                            <br>
                            {{ created }}
                        </div>
                    </v-col>
                    <v-col cols="12" lg="2">
                        <v-icon :icon="itemObject.merged ? 'mdi-link-lock' : 'mdi-link-off'" size="80"></v-icon>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-combobox multiple label="Structural ID" v-model="structualID" density="comfortable"
                            :rules="structualIDRules">
                            <template v-slot:selection="data">
                                <v-chip :selected="data.selected" class="chip-class" size="large">
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-combobox>
                        <v-textarea label="Description" rows="4" counter="260" v-model="description">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions v-if="!itemObject.merged">
                <v-spacer></v-spacer>
                <v-btn v-if="isEditing" :loading="working" @click="update" :disabled="!modified">
                    Update
                </v-btn>
                <v-btn v-if="userRoles.includes(userRole)" @click="isEditing = !isEditing">
                    {{ isEditing ? 'Cancel' : 'Edit' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>

    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import DialogModal from "@/components/common/dialogModal";
import { firestore, updateItem } from "@/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default {
    props: {
        itemObject: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        userRoles: ['Administrator', 'Manager', 'Moderator', 'Designer'],
        working: true,
        isEditing: false,
        modified: false,
        title: "",
        userRole: "",
        salesOrder: "",
        structualID: [],
        description: "",
        created: "",
        createdBy: "",
        dialog: false,
        dialogData: [{
            title: "",
            icon: "",
            text: "",
            redirect: null,
        }],
        requiredRule: [(v) => !!v || "This is required"],
        structualIDRules: [
            (value) =>
                value.every((v) => v.length === 0 || v.length >= 4) ||
                "Must be more than 4 characters long.",
        ],
    }),
    components: {
        DialogModal,
    },
    watch: {
        structualID: 'checkIfModified',
        description: 'checkIfModified'
    },
    created()
    {
        this.working = false;
        bus.emit("loadingState", false);
        this.userRole = localStorage.userRole;
        this.intData();
    },
    methods: {
        intData()
        {
            this.title = "ITEM " + this.itemObject.itemCode.toString().padStart(2, '0');
            this.salesOrder = this.itemObject.SO == null ? " -" : this.itemObject.SO;
            this.structualID = this.itemObject.structualID;
            this.description = this.itemObject.description;
            this.created = this.itemObject.timestamp;
            this.createdBy = this.itemObject.user
            this.originalStructualID = [...this.structualID];
            this.originalDescription = this.description;
        },
        checkIfModified()
        {
            this.modified = this.structualID.join(',') !== this.originalStructualID.join(',') ||
                this.description !== this.originalDescription;
        },
        async update()
        {
            this.working = true;
            bus.emit("loadingState", true);
            this.showDialog({
                title: "Updating",
                icon: "mdi-note-text",
                text: "Updating Item",
                redirect: null,
            });

            let sendData = {
                jobID: this.itemObject.jobID,
                docID: this.itemObject.docID,
                structualID: this.structualID,
                description: this.description,
            }

            await updateItem(sendData).then((result) =>
            {
                bus.emit("loadingState", false);
                this.working = false;
                this.showDialog({
                    title: result.data.response,
                    icon: result.data.icon,
                    text: result.data.message,
                    redirect: "reloadCards"
                });
            });
        },
        showDialog(data)
        {
            this.dialogData = data;
            this.dialog = true;
        }
    }
}
</script>

<style></style>