<template>
    <div class="ml-4">
        <v-chip v-if="jobType == 'New Job'" class="text-none text-subtitle-1" color="secondary" label size="small"
            rounded="0" variant="flat">
            <v-icon start icon="mdi-plus"></v-icon>{{ jobType }}
        </v-chip>
        <v-chip v-if="jobType == 'Repeat Job'" class="text-none text-subtitle-1" color="secondaryWarn" label size="small"
            rounded="0" variant="flat">
            <v-icon start icon="mdi-refresh"></v-icon>{{ jobType }}
        </v-chip>
        <v-chip v-if="jobType == 'Plate Only'" class="text-none text-subtitle-1" color="neutral" label size="small"
            rounded="0" variant="flat">
            <v-icon start icon="mdi-format-paint"></v-icon>{{ jobType }}
        </v-chip>
    </div>
</template>

<script>
export default {
    props: ["jobType"],
} 
</script>

<style></style>