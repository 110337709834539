<template>
  <div v-if="maintenance" class="background">
    <div class="text-h4 text-center pt-16">UNDER MAINTENANCE</div>
    <div class="text-h5 text-center">Be back in</div>
    <p class="text-h2 text-center pt-8">{{ countdown }}</p>
    <Vue3Lottie :animationData="Maintenance" :height="300" :width="300" :speed="1" />
    <div class="py-16"></div>
  </div>
  <div v-else>
    <v-app class="background" v-if="$route.meta.requiresAuth">
      <v-navigation-drawer v-model="drawer" color="drawer" :permanent="!$vuetify.display.mobile">
        <Drawer />
        <template v-slot:append>
          <Latency />
        </template>
      </v-navigation-drawer>

      <v-app-bar :color="navBarColor" prominent :order="1" :elevation="0">
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <UserDetails />
        <v-progress-linear :active="loading" indeterminate absolute bottom height="7" color="loader" />
      </v-app-bar>

      <v-main class="d-flex align-top justify-center">
        <v-container fluid :class="{ 'no-padding': $route.name === 'PDF' }">
          <router-view :key="$route.path" />
        </v-container>
      </v-main>

      <AllowNotification />

      <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" />
      </v-dialog>
    </v-app>

    <div v-if="$route.meta.requiresGuest">
      <router-view />
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, onBeforeUnmount, computed } from "vue";
import { useTheme } from "vuetify";
import bus from "vue3-eventbus";
import router from "@/router";
import Maintenance from "@/assets/animjson/maintenance.json";
import { firestore, auth, getCurrentUser } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";
import AllowNotification from "@/components/common/allowNotification";
import DialogModal from "@/components/common/dialogModal";
import Drawer from "@/components/common/Drawer/Drawer";
import UserDetails from "@/components/common/userDetails";
import Latency from "@/components/common/latency";

export default {
  name: "App",
  setup() {
    const theme = useTheme();
    const maintenance = ref(false);
    const countdown = ref("--");
    const onLine = ref(navigator.onLine);
    const showBackOnline = ref(false);
    const drawer = ref(true);
    const loading = ref(true);
    const dialog = ref(false);
    const dialogData = ref({
      title: "",
      icon: "",
      text: "",
    });

    const setTheme = (darkmode) => {
      theme.global.name.value = darkmode ? "darkTheme" : "lightTheme";
    };

    const navBarColor = computed(() => {
      return process.env.NODE_ENV === "development" ||
        ["beta.workflow.printcarepremedia.com", "ppm-workflow-dev.web.app", "ppm-workflow-dev.firebaseapp.com"].includes(window.location.hostname)
        ? "secondaryError"
        : "navBar";
    });

    const updateOnlineStatus = (e) => {
      onLine.value = e.type === "online";
    };

    const fetchMaintenanceData = async () => {
      const docRef = doc(firestore, "analytics", "maintenance");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        maintenance.value = data.state;
        if (maintenance.value) {
          startCountdown(data.countdown.toDate());
        }
      }
    };

    const startCountdown = (targetTime) => {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = targetTime - now;

        if (distance < 0) {
          clearInterval(interval);
          countdown.value = "EXPIRED";
          onCountdownComplete();
        } else {
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          countdown.value = `${hours > 0 ? hours + "h " : ""}${minutes}m ${seconds}s`;
        }
      }, 1000);
    };

    const onCountdownComplete = () => {
      router.push(`/`);
    };

    const checkForUpdate = async () => {
      if (!localStorage.uid) {
        getCurrentUser();
      } else {
        const docRef = doc(firestore, "users", localStorage.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.data().update) {
          dialogData.value.title = "Update Available";
          dialogData.value.text =
            "The App has an update, click okay to load the latest version";
          dialogData.value.icon = "mdi-update";
          dialogData.value.redirect = "Update";
          dialog.value = true;
        }
      }
    };

    const checkAppControls = () => {
      fetchMaintenanceData();
      checkForUpdate();
    };

    bus.on("loadingState", (loadingState) => {
      loading.value = loadingState;
    });

    router.beforeEach((to, from, next) => {
      if (auth.currentUser) {
        checkAppControls();
      }
      next();
    });

    if (localStorage.darkmode === "true") {
      setTheme(true);
    } else {
      setTheme(false);
    }

    onMounted(() => {
      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
      fetchMaintenanceData();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    });

    watch(onLine, (value) => {
      if (value) {
        showBackOnline.value = true;
        setTimeout(() => {
          showBackOnline.value = false;
        }, 1000);
      }
    });

    return {
      maintenance,
      countdown,
      onLine,
      showBackOnline,
      drawer,
      loading,
      dialog,
      dialogData,
      Maintenance,
      setTheme,
      navBarColor,
    };
  },
  components: {
    Drawer,
    UserDetails,
    Latency,
    DialogModal,
    AllowNotification,
  },
};
</script>

<style>
.background {
  height: 100%;
  width: 100%;
  background-color: rgb(var(--v-theme-background));
}

.no-padding {
  padding: 0;
}
</style>
