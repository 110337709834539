<template>
    <v-btn v-if="btnVisible" variant="tonal" @click="complete" :disabled="working">Complete</v-btn>
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
import DialogModal from "@/components/common/dialogModal";
import bus from "vue3-eventbus";
import { markComplete } from "@/firebase";

export default {
    props: ["jobObject"],
    data() {
        return {
            working: false,
            btnVisible: ["Administrator", "Manager"].includes(localStorage.userRole),
            dialog: false,
            dialogData: {
                title: "",
                icon: "",
                text: ""
            },
        };
    },
    components: {
        DialogModal,
    },
    methods: {
        async complete() {
            this.working = true;
            bus.emit("loadingState", true);
            Object.assign(this.dialogData, {
                title: "Forwarding",
                text: "Sending to Complete",
                icon: "mdi-forwardburger"
            });
            this.dialog = true;

            try {
                const result = await markComplete({ saleOrder: this.jobObject.saleOrder });
                Object.assign(this.dialogData, {
                    title: result.data.response,
                    icon: result.data.icon,
                    text: result.data.message,
                    redirect: "/jobs/completed"
                });
            } finally {
                this.working = false;
                bus.emit("loadingState", false);
                this.dialog = true;
            }
        }
    },
};
</script>

