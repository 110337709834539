<template>
  <v-card class="mt-4" color="cards">
    <v-toolbar flat density="compact" color="navBar" title="CTP" />
    <v-row justify="space-between" align="center" class="ma-3" no-gutters>
      <v-col cols="12" md="3">
        <div class="text-h6 font-weight-bold text-center">
          <v-icon
            color="success"
            icon="mdi-checkbox-marked-circle-outline"
            size="64"
          ></v-icon
          >CTP PASSED
        </div>
      </v-col>
      <v-col cols="12" md="9" class="d-flex flex-wrap">
        <div v-for="item in checkItems" :key="item">
          <v-checkbox
            class="mx-1"
            density="compact"
            v-model="item.state"
            :label="item.label"
            color="success"
            disabled
            hide-details
          ></v-checkbox>
        </div>
      </v-col>
    </v-row>
    <UserTimeStrip :stageObject="stageObject" />
  </v-card>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";

export default {
  props: ["stageObject"],
  components: {
    UserTimeStrip,
  },
  data: () => ({
    checkItems: [
      { label: "Colours as per ticket", state: true },
      { label: "Expiry dates", state: true },
      { label: "Barcode", state: true },
      { label: "SO/PO Station number", state: true },
      { label: "Customer IC", state: true },
      { label: "REG Marks", state: true },
      { label: "Screen rulling & Angles", state: true },
      { label: "Job version", state: true },
      { label: "Cutter No", state: true },
      { label: "Design & Text", state: true },
      { label: "UPs", state: true },
    ],
  }),
};
</script>

<style></style>
