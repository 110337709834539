<template>
  <NoInternet v-if="!connection" />
  <NoContent v-if="connection && !working && jobs.length == 0" :componentColor="state" />
  <v-app-bar
    color="navBar"
    :order="2"
    scroll-behavior="hide collapse"
    scroll-threshold="250"
    :elevation="4"
    density="compact"
  >
    <v-app-bar-nav-icon v-if="showScrollTop" @click="scrollTop">
      <v-icon> mdi-arrow-collapse-up </v-icon>
    </v-app-bar-nav-icon>
    <v-app-bar-title class="mt-3">
      <v-row no-gutters justify="end">
        <v-spacer></v-spacer>
        <v-col cols="3" v-if="!this.$vuetify.display.mobile">
          <v-select
            label="Order by"
            class="scaling-size"
            flat
            rounded
            bg-color="navBar"
            v-model="sort"
            item-title="name"
            item-value="value"
            :items="sortItems"
            variant="solo-filled"
            density="compact"
            :disabled="working"
            @update:model-value="sortChanged"
          ></v-select>
        </v-col>
        <v-col cols="auto" v-if="!this.$vuetify.display.mobile">
          <v-select
            class="scaling-size"
            flat
            rounded
            bg-color="navBar"
            v-model="order"
            item-title="name"
            item-value="value"
            :items="orderItems"
            variant="solo-filled"
            density="compact"
            :disabled="working"
            @update:model-value="sortChanged"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            label="Location"
            class="mr-4 scaling-size"
            flat
            rounded
            bg-color="cards"
            v-model="location"
            item-title="name"
            item-value="value"
            :items="locationItems"
            variant="solo-filled"
            density="compact"
            :disabled="working"
            @update:model-value="sortChanged"
          ></v-select>
        </v-col>
      </v-row>
    </v-app-bar-title>
  </v-app-bar>
  <v-row justify="center" no-gutters>
    <v-col cols="12" v-for="job in jobs" :key="job.id">
      <ArtworkStrip v-if="job.state == 'artwork'" :jobObject="job" :cardColor="state" />
      <JobStrip v-else :jobObject="job" :cardColor="state" />
    </v-col>
  </v-row>
  <Observer @intersect="intersected" />
  <Spinner v-if="working" :componentColor="state" />
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import moment from "moment";

import Spinner from "@/components/common/spinner";
import NoContent from "@/components/common/noJobs";
import NoInternet from "@/components/common/noInternet";
import DialogModal from "@/components/common/dialogModal";
import Observer from "@/components/common/Observer";
import JobStrip from "@/components/job/jobStrip";
import ArtworkStrip from "@/components/artwork/artworkStrip";

import { firestore } from "@/firebase";
import { collection, query } from "firebase/firestore";
import { doc, where, getDoc, getDocs } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";

export default {
  data: () => ({
    connection: true,
    working: true,
    situation: "",
    state: "",
    jobs: [],
    noMore: false,
    lastDoc: false,
    showScrollTop: false,
    dialog: false,
    dialogData: [
      {
        title: "",
        icon: "",
        text: "",
      },
    ],
    sort: "modified",
    sortItems: [
      { name: "Modified Date", value: "modified" },
      { name: "Sales Order", value: "SO" },
      { name: "Created Date", value: "created" },
    ],
    order: "desc",
    orderItems: [
      { name: "Ascending", value: "asc" },
      { name: "Descending", value: "desc" },
    ],
    location: "",
    locationItems: [
      { name: "All", value: "" },
      { name: "Kelaniya", value: "rjoKKCBYPynZ5SaheJzr" },
      { name: "Kadawatha", value: "NFYkURh8sanbrx4FMnyj" },
      { name: "Nairobi", value: "BBS0FPUH8wSdRkfX0wf7" },
    ],
    userRole: localStorage.userRole,
    selectedAgents: [],
    queryLimit: 6,
    cache: {},
  }),
  components: {
    Spinner,
    NoContent,
    NoInternet,
    JobStrip,
    ArtworkStrip,
    DialogModal,
    Observer,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  async created() {
    const pathParts = this.$route.path.split("/");
    this.situation = pathParts[1];
    if (this.situation === "artworks") {
      this.state = "artwork";
    } else {
      this.state = pathParts.slice(2).join("/");
    }
    this.userRole = localStorage.userRole;
    this.sort = this.getValidValue(
      localStorage.sort,
      ["modified", "SO", "created"],
      "modified"
    );
    this.order = this.getValidValue(localStorage.order, ["asc", "desc"], "desc");
    this.location = this.getValidValue(
      localStorage.location,
      ["", "rjoKKCBYPynZ5SaheJzr", "NFYkURh8sanbrx4FMnyj", "BBS0FPUH8wSdRkfX0wf7"],
      ""
    );
    this.queryLimit = this.situation === "artworks" ? 12 : undefined;

    if (this.userRole === "Agent") {
      const docRef = doc(firestore, "users", localStorage.uid);
      const docSnap = await getDoc(docRef);
      this.selectedAgents = (docSnap.exists() && docSnap.data().agents) || [];
    }

    if (this.userRole === "Coordinator") {
      const docRef = doc(firestore, "users", localStorage.uid);
      const docSnap = await getDoc(docRef);

      // Filter the locationItems array
      this.locationItems = this.locationItems.filter((item) =>
        docSnap.data().locations.includes(item.value)
      );
    }

    this.setConditions();
  },
  methods: {
    getValidValue(value, validValues, defaultValue) {
      return validValues.includes(value) ? value : defaultValue;
    },
    async intersected() {
      if (this.jobs.length > 2) {
        this.setConditions();
      }
    },
    sortChanged() {
      this.working = true;
      bus.emit("loadingState", true);
      bus.emit("reloadJobCount", this.location);

      localStorage.sort = this.sort;
      localStorage.order = this.order;
      localStorage.location = this.location;

      this.jobs = [];
      this.noMore = false;
      this.lastDoc = false;

      this.setConditions();
    },
    handleScroll() {
      this.showScrollTop = window.scrollY > 100; // Adjust the value as needed
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async setConditions() {
      this.working = true;
      bus.emit("loadingState", true);
      let jobsRef = collection(firestore, this.situation);
      let q = null;
      let processesSort;

      if (this.sort == "SO" && this.state == "artwork") {
        processesSort = "jobID";
      } else {
        processesSort = this.sort;
      }

      if (this.noMore == false && this.lastDoc == null) {
        if (this.location == "" || this.location == undefined) {
          if (this.userRole == "Agent") {
            q = query(
              jobsRef,
              where("agent", "in", this.selectedAgents),
              where("currState", "==", this.state),
              where("currState", "==", this.state),
              orderBy("currState"),
              orderBy(processesSort, this.order),
              limit(this.queryLimit)
            );
          } else {
            q = query(
              jobsRef,
              where("currState", "==", this.state),
              orderBy("currState"),
              orderBy(processesSort, this.order),
              limit(this.queryLimit)
            );
          }
        } else {
          if (this.userRole == "Agent") {
            q = query(
              jobsRef,
              where("agent", "in", this.selectedAgents),
              where("location", "==", this.location),
              where("currState", "==", this.state),
              orderBy("currState"),
              orderBy(processesSort, this.order),
              limit(this.queryLimit)
            );
          } else {
            q = query(
              jobsRef,
              where("location", "==", this.location),
              where("currState", "==", this.state),
              orderBy("currState"),
              orderBy(processesSort, this.order),
              limit(this.queryLimit)
            );
          }
        }
        this.getJobs(q);
      } else if (this.noMore == false && this.lastDoc != null) {
        if (this.location == "" || this.location == undefined) {
          if (this.userRole == "Agent") {
            q = query(
              jobsRef,
              where("agent", "in", this.selectedAgents),
              where("currState", "==", this.state),
              orderBy("currState"),
              orderBy(processesSort, this.order),
              startAfter(this.lastDoc),
              limit(this.queryLimit)
            );
          } else {
            q = query(
              jobsRef,
              where("currState", "==", this.state),
              orderBy("currState"),
              orderBy(processesSort, this.order),
              startAfter(this.lastDoc),
              limit(this.queryLimit)
            );
          }
        } else {
          if (this.userRole == "Agent") {
            q = query(
              jobsRef,
              where("agent", "in", this.selectedAgents),
              where("location", "==", this.location),
              where("currState", "==", this.state),
              orderBy("currState"),
              orderBy(processesSort, this.order),
              startAfter(this.lastDoc),
              limit(this.queryLimit)
            );
          } else {
            q = query(
              jobsRef,
              where("location", "==", this.location),
              where("currState", "==", this.state),
              orderBy("currState"),
              orderBy(processesSort, this.order),
              startAfter(this.lastDoc),
              limit(this.queryLimit)
            );
          }
        }
        this.getJobs(q);
      } else {
        console.log("No more");
        this.noMore = true;
        this.working = false;
        bus.emit("loadingState", false);
      }
    },
    async getJobs(q) {
      try {
        const querySnapshot = await getDocs(q);

        if (querySnapshot.metadata.fromCache) {
          this.connection = false;
        } else if (querySnapshot.docs.length === 0) {
          this.noMore = true;
        } else {
          this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

          for (let i = 0; i < querySnapshot.docs.length; i++) {
            this.jobs.push({
              situation: this.situation,
              state: querySnapshot.docs[i].data().currState,
              saleOrder: querySnapshot.docs[i].data().SO,
              type: querySnapshot.docs[i].data().type,
              jobID: querySnapshot.docs[i].data().jobID,
              description: querySnapshot.docs[i].data().description,
              purchaseOrder: querySnapshot.docs[i].data().PO,
              structualID: querySnapshot.docs[i].data().structualID,
              assigned: await this.getName(
                querySnapshot.docs[i].data().assigned,
                "users"
              ),
              open: querySnapshot.docs[i].data().open,
              items: querySnapshot.docs[i].data().items,
              hold: querySnapshot.docs[i].data().hold,
              holded: this.formatDateTime(
                querySnapshot.docs[i].data().holded,
                "DD MMM YYYY - hh:mm A"
              ),
              customer: await this.getName(
                querySnapshot.docs[i].data().customer,
                "customers"
              ),
              agent: await this.getName(querySnapshot.docs[i].data().agent, "agents"),
              factory: await this.getName(
                querySnapshot.docs[i].data().factory,
                "factories"
              ),
              location: await this.getName(
                querySnapshot.docs[i].data().location,
                "locations"
              ),
              created: this.formatDateTime(
                querySnapshot.docs[i].data().created,
                "DD MMM YYYY - hh:mm A"
              ),
            });
          }
        }
        this.working = false;
        bus.emit("loadingState", false);
      } catch (error) {
        this.handleError(error);
      }
    },
    async getName(document, collection) {
      if (!document) {
        return " - ";
      }

      const cacheKey = `${collection}${document}`;
      if (this.cache[cacheKey]) {
        return this.cache[cacheKey];
      }

      const docRef = doc(firestore, collection, document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          return " - ";
        }

        this.cache[cacheKey] = docSnap.data().name;
        return this.cache[cacheKey];
      } catch (error) {
        this.handleError(error);
      }
    },
    formatDateTime(timeStamp, format) {
      if (timeStamp != null || timeStamp != undefined) {
        var m = moment(timeStamp.toDate());
        var mFormatted = m.format(format);
        return mFormatted;
      } else {
        return " - ";
      }
    },
    handleError(error) {
      this.dialogData = {
        title: "error",
        icon: "mdi-close-circle-outline",
        text: error,
        redirect: "/",
      };
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.scaling-size {
  transform: scale(0.9);
}
</style>
