<template>
    <v-card class="mt-4" color="store">
        <v-row justify="center" align="center">
            <v-col cols="12" md="6">
                <div v-if="stageObject.film != null" class="text-body-2 text-center">
                    Flexo UV Varnish for
                </div>
                <div v-else class="text-body-2 text-center">
                    Special Gold Film for
                </div>
                <div class="text-body-1 text-center">
                    Job ID
                    <a class="text-h6">{{ stageObject.jobID }}</a>
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <div v-if="stageObject.film == 'yes' || stageObject.gold == 'yes'">
                    <v-row justify="space-around" align="center">
                        <v-col cols="12" md="6">
                            <v-row justify="start" align="center">
                                <v-col cols="auto">
                                    <Vue3Lottie :animationData="Rack" :height="130" :width="130" />
                                </v-col>
                                <v-col cols="auto">
                                    <div class="text-h6">
                                        RACK
                                    </div>
                                    <div class="text-h4">{{ stageObject.rack }}</div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-row justify="space-around" align="center">
                                <v-col cols="6">
                                    <Vue3Lottie :animationData="Bag" :height="130" :width="130" :speed="1.5" />
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-h6">
                                        BAG
                                    </div>
                                    <div class="text-h4">{{ stageObject.shelf }}</div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="stageObject.film == 'no' || stageObject.gold == 'no'">
                    <v-col cols="12" md="12">
                        <v-row justify="start" align="center">
                            <v-col cols="auto">
                                <Vue3Lottie :animationData="Blanket" :height="130" :width="130" :speed="1" />
                            </v-col>
                            <v-col cols="auto">
                                <div class="text-h6">
                                    FILM NOT AVAILABLE
                                </div>
                                <div class="text-h4">{{ stageObject.shelf }}</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>
                <div v-if="stageObject.film == 'blanket' || stageObject.gold == 'blanket'">
                    <v-col cols="12" md="12">
                        <v-row justify="start" align="center">
                            <v-col cols="auto">
                                <Vue3Lottie :animationData="Cut" :height="130" :width="130" :speed="1" />
                            </v-col>
                            <v-col cols="auto">
                                <div class="text-h6">
                                    BLANKET USED
                                </div>
                                <div class="text-h4">{{ stageObject.shelf }}</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>
                <div v-if="stageObject.film == 'screen' || stageObject.gold == 'screen'">
                    <v-col cols="12" md="12">
                        <v-row justify="start" align="center">
                            <v-col cols="auto">
                                <Vue3Lottie :animationData="Screen" :height="100" :width="100" :speed="1" />
                            </v-col>
                            <v-col cols="auto">
                                <div class="text-h6">
                                    SCREEN PRINT
                                </div>
                                <div class="text-h4">{{ stageObject.shelf }}</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>
            </v-col>
        </v-row>
        <UserTimeStrip :stageObject="stageObject" />
    </v-card>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";
import Screen from '@/assets/animjson/screen.json';
import Rack from '@/assets/animjson/rack.json';
import Bag from '@/assets/animjson/bag.json';
import Cut from '@/assets/animjson/cut.json';
import Blanket from '@/assets/animjson/blanket.json';

export default {
    props: ["stageObject"],
    components: {
        UserTimeStrip
    },
    data() {
        return {
            Screen,
            Rack,
            Bag,
            Cut,
            Blanket
        }
    },

}
</script>

<style></style>