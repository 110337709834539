<template>
   <v-form class="mt-4" ref="form" :disabled="working">
      <v-row justify="center" align="center">
         <v-col cols="12" lg="12" xl="10">
            <v-card color="cards">
               <v-toolbar flat color="navBar" title="REPRO"><Completer :jobObject="jobObject" /></v-toolbar>
               <!---------------------------------------------------------------------------------------------------- REPRO STATE----->
               <v-row justify="start" align="center" class="mx-4 mt-4" no-gutters>
                  <v-col cols="auto" v-for="item in reproItems" :key="item">
                     <v-checkbox class="mx-4" density="compact" v-model="item.state" :label="item.label" :style="{ 'margin-bottom': '-16px' }"
                        :value="item.label" color="success" :rules="requiredRule"></v-checkbox>
                  </v-col>
               </v-row>

               <v-row justify="center">
                  <v-col cols="12" md="8" xl="6">
                     <v-col class="text-left">
                        <span class="text-h2 font-weight-light" v-text="repMins"></span>
                        <span class="subheading font-weight-light me-1"> mins</span>

                     </v-col>
                     <v-slider v-model="repMins" color="primary" track-color="grey" min="0" max="1000" :step="10"
                        label="Repro Charge">
                        <template v-slot:prepend>
                           <v-btn size="small" variant="text" icon="mdi-minus" color="error" @click="decrement"></v-btn>
                        </template>

                        <template v-slot:append>
                           <v-btn size="small" variant="text" icon="mdi-plus" color="success" @click="increment"></v-btn>
                        </template>
                     </v-slider>
                  </v-col>
               </v-row>
               <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="px-4" @click="validate" append-icon="mdi-arrow-collapse-right" variant="flat"
                     :loading="working" color="success">
                     Send for Outsource
                  </v-btn>
               </v-card-actions>
            </v-card>
         </v-col>
      </v-row>
   </v-form>
   <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
   <v-dialog v-model="dialog" persistent>
      <DialogModal :dialog="dialogData" />
   </v-dialog>
</template>

<script>
import DialogModal from "@/components/common/dialogModal";
import Completer from "@/components/job/completer";
import moment from "moment";
import bus from "vue3-eventbus";

import { firestore, storage, updateOffSet, addRepro } from "@/firebase";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";

export default {
   props: ["jobObject"],
   data: () => ({
      working: false,
      dialog: false,
      dialogData: [{
         title: "",
         icon: "",
         text: ""
      }],
      requiredRule: [(v) => !!v || "This is required"],
      reproItems: [{ label: "Job Version", state: false }, { label: "Customer IC", state: false }, { label: "Cutter Number", state: false },
      { label: "Design & Text", state: false }, { label: "Dimension", state: false }, { label: "Half-cut Line", state: false },
      { label: "Barcode", state: false }, { label: "Expiry Dates", state: false }, { label: "Form & Check for Continuation", state: false },
      { label: "Colours as per Ticket", state: false }, { label: "Cutting Line", state: false }, { label: "Unprinted Area", state: false },
      { label: "Overprints", state: false }, { label: "Trapping", state: false }, { label: "Bleed", state: false },
      { label: "Colour Code", state: false }, { label: "FG Number", state: false }, { label: "Lock System", state: false }],
      repMins: 0,
   }),
   components: {
      DialogModal,
      Completer
   },
   created() {
      this.working = true;
      this.setJobDetails();
   },
   methods: {
      async setJobDetails() {
         this.working = false;
      },
      async getName(ID, type) {
         if (ID == null) {
            return " - ";
         } else {

            const docRef = doc(firestore, type, ID);
            try {
               const docSnap = await getDoc(docRef);
               if (docSnap.exists()) {
                  return docSnap.data().name;
               } else {
                  return " - ";
               }
            } catch (error) {
               this.dialogData.title = "error";
               this.dialogData.icon = "mdi-close-circle-outline";
               this.dialogData.text = error;
               this.dialog = true;
            }
         }


      },
      formatDateTime(timeStamp, format) {
         if (timeStamp != null) {
            var m = moment(timeStamp.toDate());
            var mFormatted = m.format(format);
            return mFormatted;
         } else {
            return " - ";
         }
      },
      decrement() {
         this.repMins--
      },
      increment() {
         this.repMins++
      },
      async validate() {
         const { valid } = await this.$refs.form.validate();
         if (valid) this.addStageFun();
      },
      async addStageFun() {
         this.working = true;
         bus.emit("loadingState", true);
         this.dialogData.title = "Updating";
         this.dialogData.text = "Sending job for Out Source List";
         this.dialogData.icon = "mdi-note-text";
         this.dialog = true;

         await addRepro({
            saleOrder: this.jobObject.saleOrder,
            mins: this.repMins,
         }).then((result) => {
            bus.emit("loadingState", false);
            this.working = false;
            this.dialogData.title = result.data.response;
            this.dialogData.icon = result.data.icon;
            this.dialogData.text = result.data.message;
            this.dialogData.redirect = "/jobs/osl";
            this.dialog = true;
         });
      }
   }
}
</script>

<style></style>