<template>
  <NoInternet v-if="connection" />
  <div v-if="noSuchDoc">
    <NoContent componentColor="artwork" />
  </div>
  <div v-else>
    <!-- First Card -->
    <ItemForm v-if="fullyLoaded" :itemObject="item[0]" />
  </div>
  <!-- ///////////// Stages ///////////////////// -->
  <v-row justify="center" no-gutters>
    <v-col cols="12" v-for="stage in stages" :key="stage.time">
      <JobCard v-if="stage.stage == 'job'" :stageObject="stage" />
      <ArtworkCard
        v-if="stage.stage == 'artwork'"
        :itemObject="item[0]"
        :stageObject="stage"
      />
    </v-col>
  </v-row>
  <!-- Last Card -->
  <div v-if="['Administrator', 'Manager', 'Designer'].includes(userRole) && fullyLoaded">
    <ArtworkForm :itemObject="item[0]" />
  </div>
</template>

<script>
import moment from "moment";
import bus from "vue3-eventbus";
import { firestore } from "@/firebase";
import { collection, query } from "firebase/firestore";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";

import NoContent from "@/components/common/noJobs";
import Spinner from "@/components/common/spinner";
import NoInternet from "@/components/common/noInternet";
import DialogModal from "@/components/common/dialogModal";
import ItemForm from "@/components/artwork/itemForm";
import JobCard from "@/components/offset_stages/jobCard";
import ArtworkForm from "@/components/artwork/artworkForm";
import ArtworkCard from "@/components/artwork/artworkCard";

export default {
  data() {
    return {
      userRole: localStorage.userRole,
      connection: false,
      noSuchDoc: false,
      working: true,
      dialog: false,
      dialogData: {
        title: "",
        icon: "",
        text: "",
      },
      item: [],
      stages: [],
      fullyLoaded: false,
      cache: {},
    };
  },
  components: {
    NoContent,
    NoInternet,
    ItemForm,
    JobCard,
    ArtworkCard,
    ArtworkForm,
  },
  created() {
    this.working = true;
    bus.emit("loadingState", true);
    this.getItemDetails();
    bus.on("reloadComponents", (reloadType) => {
      this[reloadType]();
    });
  },
  methods: {
    reloadCards() {
      this.connection = false;
      this.fullyLoaded = false;
      this.noSuchDoc = false;
      this.working = true;
      bus.emit("loadingState", true);
      this.getItemDetails();
    },
    async getItemDetails() {
      const itemRef = doc(
        firestore,
        "artworks",
        this.$route.params.jobID,
        "stages",
        this.$route.params.itemID
      );
      const docSnap = await getDoc(itemRef);

      if (docSnap.exists()) {
        this.item = [];
        const docData = docSnap.data();
        this.item.push({
          jobID: this.$route.params.jobID,
          SO: docData.SO,
          description: docData.description,
          docID: docData.docID,
          itemCode: docData.itemCode,
          merged: docData.merged,
          structualID: docData.structualID,
          modified: this.formatDateTime(docData.modified),
          timestamp: this.formatDateTime(docData.timestamp),
          user: await this.getName(docData.userID, "users"),
        });
      } else {
        this.noSuchDoc = true;
      }

      this.stages = [];
      this.getStages();
    },
    async getStages() {
      let stagesRef = collection(
        firestore,
        "artworks",
        this.$route.params.jobID,
        "stages",
        this.$route.params.itemID,
        "charges"
      );
      let q;

      q = await query(stagesRef, orderBy("timestamp"));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.metadata.fromCache) {
        this.connection = true;
      } else {
        for (let i = 0; i < querySnapshot.docs.length; i++) {
          var data;
          if (querySnapshot.docs[i].data().stage == "artwork") {
            data = {
              stage: querySnapshot.docs[i].data().stage,
              jobID: querySnapshot.docs[i].data().jobID,
              docID: querySnapshot.docs[i].data().docID,
              task: querySnapshot.docs[i].data().task,
              note: querySnapshot.docs[i].data().note,
              mins: querySnapshot.docs[i].data().mins,
              time: this.formatDateTime(
                querySnapshot.docs[i].data().timestamp,
                "DD MMM YYYY - hh:mm A"
              ),
              user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
            };
          } else if (querySnapshot.docs[i].data().stage == "job") {
            data = {
              stage: querySnapshot.docs[i].data().stage,
              text: querySnapshot.docs[i].data().text,
              time: this.formatDateTime(
                querySnapshot.docs[i].data().timestamp,
                "DD MMM YYYY - hh:mm A"
              ),
              user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
            };
          } else {
            //
          }
          this.stages.push(data);
        }
      }

      this.fullyLoaded = true;
      this.working = false;
      bus.emit("loadingState", false);
    },
    async getName(document, collection) {
      if (!document) return " - ";

      const cacheKey = `${collection}${document}`;
      if (this.cache[cacheKey]) return this.cache[cacheKey];

      const docRef = doc(firestore, collection, document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) return " - ";

        const name = docSnap.data().name;
        this.cache[cacheKey] = name;
        return name;
      } catch (error) {
        this.showDialog({
          title: "Error",
          icon: "mdi-close-circle-outline",
          text: error.message,
          redirect: "reloadCards",
        });
      }
    },
    formatDateTime(timeStamp) {
      return timeStamp ? moment(timeStamp.toDate()).format("DD MMM YYYY - HH:MM") : " - ";
    },
    showDialog(data) {
      this.dialogData = data;
      this.dialog = true;
    },
  },
};
</script>
