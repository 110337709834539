<template>
  <v-row justify="end" class="ma-1">
    <v-col cols="auto">
      <v-btn large color="primary" prepend-icon="mdi-plus" to="/users/create">
        Create User
      </v-btn>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col cols="11">
      <v-data-table
        hover
        :headers="headers"
        :items="users"
        v-model:items-per-page="itemsPerPage"
        :items-per-page-options="[{value: 10, title: '10'},{value: 25, title: '25'}]"
        @update:page="setConditions"
        item-value="id"
        class="elevation-1"
        @click:row="openUser"
      >
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import router from "@/router";
import bus from "vue3-eventbus";
import moment from "moment";
import { firestore } from "@/firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
} from "firebase/firestore";

export default {
  data: () => ({
    working: false,
    users: [],
    headers: [
      {
        align: "start",
        key: "name",
        sortable: false,
        title: "Name",
      },
      { title: "Email", key: "email" },
      { title: "Created", key: "created" },
      { title: "User Role", key: "role" },
      { title: "Disabled", key: "disabled" },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    lastDoc: null,
    noMore: false,
  }),
  created() {
    this.working = true;
    bus.emit("loadingState", true);

    this.setConditions();
  },
  methods: {
    async setConditions() {
      const usersRef = collection(firestore, "users");
      let q;

      if (this.noMore == false && this.lastDoc == null) {
        q = await query(usersRef, orderBy("name"), limit(50));
        this.getUsers(q);
      } else if (this.noMore == false && this.lastDoc != null) {
        q = query(
          usersRef,
          orderBy("name"),
          startAfter(this.lastDoc),
          limit(50)
        );
        this.getUsers(q);
      } else {
        console.log("No more");
      }
    },
    async getUsers(q) {
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0) {
        this.noMore = true;
        return;
      } else {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((doc) => {
          const data = {
            name: doc.data().name,
            email: doc.data().email,
            created: this.formatDateTime(
              doc.data().created.toDate(),
              "DD-MMM-YYYY HH:MM"
            ),
            role: doc.data().role.toUpperCase(),
            disabled: doc.data().disabled.toString(),
            id: doc.data().uid,
          };
          this.users.push(data);
        });
      }

      this.working = false;
      bus.emit("loadingState", false);
    },
    formatDateTime(timeStamp, format) {
      var m = moment(timeStamp);
      var mFormatted = m.format(format);
      return mFormatted;
    },
    openUser(item, row){
      router.push(`/users/edit/${row.item.id}`);
    }
  },
};
</script>

<style scoped>
.table {
  background-color: rgb(var(--v-theme-navBar));
}
</style>
