<template>
    <v-data-table :headers="headers" :items="logs" v-model:page="page" :loading="working" item-key="docID"
        class="elevation-1" @page-count="pageCount = $event">
        <template v-slot:[`item`]="{ item }">
            <tr :class="getRowClass(item)">
                <td>{{ item.timestamp }}</td>
                <td><v-icon :icon="item.icon"></v-icon></td>
                <td>{{ item.user }}</td>
                <td>{{ item.before }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.total }}</td>
                <td>{{ item.message }}</td>
                <td>{{ item.note }}</td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
import bus from "vue3-eventbus";
import { firestore } from "@/firebase";
import { collection, query, doc, getDocs, getDoc, updateDoc, orderBy, limit, startAfter } from "firebase/firestore";
import moment from "moment";

export default {
    data: () => ({
        working: true,
        docRef: null,
        lastDoc: null,
        noMore: false,
        logs: [],
        totalItems: 10,
        page: 1,
        pageCount: 0,
        headers: [
            {
                title: "Time",
                align: "start",
                key: "timestamp",
                width: '18%'
            },
            { title: "Level", key: "icon", width: '1%' },
            { title: "By", key: "user" },
            { title: "Before", key: "before" },
            { title: "Ajusted", key: "quantity" },
            { title: "After", key: "total" },
            { title: "Event message", key: "message" },
            { title: "SO", key: "note" },
        ],
        users: []
    }),
    watch: {
        page: {
            handler() {
                if (this.logs.length != 0) {
                    if (this.page >= this.pageCount - 3 && this.noMore == false) {
                        this.getLogsSetup();
                    }
                }
            },
        },
    },
    created() {
        bus.emit("loadingState", true);
        this.getLogsSetup();
    },
    methods: {
        async getLogsSetup() {
            const logsRef = collection(firestore, "inventory", this.$route.params.location, "materials", this.$route.params.material, "logs");
            let q;

            if (this.lastDoc == null) {
                q = await query(logsRef, orderBy("timestamp", "desc"), limit(50));
                this.getLogs(q);
            } else if (this.noMore == false && this.lastDoc != null) {
                q = query(
                    logsRef,
                    orderBy("timestamp", "desc"),
                    startAfter(this.lastDoc),
                    limit(50)
                );
                this.getLogs(q);
            } else {
                console.log("No more");
            }
        },
        async getLogs(q) {
            const querySnapshot = await getDocs(q);

            if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
                this.noMore = true;
            } else {
                this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const data = {
                        before: querySnapshot.docs[i].data().before,
                        quantity: querySnapshot.docs[i].data().quantity,
                        total: querySnapshot.docs[i].data().total,
                        user: await this.getFromArray("users", querySnapshot.docs[i].data().userID),
                        icon: querySnapshot.docs[i].data().icon,
                        message: querySnapshot.docs[i].data().message,
                        note: querySnapshot.docs[i].data().note,
                        timestamp: this.formatDateWithTime(querySnapshot.docs[i].data().timestamp.toDate()),
                    };
                    this.logs.push(data);
                }
            }

            this.working = false;
            bus.emit("loadingState", false);
        },
        formatDateWithTime(timeStamp) {
            var m = moment(timeStamp);
            var mFormatted = m.format("DD-MM-YYYY - HH:MM:SS a");
            return mFormatted;
        },
        getFromArray(type, ID) {
            let foundObject = this[type].find(item => item.id === ID);
            if (foundObject) {
                return foundObject.name
            } else {
                return this.getName(ID, type);
            }
        },
        async getName(ID, type) {
            if (ID == null) {
                return " - ";
            } else {
                const docRef = doc(firestore, type, ID);

                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.users.push({
                        name: docSnap.data().name,
                        id: docSnap.data().uid,
                    })
                    return docSnap.data().name;
                } else {
                    return " - ";
                }
            }
        },
        getRowClass(item) {
            if (item.message.includes("Used")) {
                return "";
            } else if (item.message.includes("Wasted")) {
                return "red-row";
            }
            return "";
        },
    }
}
</script>

<style>
.green-row {
    background-color: green;
    color: white;
}
.red-row {
    background-color: rgb(var(--v-theme-secondaryError));
    color: white;
}
</style>
