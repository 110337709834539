<template>
  <v-row justify="start">
    <v-col cols="12" md="12">
      <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details
        single-line></v-text-field>
      <v-data-table v-model:search="search" :headers="headers" :items="invoices" v-model:page="page" :loading="loading"
        item-key="docID" item-value="docID" class="elevation-1" @page-count="pageCount = $event"
        :items-per-page-options="[10, 20, 25, 50]" @click:row="onRowClick" @update:page="loadMore"
        :mobile="this.$vuetify.display.mobile">
        <template v-slot:[`item.verifiedByName`]="{ item }">
          <span :class="getverifiedByNameClass(item)">{{ item.verifiedByName }}</span>
        </template>
        <template v-slot:[`item.ziv`]="{ item }">
          <v-icon :icon="getZIVIcon(item.ziv)" :color="item.ziv == 'success' || 'cleared' ? 'success' : 'error'"
            size="large"></v-icon>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" md="4">
      <v-dialog v-model="slideUp" transition="dialog-bottom-transition" fullscreen>
        <v-card color="cards">
          <v-toolbar color="navBar">
            <v-btn icon="mdi-close" @click="slideUp = false"></v-btn>

            <v-toolbar-title>{{ slideUpTitle }}</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-row no-gutters>
            <v-col cols="12" md="12">
              <ComPDFViewer :pdfUrl="selectedPdfUrl" />
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" @close="dialog = false" />
  </v-dialog>
</template>
n
<script>
import bus from "vue3-eventbus";
import moment from "moment";
import DialogModal from "@/components/common/dialogModal";
import ComPDFViewer from '@/components/common/pdf/comPDFViewer';
import { firestore, storage } from "@/firebase";
import { query, where, orderBy, startAfter, limit } from "firebase/firestore";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";

export default {
  data() {
    return {
      search: "",
      invoices: [],
      headers: [
        { title: "Created By", value: "createdBy" },
        { title: "Verified By", value: "verifiedByName" },
        { title: "Approved By", value: "approvedBy" },
        { title: "Approved", value: "approved" },
        { title: "Invoice Date", value: "invoiceDate" },
        { title: "Invoice No", value: "invoiceID" },
        { title: "Purchase Order", value: "purchaseOrder" },
        { title: "GRN", value: "grn" },
        { title: "Qty", value: "totalQuantity" },
        { title: "Total", value: "totalAmount" },
        { title: "Type", value: "type" },
        { title: "ZIV", value: "ziv" },
      ],
      page: 1,
      pageCount: 0,
      loading: false,
      imageLoading: false,
      imageUrl: null,
      showLightbox: false,
      lastDoc: null,
      noMore: false,
      cache: {},
      dialog: false,
      dialogData: {},
      slideUpDocID: null,
      slideUp: false,
      slideUpTitle: "",
    };
  },
  components: {
    DialogModal,
    ComPDFViewer,
  },
  async mounted() {
    await this.fetchInvoices();
  },
  methods: {
    async fetchInvoices() {
      if (this.noMore) return;

      this.loading = true;
      bus.emit("loadingState", true);

      let q = query(
        collection(firestore, "purchaseInvoices"),
        where("state", "in", ["approved", "skipped"]),
        orderBy("created", "desc"),
        limit(300)
      );

      if (this.lastDoc) {
        q = query(q, startAfter(this.lastDoc));
      }

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        this.noMore = true;
      } else {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const newInvoices = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const createdByName = await this.getName(data.createdBy, "users");
            const approvedByName = await this.getName(data.approvedBy, "users");
            const verifiedByName = await this.getName(data.verifiedBy, "users");

            const formattedTotalAmount = new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(data.totalAmount);

            return {
              docID: doc.id,
              ...data,
              created: this.formatDateWithTime(data.created.toDate()),
              createdBy: createdByName,
              approved: this.formatDateWithTime(data.approved.toDate()),
              approvedBy: approvedByName,
              verifiedByName: verifiedByName,
              totalAmount: formattedTotalAmount,
            };
          })
        );

        this.invoices = [...this.invoices, ...newInvoices];
      }

      this.loading = false;
      bus.emit("loadingState", false);
    },
    async onRowClick(_, row) {
      this.slideUpTitle = "Purchase Order " + row.item.purchaseOrder;
      this.slideUpDocID = row.item.docId;
      this.selectedPdfUrl = row.item.PDF;
      this.selectedInvoices = [row.item.docId];
      this.slideUp = true;
    },
    async getName(document, collection) {
      if (!document) return " - ";

      if (document == "AI System") return document;

      if (this.cache[document]) return this.cache[document];

      const docRef = doc(firestore, collection, document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) return " - ";

        this.cache[document] = docSnap.data().name;
        return this.cache[document];
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      }
    },
    getZIVIcon(status) {
      if (status == "success") {
        return "mdi-database";
      } else if (status == "cleared") {
        return "mdi-database-check";
      } else {
        return "mdi-alert-circle";
      }
    },
    getverifiedByNameClass(value) {
      if (value.note) {
        return "text-red";
      } else if (value.verifiedByName == "AI System") {
        return "text-green";
      } else {
        return "text-amber";
      }
    },
    showDialog(title, icon, message, action) {
      this.sending = false;
      this.dialog = false;
      this.dialogData = {
        title: title,
        icon: icon,
        text: message,
        redirect: action,
      };
      this.dialog = true;
    },
    formatDateWithTime(timeStamp) {
      return moment(timeStamp).format("DD-MM-YYYY - hh:mm:ss a");
    },
    loadMore() {
      if (this.page >= this.pageCount - 1) {
        this.fetchInvoices();
      }
    },
  },
};
</script>

<style scoped>
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.highlighted-row {
  background-color: #e0f7fa;
  /* You can choose any color */
}
</style>
