<template>
    <v-row justify="center">
        <v-col>
            <div class="py-12 text-center">
                <v-icon v-if="icon == undefined" class="mb-6" :color="this.componentColor" icon="mdi-inbox" size="128"></v-icon>
                <v-icon v-else class="mb-6" :color="this.componentColor" :icon="this.icon" size="128"></v-icon>
                <div v-if="text == undefined" class="text-h4 font-weight-bold">No Jobs</div>
                <div v-else class="text-h4 font-weight-bold">{{ text }}</div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ["componentColor", "icon", "text"],

}
</script>

<style></style>