<template>
    <v-row justify="center" v-if="lottieData">
        <v-col cols="auto">
            <DotLottieVue :key="lottieKey" :style="{ height: height + 'px', width: width + 'px' }" autoplay :loop="lottieFile != 'tick'"
                :data="lottieData" />
        </v-col>
    </v-row>
    <div v-else>
        Loading...
    </div>
</template>

<script>
import { DotLottieVue } from '@lottiefiles/dotlottie-vue';

export default {
    props: {
        height: {
            type: Number,
            required: true
        },
        width: {
            type: Number,
            required: true
        },
        lottieFile: {
            type: String,
            required: true,
            default: 'blank'  // Ensure you have a default Lottie file for safety
        },
    },
    data() {
        return {
            lottieData: null,
            lottieKey: 0,
        };
    },
    components: {
        DotLottieVue,
    },
    watch: {
        lottieFile: {
            immediate: true,  // Load on mount
            handler(newFile) {
                this.loadLottieFile(newFile);
            }
        }
    },
    methods: {
        async loadLottieFile(fileName) {
            console.log(fileName);
            if (!fileName) {
                console.error('Lottie file name is undefined.');
                return;
            }

            try {
                const fileUrl = `/lottie/${fileName}.lottie`; // Dynamically build the URL based on the file name
                const response = await fetch(fileUrl);
                if (!response.ok) {
                    throw new Error(`Network response was not ok for ${fileUrl}`);
                }
                this.lottieData = await response.arrayBuffer(); // Fetch as ArrayBuffer

                // Trigger re-render by changing the key
                this.lottieKey += 1;
            } catch (error) {
                console.error('Error loading the .lottie file:', error);
                this.lottieData = null;
            }
        }
    }
};
</script>

<style></style>
