<template>
  <v-row justify="center" class="mt-6">
    <v-col cols="11" xs="11" sm="11" md="8" lg="6" xl="4">
      <v-card color="cards">
        <v-card-text
          ><v-form ref="form" class="ma-4" :disabled="working"
            ><v-text-field
              v-model="name"
              :rules="nameRules"
              label="Name"
              required
            ></v-text-field
            ><v-text-field
              v-model="email"
              :rules="emailRules"
              label="Email"
              required
              :disabled="editState"
            ></v-text-field
            ><v-text-field
              v-model="password"
              :rules="passwordRules"
              label="Password"
              required
              :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible ? 'text' : 'password'"
              @click:append-inner="visible = !visible"
              v-if="!editState"
            ></v-text-field>
            <v-select
              v-model="role"
              :items="roles"
              :rules="[(v) => !!v || 'Please select a Role']"
              label="Role"
              required
            ></v-select>
            <v-row justify="center" class="mb-16" v-if="editState">
              <v-col cols="auto">
                <v-sheet :color="switchColor" class="rounded-xl"
                  ><v-switch
                    class="mx-5"
                    :label="switchText"
                    hide-details
                    inset
                    v-model="disabled"
                  ></v-switch
                ></v-sheet>
              </v-col>
            </v-row>
            <v-combobox
              v-if="role == 'Agent'"
              clearable
              chips
              multiple
              label="Viewable agents"
              v-model="selectedAgents"
              :items="agents"
              item-value="id"
              item-title="name"
              variant="outlined"
            ></v-combobox>
            <v-autocomplete
              v-if="['CTP_Operator', 'Coordinator'].includes(role)"
              v-model="selectedLocations"
              :items="locations"
              item-value="id"
              item-title="name"
              label="Select Locations"
              multiple
              chips
              clearable
            ></v-autocomplete>
            <v-btn
              color="primary"
              class="mt-4"
              block
              size="large"
              @click="validate"
              :loading="working"
              v-if="!editState"
            >
              Create User
            </v-btn>
            <v-btn
              color="primary"
              class="mt-4"
              block
              size="large"
              @click="validate"
              :loading="working"
              v-if="editState"
            >
              Update
            </v-btn></v-form
          ></v-card-text
        >
      </v-card>
    </v-col>
  </v-row>
  <v-row justify="center" class="mt-10">
    <v-col cols="11" xs="11" sm="11" md="8" lg="6" xl="4">
      <v-btn
        color="secondary"
        size="large"
        variant="tonal"
        @click="sendPasswordReset"
        :loading="working"
        block
        v-if="editState"
      >
        Send password reset link
      </v-btn>
    </v-col>
  </v-row>
  <v-dialog v-model="dialog" persistent>
    <v-card
      class="mx-auto"
      elevation="16"
      min-width="300"
      max-width="600"
      :title="dialogtitle.toUpperCase()"
      color="cards"
    >
      <v-divider class="mt-3"></v-divider>

      <div class="py-12 text-center">
        <v-icon class="mb-6" :color="dialogtitle" :icon="dialogIcon" size="128"></v-icon>

        <div class="text-h4 font-weight-bold mx-16">{{ dialogText }}</div>
      </div>

      <v-divider></v-divider>

      <div class="pa-4 text-end">
        <v-btn
          class="text-none"
          :color="dialogtitle"
          min-width="92"
          rounded
          variant="flat"
          @click="okayPage"
        >
          Okay
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import router from "@/router";
import { useRoute } from "vue-router";
import bus from "vue3-eventbus";
import { createUser, updateUser, passwordReset } from "@/firebase";
import { firestore } from "@/firebase";
import { doc, getDoc, getDocs, collection, query, orderBy } from "firebase/firestore";

export default {
  data: () => ({
    editState: false,
    name: "",
    email: "",
    password: "",
    visible: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Name must be more than 2 characters",
    ],
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => (v && v.length >= 4) || "Email must be more than 4 characters",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Email must be more than 7 characters",
    ],
    role: "",
    roles: [
      "Administrator",
      "Manager",
      "Moderator",
      "Designer",
      "CTP_Operator",
      "Coordinator",
      "Agent",
      "Customer",
      "Accountant",
    ],
    agents: [],
    selectedAgents: [],
    disabled: false,
    created: "",
    uid: "",
    working: true,
    dialog: false,
    dialogtitle: "",
    dialogIcon: "",
    dialogText: "",
    locations: [],
    selectedLocations: [],
  }),
  beforeRouteLeave(to, from, next) {
    if (this.working) {
      const answer = window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
      // cancel the navigation and stay on the same page
      if (!answer) return next(false);
      //
      else next();
    } else {
      next();
    }
  },
  mounted() {
    if (this.$route.name == "Edit User") {
      //this.$route.params.userid
      this.editState = true;
      this.getUser();
    } else {
      bus.emit("loadingState", false);
      this.working = false;
      this.editState = false;
    }
    this.getLocations();
  },
  computed: {
    switchText() {
      if (this.disabled) {
        return "User account disabled";
      } else {
        return "User account Active";
      }
    },
    switchColor() {
      if (this.disabled) {
        return "error";
      } else {
        return "success";
      }
    },
  },
  methods: {
    async getLocations() {
      const q = query(collection(firestore, "locations"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      this.locations = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          name: doc.data().name,
        };
      });
    },
    async validate() {
      const { valid } = await this.$refs.form.validate();

      if (valid && !this.editState) this.createUserFun();
      else if (valid && this.editState) this.updateUserFun();
    },
    async createUserFun() {
      bus.emit("loadingState", true);
      this.working = true;

      try {
        await createUser({
          name: this.name,
          email: this.email,
          role: this.role,
          password: this.password,
          agents: this.selectedAgents.map((agent) => agent.id),
          locations: this.selectedLocations,
        }).then((result) => {
          bus.emit("loadingState", false);
          this.working = false;
          this.dialogtitle = result.data.response;
          this.dialogIcon = result.data.icon;
          this.dialogText = result.data.message;
          this.dialog = true;
        });
      } catch (error) {
        console.error(error);
        this.dialogtitle = "error";
        this.dialogIcon = "mdi-close-circle-outline";
        this.dialogText = error;
        this.dialog = true;
      }
    },
    okayPage() {
      this.$router.push("/users");
    },
    async getUser() {
      try {
        const route = useRoute();
        const docRef = doc(firestore, "users", route.params.userid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          this.name = docSnap.data().name;
          this.email = docSnap.data().email;
          this.role = docSnap.data().role;
          this.disabled = docSnap.data().disabled;
          this.uid = docSnap.data().uid;
          this.selectedAgents = docSnap.data().agents || [];
          this.selectedLocations = docSnap.data().locations || [];

          // Fetch and set selected locations for CTP_Operator
          if (this.role === "CTP_Operator") {
            await this.getLocations();
            const userLocations = docSnap.data().locations || [];
            this.selectedLocations = this.locations.filter((location) =>
              userLocations.includes(location.id)
            );
          }
        } else {
          console.log("No such document!");
          this.dialogtitle = "error";
          this.dialogIcon = "mdi-close-circle-outline";
          this.dialogText = "Error getting user details";
          this.dialog = true;
        }

        bus.emit("loadingState", false);
        this.working = false;
      } catch {
        this.dialogtitle = "error";
        this.dialogIcon = "mdi-close-circle-outline";
        this.dialogText = "Error getting user details";
        this.dialog = true;
      }
    },
    async getAgents() {
      const q = query(collection(firestore, "agents"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
        return;
      } else {
        querySnapshot.forEach((doc) => {
          const data = {
            name: doc.data().name,
            id: doc.data().id,
          };
          this.agents.push(data);
        });
        return;
      }
    },
    async updateUserFun() {
      bus.emit("loadingState", true);
      this.working = true;

      try {
        await updateUser({
          name: this.name,
          role: this.role,
          disabled: this.disabled,
          uid: this.uid,
          agents: this.selectedAgents.map((agent) => agent.id),
          locations: this.selectedLocations,
        }).then((result) => {
          bus.emit("loadingState", false);
          this.working = false;
          this.dialogtitle = result.data.response;
          this.dialogIcon = result.data.icon;
          this.dialogText = result.data.message;
          this.dialog = true;
        });
      } catch (error) {
        console.error(error);
        this.dialogtitle = "error";
        this.dialogIcon = "mdi-close-circle-outline";
        this.dialogText = error;
        this.dialog = true;
      }
    },
    async sendPasswordReset() {
      bus.emit("loadingState", true);
      this.working = true;

      try {
        await passwordReset({
          email: this.email,
        }).then((result) => {
          bus.emit("loadingState", false);
          this.working = false;
          this.dialogtitle = result.data.response;
          this.dialogIcon = result.data.icon;
          this.dialogText = result.data.message;
          this.dialog = true;
        });
      } catch (error) {
        console.error(error);
        this.dialogtitle = "error";
        this.dialogIcon = "mdi-close-circle-outline";
        this.dialogText = error;
        this.dialog = true;
      }
    },
  },
};
</script>

<style></style>
