<template>
  <v-form ref="formJobSO" :disabled="!isEditing">
    <v-card color="cards" :loading="working">
      <v-toolbar flat color="navBar">
        <JobTag :jobType="jobObject.type" />
        <v-spacer></v-spacer>
        <v-btn icon v-if="
          (jobStates.includes(jobObject.state) && userRole == 'Designer') ||
          userRoles.includes(userRole)
        " @click="isEditing = !isEditing">
          <v-fade-transition leave-absolute>
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-fade-transition>
        </v-btn>
      </v-toolbar>

      <v-card-item :title="'Sales Order ' + jobObject.saleOrder"> </v-card-item>
      <v-divider></v-divider>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" lg="6" xl="6">
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="12" md="4">
                <v-select label="Select Job type" colors="primary" v-model="type" bg-color="navBar" variant="outlined"
                  :items="jobObject.state == 'artwork'
                      ? ['New Job']
                      : ['New Job', 'Repeat Job', 'Plate Only']
                    " :rules="requiredRule" class="mr-1"></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete label="Select Factory/Plant" colors="primary" v-model="factory" bg-color="navBar"
                  variant="outlined" :items="factories" :loading="working" item-title="name" :rules="requiredRule"
                  item-value="id" class="mr-1"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete label="Select CtP Location" colors="primary" v-model="location" bg-color="navBar"
                  variant="outlined" :items="locations" :loading="working" item-title="name" :rules="requiredRule"
                  item-value="id"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="5">
                <v-autocomplete label="Select Agent" colors="primary" v-model="agent" bg-color="navBar"
                  variant="outlined" :items="agents" :loading="working" item-title="name" :rules="requiredRule"
                  item-value="id"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="7">
                <v-combobox class="ml-1" multiple label="Structual ID" v-model="structualID" variant="outlined"
                  :rules="structualIDRules">
                  <template v-slot:selection="data">
                    <v-chip :selected="data.selected" class="chip-class" size="large" density="compact">
                      {{ data.item.title }}
                    </v-chip>
                  </template></v-combobox>
              </v-col>
            </v-row>
            <v-autocomplete label="Select Customer" colors="primary" v-model="customer" bg-color="navBar"
              variant="outlined" :items="customers" :loading="customersLoading" item-title="name" item-value="id"
              @update:search="getCustomers" @update:modelValue="updateCustomerCode"
              :rules="agent == 'T7LVhEWAHvX2X6DMSUcy' ? [] : requiredRule"
              no-data-text="start typing to search"></v-autocomplete>
            <v-row justify="space-between">
              <v-col cols="12" lg="5">
                <div class="text-body-1">{{ customerCode }}</div>
                <div class="text-subtitle-2">{{ customerOrganization }}</div>
              </v-col>
              <v-col cols="12" lg="auto">
                <div class="text-right">
                  Created by
                  <p class="text-subtitle-2">
                    {{ jobObject.createdBy }} @ {{ jobObject.created }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6">
            <v-combobox multiple variant="outlined" label="Purchase Order" v-model="purchaseOrder"
              :rules="purchaseOrderRules" v-on:keypress="NumbersOnly"><template v-slot:selection="data">
                <v-chip :selected="data.selected" class="chip-class" size="large" density="compact">
                  {{ data.item.title }}
                </v-chip>
              </template></v-combobox>
            <v-combobox v-if="jobObject.state != 'artwork'" multiple variant="outlined" label="Job ID" v-model="jobID"
              :rules="jobIDRules" v-on:keypress="NumbersOnly">
              <template v-slot:selection="data">
                <v-chip :selected="data.selected" class="chip-class" size="large" density="compact">
                  {{ data.item.title }}
                </v-chip>
              </template>
            </v-combobox>
            <v-row justify="space-between" align="center">
              <v-col cols="auto">
                <div>
                  <v-chip v-for="(item, index) in jobItems" :key="index" :disabled="!isEditing" color="info"
                    variant="flat" @click="handleItemClick(item.docID)">
                    {{ item.jobID }} - item {{ item.itemCode }}
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="auto">
                <v-btn icon="mdi-link-variant" variant="outlined" color="primary" size="small" :disabled="!isEditing">
                </v-btn>
              </v-col>
            </v-row>
            <v-textarea class="mt-4" label="Description" rows="2" counter="260" v-model="description"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn v-if="userRole == 'Administrator' || userRole == 'Manager'" :disabled="!isEditing" @click="deleteJob">
          Send to trash
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="working" to="/pdf">
          View Artwork
        </v-btn>

        <v-btn v-if="
          (jobStates.includes(jobObject.state) && userRole == 'Designer') ||
          userRoles.includes(userRole)
        " :loading="working" :disabled="!isEditing" @click="validateJob(job)">
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";

import DialogModal from "@/components/common/dialogModal";
import JobTag from "@/components/job/jobTag";

import { firestore, updateOffSet, deleteOffSet } from "@/firebase";
import { collection, query, startAt } from "firebase/firestore";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";

export default {
  props: {
    jobObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    jobStates: ["artwork", "repro", "osl", "vps", "ctp", "plateout"],
    userRoles: ["Administrator", "Manager", "Moderator"],
    urlPath: "",
    working: true,
    isEditing: false,
    userRole: "",
    factories: [],
    locations: [],
    customers: [],
    agents: [],
    type: "",
    factory: "",
    location: "",
    customersLoading: false,
    customer: "",
    agent: "",
    customerCode: "",
    customerOrganization: "",
    jobID: [],
    jobItems: [],
    purchaseOrder: [],
    structualID: [],
    description: "",
    dialog: false,
    dialogData: [
      {
        title: "",
        icon: "",
        text: "",
      },
    ],
    requiredRule: [(v) => !!v || "This is required"],
    structualIDRules: [
      (value) =>
        value.every((v) => v.length === 0 || v.length >= 4) ||
        "Must be more than 4 characters long.",
    ],
    itemsDialog: false,
  }),
  computed: {
    purchaseOrderRules() {
      if (
        this.jobObject.state == "vps" &&
        (this.purchaseOrder == null) | (this.purchaseOrder == [])
      ) {
        return [
          (value) =>
            value.every((v) => v.length === 8) ||
            "All PO numbers must be 8 characters long.",
        ];
      } else {
        return [];
      }
    },
    jobIDRules() {
      if (this.jobObject.state == "vps" && (this.jobID == null) | (this.jobID == [])) {
        return [
          (value) => !!value || "Required.",
          (value) =>
            value.every((v) => v.length === 5) ||
            "All job IDs must be 5 characters long.",
        ];
      } else if (this.jobObject.state == "vps" && this.jobID.length == 0) {
        return [() => "Job ID required"];
      } else {
        return [];
      }
    },
    agentRules() {
      if (this.jobObject.state == "vps" && (this.agent == null) | (this.agent == "")) {
        return [() => "Agent is needed"];
      } else {
        return [];
      }
    },
  },
  components: {
    DialogModal,
    JobTag,
  },
  async created() {
    this.urlPath = this.$route.path;
    this.working = true;
    this.userRole = localStorage.userRole;

    try {
      await this.getFactories();
      await this.getLocations();
      await this.getCustomerShortList();
      await this.getAgents();
      this.type = this.jobObject.type;
      this.factory = this.jobObject.factory;
      this.location = this.jobObject.location;
      this.customer = this.jobObject.customer;
      this.agent = this.jobObject.agent;
      this.jobID = this.jobObject.jobID;
      this.jobItems = await this.getItemCode(this.jobObject.items);
      this.purchaseOrder = this.jobObject.purchaseOrder;
      this.structualID = this.jobObject.structualID;
      this.description = this.jobObject.description;
      this.updateCustomerCode();
      this.working = false;
    } catch (error) {
      this.dialogData.title = "error";
      this.dialogData.icon = "mdi-close-circle-outline";
      this.dialogData.text = error;
      this.dialog = true;
    }
    console.log(this.jobItems);
  },
  methods: {
    async getFactories() {
      const q = query(collection(firestore, "factories"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = {
          name: doc.data().name,
          id: doc.data().id,
        };
        this.factories.push(data);
      });
      return;
    },
    async getLocations() {
      const q = query(collection(firestore, "locations"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = {
          name: doc.data().name,
          id: doc.data().id,
          code: doc.data().code,
          organization: doc.data().organization,
        };
        this.locations.push(data);
      });
      return;
    },
    async getCustomerShortList() {
      const q = query(
        collection(firestore, "customers"),
        orderBy("id"),
        startAt(this.jobObject.customer),
        limit(10)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = {
          name: doc.data().name,
          id: doc.data().id,
          code: doc.data().code,
          organization: doc.data().organization,
        };
        this.customers.push(data);
      });
      return;
    },
    async getCustomers(val) {
      if (val && val.length > 2) {
        bus.emit("loadingState", true);
        this.customersLoading = true;

        const q = query(
          collection(firestore, "customers"),
          orderBy("name"),
          startAt(val.toUpperCase()),
          limit(10)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
          this.customersLoading = false;
          bus.emit("loadingState", false);
        } else {
          this.customers = [];
          querySnapshot.forEach((doc) => {
            const data = {
              name: doc.data().name,
              id: doc.data().id,
              code: doc.data().code,
              organization: doc.data().organization,
            };
            this.customers.push(data);
          });

          this.customersLoading = false;
          bus.emit("loadingState", false);
        }
      }
    },
    async getAgents() {
      const q = query(collection(firestore, "agents"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = {
          name: doc.data().name,
          id: doc.data().id,
        };
        this.agents.push(data);
      });
      return;
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateCustomerCode() {
      let foundObject = this.customers.find((obj) => obj.id === this.customer);

      if (foundObject) {
        this.customerCode = "Customer Code: " + foundObject.code;
        this.customerOrganization = "Org: " + foundObject.organization;
      } else {
        this.customerCode = "";
        this.customerOrganization = "";
      }
    },
    async getItemCode(array) {
      const updatedArray = await Promise.all(
        array.map(async (item) => {
          if (item.itemCode === undefined) {
            const docRef = doc(firestore, "artworks", item.jobID, "stages", item.docID);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists) {
              item.itemCode = docSnap.data().itemCode;
            }
          }
          return item;
        })
      );

      return updatedArray;
    },
    handleItemClick(docID) {
      // Handle the click event, with docID as the value
      console.log("Clicked docID:", docID);
      // Add your logic here
    },
    async validateJob() {
      const { valid } = await this.$refs.formJobSO.validate();
      if (valid) this.updateJob();
    },
    async updateJob() {
      this.working = true;
      bus.emit("loadingState", true);
      this.dialogData.title = "Updating";
      this.dialogData.text = "Updating Job";
      this.dialogData.icon = "mdi-note-text";
      this.dialog = true;

      let sendData = {
        state: this.jobObject.state,
        type: this.type,
        jobID: this.jobID,
        saleOrder: this.jobObject.saleOrder,
        factory: this.factory,
        location: this.location,
        customer: this.customer,
        agent: this.agent,
        purchaseOrder: this.purchaseOrder,
        structualID: this.structualID,
        description: this.description,
      };

      await updateOffSet(sendData).then((result) => {
        bus.emit("loadingState", false);
        this.working = false;
        this.dialogData.title = result.data.response;
        this.dialogData.icon = result.data.icon;
        this.dialogData.text = result.data.message;
        this.dialogData.redirect = "reloadCards";
        this.dialog = true;
      });
    },
    async deleteJob() {
      this.working = true;
      bus.emit("loadingState", true);
      this.dialogData.title = "Deleting";
      this.dialogData.text = "Sending Job to Trash";
      this.dialogData.icon = "mdi-delete";
      this.dialog = true;

      await deleteOffSet({
        saleOrder: this.jobObject.saleOrder,
      }).then((result) => {
        bus.emit("loadingState", false);
        this.working = false;
        this.dialogData.title = result.data.response;
        this.dialogData.icon = result.data.icon;
        this.dialogData.text = result.data.message;
        this.dialogData.redirect = "/";
        this.dialog = true;
      });
    },
  },
};
</script>

<style></style>
