<template>
  <v-card class="mt-4" color="cards">
    <v-toolbar flat color="navBar" density="compact">
      <!-- Title on the left -->
      <v-toolbar-title>ARTWORK</v-toolbar-title>
      <!-- Text on the right -->
      <v-spacer></v-spacer>
      <span class="mr-4">Job ID {{ stageObject.jobID }} - {{ getItemCode }}</span>
    </v-toolbar>
    <v-row justify="space-between" align="center" class="ma-5" no-gutters>
      <v-col cols="12" md="7">
        <div
          class="text-center"
          :style="{ border: '1px solid #6f4ef2', borderRadius: '6px', padding: '10px' }"
        >
          <div class="text-h6 text-left">Task: {{ stageObject.task }}</div>
          <div class="text-subtitle-1 text-left">Note: {{ stageObject.note }}</div>
        </div>
      </v-col>
      <v-col cols="12" md="auto" :class="this.$vuetify.display.mobile ? 'mt-4' : ''">
        <div class="text-h6 font-weight-light me-1 text-end">
          Artwork charges
          <div class="text-h4">{{ stageObject.mins }} mins</div>
        </div>
      </v-col>
    </v-row>
    <UserTimeStrip :stageObject="stageObject" />
  </v-card>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";

export default {
  props: ["itemObject", "stageObject"],
  components: {
    UserTimeStrip,
  },
  computed: {
    getItemCode() {
      let object = this.itemObject || this.stageObject;
      return object ? object.itemCode.toString().padStart(2, "0") : "not found";
    },
  },
};
</script>

<style></style>
