<template>
  <v-card class="mt-4" color="cards">
    <v-toolbar flat density="compact" color="navBar" title="IMPOSITION / VPS" />
    <v-row justify="space-around" align="center" class="ma-2" no-gutters>
      <v-col cols="12" md="3">
        <v-row justify="center" no-gutters>
          <v-col cols="auto">
            <v-icon
              color="success"
              icon="mdi-checkbox-marked-circle-outline"
              size="64"
            ></v-icon>
          </v-col>
          <v-col cols="auto">
            <div class="text-h6 font-weight-bold text-start">
              IMPOSITION/VPS <br />
              PASSED
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <div
          class="text-center"
          :style="{ border: '1px solid #6f4ef2', borderRadius: '5px', padding: '5px' }"
        >
          <div class="text-h6 mx-1 text-left">Task: {{ stageObject.task }}</div>
          <div class="text-subtitle-1 mx-1 text-left">Note: {{ stageObject.note }}</div>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <v-row justify="space-around" no-gutters>
          <v-col cols="12" md="auto">
            <v-row justify="center" align="center">
              <GripperSVG class="mt-3 mx-2" :height="80"></GripperSVG>
              <div class="text-center">
                <div class="text-h6 font-weight-light me-1">Imposition Gripper</div>
                <div class="text-h4 font-weight-light me-1">
                  {{ stageObject.gripper }} mm
                </div>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" md="auto">
            <div class="text-center">
              <div class="text-h6 font-weight-light me-1">Artwork charges</div>
              <div class="text-h4 font-weight-light me-1">
                {{ stageObject.mins }} mins
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <UserTimeStrip :stageObject="stageObject" />
  </v-card>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";
import GripperSVG from "@/components/job/gripperSVG";

export default {
  props: ["stageObject"],
  components: {
    UserTimeStrip,
    GripperSVG,
  },
};
</script>

<style></style>
