<template>
  <v-card class="mt-4" color="cards">
    <v-toolbar flat color="navBar">
      <v-toolbar-title>PLATE SLIP</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-form :disabled="working" ref="form">
        <v-row justify="space-around" align="center" no-gutters>
          <v-col cols="5" md="auto" class="d-flex align-center">
            <v-checkbox
              v-model="plates.cyan"
              class="custom-checkbox my-3"
              color="cyan"
              base-color="cyan"
              label="Cyan"
              density="compact"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="5" md="auto" class="d-flex align-center">
            <v-checkbox
              v-model="plates.yellow"
              class="custom-checkbox my-3"
              color="yellow"
              base-color="yellow"
              label="Yellow"
              density="compact"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="5" md="auto" class="d-flex align-center">
            <v-checkbox
              v-model="plates.magenta"
              class="custom-checkbox my-3"
              color="purple"
              base-color="purple"
              label="Magenta"
              density="compact"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="5" md="auto" class="d-flex align-center">
            <v-checkbox
              v-model="plates.black"
              class="custom-checkbox my-3"
              color="#424242"
              base-color="#424242"
              label="Black"
              density="compact"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align="center"
          no-gutters
          v-for="(plate, index) in customPlates"
          :key="index"
          class="my-2"
        >
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="plate.name"
              label="Plate"
              dense
              hide-details
              variant="outlined"
              placeholder="Enter plate name"
              :rules="plateRules"
            >
              <template v-slot:append-inner>
                <v-btn
                  icon
                  variant="text"
                  @click="removeCustomPlate(index)"
                  class="ma-0 pa-0"
                >
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn color="primary" @click="addCustomPlate">Add More Plates</v-btn>
        </v-row>
        <v-row justify="center" class="mt-10">
          <v-col cols="11" md="6">
            <v-select
              v-model="reason"
              label="Reason"
              :items="['Plate Weak', 'Others']"
              :rules="reasonRules"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <div class="ml-2 text-h6 text-end">{{ totalPlates }} Total Plates</div>
      <v-spacer></v-spacer>
      <v-btn
        class="px-4"
        @click="validate"
        append-icon="mdi-arrow-collapse-right"
        variant="flat"
        rounded="6"
        :loading="working"
        color="success"
      >
        Send
      </v-btn>
    </v-card-actions>
  </v-card>
  <div class="mb-16"></div>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import { addPlateSlip } from "@/firebase";
import DialogModal from "@/components/common/dialogModal";

export default {
  props: ["jobObject"],
  data() {
    return {
      working: false,
      plates: {
        cyan: false,
        yellow: false,
        magenta: false,
        black: false,
      },
      customPlates: [],
      reason: "",
      plateRules: [
        (v) => !!v || "Plate is required",
        (v) => (v && v.length >= 4) || "Name must be 4 characters or more",
      ],
      reasonRules: [(v) => !!v || "Reason is required"],
      dialog: false,
      dialogData: [
        {
          title: "",
          icon: "",
          text: "",
        },
      ],
    };
  },
  components: {
    DialogModal,
  },
  computed: {
    totalPlates() {
      const basePlates = Object.values(this.plates).filter(Boolean).length;
      const customPlates = this.customPlates.filter((plate) => plate.name).length;
      return basePlates + customPlates;
    },
    selectedPlates() {
      const basePlates = Object.entries(this.plates)
        .filter(([key, value]) => value)
        .map(([key, value]) => key.charAt(0).toUpperCase() + key.slice(1)); // Capitalize the first letter to match the value prop

      const customPlates = this.customPlates.map((plate) => plate.name).filter(Boolean);

      return [...basePlates, ...customPlates];
    },
  },
  methods: {
    addCustomPlate() {
      this.customPlates.push({ name: "" });
    },
    removeCustomPlate(index) {
      this.customPlates.splice(index, 1);
    },
    async validate() {
      const { valid } = await this.$refs.form.validate();

      if (valid) this.sendRequest();
    },
    async sendRequest() {
      this.working = true;
      bus.emit("loadingState", true);
      this.dialogData.title = "Creating Plate Slip";
      this.dialogData.text = "Creating and sending Plate Slip";
      this.dialogData.icon = "mdi-ticket-confirmation";
      this.dialog = true;
      await addPlateSlip({
        saleOrder: this.jobObject.saleOrder,
        selectedPlates: this.selectedPlates,
        reason: this.reason,
      }).then((result) => {
        bus.emit("loadingState", false);
        this.working = false;
        this.dialogData.title = result.data.response;
        this.dialogData.icon = result.data.icon;
        this.dialogData.text = result.data.message;
        this.dialogData.redirect = "reload";
        this.dialog = true;
      });
    },
  },
};
</script>

<style>
.custom-checkbox .v-icon {
  font-size: 40px;
}

.custom-checkbox .v-label {
  margin-left: 10px;
}
</style>
