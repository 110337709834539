<template>
  <v-row justify="end" no-gutters>
    <v-col cols="12" xs="12" md="4">
      <v-menu
        v-model="datePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        location="bottom"
        :disabled="working"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ props }">
          <v-text-field
            v-model="dateRangeText"
            label="Select date range"
            prepend-icon="mdi-calendar"
            readonly
            :disabled="working"
            v-bind="props"
          ></v-text-field>
        </template>
        <v-date-picker
          multiple="range"
          v-model="dates"
          @update:model-value="dateChanged"
          :disabled="working"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col>
      <v-card class="px-2 py-4" color="cards">
        <apexchart
          type="line"
          height="400"
          :options="currStates_ChartOptions"
          :series="currStatesSeries"
        >
        </apexchart>
      </v-card>
      <v-card class="px-2 py-4 mt-2" color="cards">
        <apexchart
          type="bar"
          height="350"
          :options="jobs_ChartOptions"
          :series="jobsSeries"
        ></apexchart>
      </v-card>
      <v-card class="px-2 py-4 mt-2" color="cards">
        <apexchart
          type="area"
          height="500"
          :options="materials_ChartOptions"
          :series="materialsSeries"
        >
        </apexchart>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import moment from "moment";
import { firestore } from "@/firebase";
import { collection, getDocs, orderBy, startAt, endAt, query } from "firebase/firestore";
import bus from "vue3-eventbus";
import { useTheme } from "vuetify";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    working: true,
    datePicker: false,
    dates: [],
    currStatesSeries: [
      { name: "Repro", data: [] },
      { name: "Out Source", data: [] },
      { name: "VPS", data: [] },
      { name: "CTP", data: [] },
      { name: "Plateout", data: [] },
      { name: "Dispatch", data: [] },
    ],
    jobsSeries: [
      { name: "Created", data: [] },
      { name: "Completed", data: [] },
      { name: "Redo", data: [] },
    ],
    materialsSeries: [
      { name: "Used", data: [] },
      { name: "Wasted", data: [] },
    ],
    currStates_ChartOptions: {
      chart: {
        type: "line",
        zoom: { enabled: true },
        toolbar: {
          show: true,
          tools: { zoom: true, zoomin: true, zoomout: true, pan: true, reset: true },
        },
      },
      dataLabels: { enabled: false },
      stroke: { curve: "straight", width: 2 },
      title: { text: "Job Stages", align: "left" },
      subtitle: { text: "Item Movements", align: "left" },
      xaxis: { type: "datetime", labels: { format: "dd MMM" } },
      yaxis: { opposite: true },
      legend: { horizontalAlign: "left" },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          type: "vertical",
          gradientToColors: [],
          opacityFrom: 1,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      colors: [],
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: { width: "100%", height: "300px" },
            legend: { position: "bottom" },
          },
        },
        {
          breakpoint: 600,
          options: {
            chart: { width: "100%", height: "250px" },
            legend: { position: "bottom" },
          },
        },
      ],
      theme: { mode: "light" },
    },
    jobs_ChartOptions: {
      chart: {
        type: "bar",
        zoom: { enabled: true },
        toolbar: {
          show: true,
          tools: { zoom: true, zoomin: true, zoomout: true, pan: true, reset: true },
        },
      },
      dataLabels: { enabled: false },
      stroke: { show: true, width: 2, colors: ["transparent"] },
      title: { text: "Sales Orders", align: "left" },
      xaxis: { type: "datetime", labels: { format: "dd MMM" } },
      yaxis: { opposite: true },
      legend: { horizontalAlign: "left" },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          type: "vertical",
          gradientToColors: [],
          opacityFrom: 1,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      colors: [],
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: { width: "100%", height: "300px" },
            legend: { position: "bottom" },
          },
        },
        {
          breakpoint: 600,
          options: {
            chart: { width: "100%", height: "250px" },
            legend: { position: "bottom" },
          },
        },
      ],
      theme: { mode: "light" },
    },
    materials_ChartOptions: {
      chart: {
        type: "area",
        zoom: { enabled: true },
        toolbar: {
          show: true,
          tools: { zoom: true, zoomin: true, zoomout: true, pan: true, reset: true },
        },
      },
      dataLabels: { enabled: false },
      stroke: { curve: "straight", width: 2 },
      title: { text: "Materials Usage", align: "left" },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (val) {
            return new Date(val).toLocaleString(); // Format date and time
          },
        },
      },
      yaxis: { opposite: true },
      legend: { horizontalAlign: "left" },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          type: "vertical",
          gradientToColors: [],
          opacityFrom: 1,
          opacityTo: 0.3,
          stops: [0, 90, 100],
        },
      },
      colors: [],
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: { width: "100%", height: "300px" },
            legend: { position: "bottom" },
          },
        },
        {
          breakpoint: 600,
          options: {
            chart: { width: "100%", height: "250px" },
            legend: { position: "bottom" },
          },
        },
      ],
      theme: { mode: "light" },
    },
  }),
  computed: {
    dateRangeText() {
      return (
        moment(this.dates[0]).format(" DD MMM YYYY") +
        " - " +
        moment(this.dates[this.dates.length - 1]).format(" DD MMM YYYY")
      );
    },
  },
  created() {
    var todayDate = new Date();
    for (let i = 13; i >= 0; i--) {
      let dateValue = new Date();
      dateValue.setTime(todayDate.getTime() - 86400000 * i);
      this.dates.push(dateValue);
    }

    this.fetchData("currStates", this.dates);
    this.fetchData("jobs", this.dates);
    this.fetchData("materials", this.dates);
    this.setVuetifyColors(this.theme);
  },
  methods: {
    async fetchData(type, dates) {
      if (!dates || dates.length < 2) return;
      this.working = true;
      bus.emit("loadingState", true);
      const data = await this.getData(type, dates);
      this.processData(type, data);
      this.working = false;
      bus.emit("loadingState", false);
    },
    async getData(type, dates) {
      const startDate = dates[0];
      const endDate = dates[dates.length - 1];
      let coll;
      switch (type) {
        case "currStates":
          coll = collection(firestore, "analytics/currStates/day");
          break;
        case "jobs":
          coll = collection(firestore, "analytics/jobs/day");
          break;
        case "materials":
          coll = collection(firestore, "analytics/materials/all");
          break;
        default:
          throw new Error("Invalid data type");
      }
      const q = query(coll, orderBy("timestamp"), startAt(startDate), endAt(endDate));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => doc.data());
    },
    processData(type, data) {
      switch (type) {
        case "currStates":
          this.processCurrStatesData(data);
          break;
        case "jobs":
          this.processJobsData(data);
          break;
        case "materials":
          this.processMaterialsData(data);
          break;
        default:
          throw new Error("Invalid data type");
      }
    },
    processCurrStatesData(data) {
      const series = {
        repro: [],
        osl: [],
        vps: [],
        ctp: [],
        plateout: [],
        dispatch: [],
      };
      data.forEach((item) => {
        const timestamp = item.timestamp.toDate();
        series.repro.push({ x: timestamp, y: item.repro || 0 });
        series.osl.push({ x: timestamp, y: item.osl || 0 });
        series.vps.push({ x: timestamp, y: item.vps || 0 });
        series.ctp.push({ x: timestamp, y: item.ctp || 0 });
        series.plateout.push({ x: timestamp, y: item.plateout || 0 });
        series.dispatch.push({ x: timestamp, y: item.dispatch || 0 });
      });
      this.currStatesSeries.forEach((serie, index) => {
        const key = Object.keys(series)[index];
        serie.data = series[key];
      });
    },
    processJobsData(data) {
      const dateMap = {};
      data.forEach((item) => {
        const date = moment(item.date, "DD-MMM-YYYY").format("YYYY-MM-DD");
        if (!dateMap[date]) {
          dateMap[date] = { created: 0, completed: 0, redo: 0 };
        }
        dateMap[date].created += item.created || 0;
        dateMap[date].completed += item.completed || 0;
        dateMap[date].redo += item.redo || 0;
      });

      const seriesData = { created: [], completed: [], redo: [] };
      Object.keys(dateMap).forEach((date) => {
        seriesData.created.push({ x: date, y: dateMap[date].created });
        seriesData.completed.push({ x: date, y: dateMap[date].completed });
        seriesData.redo.push({ x: date, y: dateMap[date].redo });
      });

      this.jobsSeries[0].data = seriesData.created;
      this.jobsSeries[1].data = seriesData.completed;
      this.jobsSeries[2].data = seriesData.redo;
    },
    processMaterialsData(data) {
      const usedData = [];
      const wastedData = [];

      data.forEach((item) => {
        const timestamp = item.timestamp.toDate();
        item.used.forEach((u) => {
          usedData.push({ x: timestamp, y: u.count });
        });
        if (item.wasted.length === 0) {
          wastedData.push({ x: timestamp, y: 0 });
        } else {
          item.wasted.forEach((w) => {
            wastedData.push({ x: timestamp, y: w.count });
          });
        }
      });

      this.materialsSeries[0].data = usedData;
      this.materialsSeries[1].data = wastedData;
    },
    setVuetifyColors(theme) {
      const isDark = theme.global.current.value.dark;
      const colors = theme.global.current.value.colors;

      this.currStates_ChartOptions.colors = [
        colors.repro,
        colors.osl,
        colors.vps,
        colors.ctp,
        colors.plateout,
        colors.dispatch,
      ];
      this.currStates_ChartOptions.fill.gradient.gradientToColors = [
        colors.repro,
        colors.osl,
        colors.vps,
        colors.ctp,
        colors.plateout,
        colors.dispatch,
      ];
      this.currStates_ChartOptions.theme.mode = isDark ? "dark" : "light";

      this.jobs_ChartOptions.colors = [colors.dispatch, colors.secondary, colors.osl];
      this.jobs_ChartOptions.fill.gradient.gradientToColors = [
        colors.dispatch,
        colors.secondary,
        colors.osl,
      ];
      this.jobs_ChartOptions.theme.mode = isDark ? "dark" : "light";

      this.materials_ChartOptions.colors = [colors.primary, colors.error];
      this.materials_ChartOptions.fill.gradient.gradientToColors = [
        colors.primary,
        colors.error,
      ];
      this.materials_ChartOptions.theme.mode = isDark ? "dark" : "light";

      this.jobs_ChartOptions.chart.background = isDark ? colors.cards : colors.cards;
      this.materials_ChartOptions.chart.background = isDark ? colors.cards : colors.cards;
      this.currStates_ChartOptions.chart.background = isDark
        ? colors.cards
        : colors.cards;

      this.jobs_ChartOptions.xaxis.labels = {
        ...this.jobs_ChartOptions.xaxis.labels,
        style: {
          colors: isDark ? "#FFFFFF" : "#000000",
        },
      };
      this.jobs_ChartOptions.yaxis.labels = {
        ...this.jobs_ChartOptions.yaxis.labels,
        style: {
          colors: isDark ? "#FFFFFF" : "#000000",
        },
      };
      this.jobs_ChartOptions.legend.labels = {
        colors: isDark ? "#FFFFFF" : "#000000",
      };

      this.materials_ChartOptions.xaxis.labels = {
        ...this.materials_ChartOptions.xaxis.labels,
        style: {
          colors: isDark ? "#FFFFFF" : "#000000",
        },
      };
      this.materials_ChartOptions.yaxis.labels = {
        ...this.materials_ChartOptions.yaxis.labels,
        style: {
          colors: isDark ? "#FFFFFF" : "#000000",
        },
      };
      this.materials_ChartOptions.legend.labels = {
        colors: isDark ? "#FFFFFF" : "#000000",
      };

      this.currStates_ChartOptions.xaxis.labels = {
        ...this.currStates_ChartOptions.xaxis.labels,
        style: {
          colors: isDark ? "#FFFFFF" : "#000000",
        },
      };
      this.currStates_ChartOptions.yaxis.labels = {
        ...this.currStates_ChartOptions.yaxis.labels,
        style: {
          colors: isDark ? "#FFFFFF" : "#000000",
        },
      };
      this.currStates_ChartOptions.legend.labels = {
        colors: isDark ? "#FFFFFF" : "#000000",
      };
    },
    dateChanged() {
      if (this.dates.length >= 2) {
        this.fetchData("currStates", this.dates);
        this.fetchData("jobs", this.dates);
        this.fetchData("materials", this.dates);
      }
    },
  },
  setup() {
    const theme = useTheme();

    return {
      theme,
    };
  },
};
</script>
