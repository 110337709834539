<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12" lg="8">
      <v-data-table :height="tableHeight" class="bg-navBar my-data-table" :headers="headers" :items="rows"
        v-model:items-per-page="itemsPerPage" item-key="name" :loading="working" v-model:page="page"
        @update:page="loadMore" fixed-header
        :items-per-page-options="[{ value: 5, title: '5' }, { value: 10, title: '10' }, { value: 20, title: '20' }, { value: 50, title: '50' }]"
        item-value="name" hover>
        <template v-slot:item="{ item }">
          <tr @click="goToPage(item)">
            <td>{{ item.timestamp }}</td>
            <td>{{ item.items }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.user }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import moment from "moment";
import DialogModal from "@/components/common/dialogModal";
import { firestore } from "@/firebase";
import { collection, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore";
import { doc, where, getDoc } from "firebase/firestore";

export default {
  data: () => ({
    working: true,
    tableHeight: window.innerHeight * 0.82,
    headers: [
      { title: 'Created', key: 'timestamp', width: 200 },
      { title: 'Items', key: 'items', width: 80 },
      { title: 'Name', key: 'name' },
      { title: 'By', key: 'user', width: 250 },
    ],
    rows: [],
    page: 1,
    itemsPerPage: 10,
    noMore: false,
    lastDoc: null,
    reportName: null,
    dialog: false,
    dialogData: [
      {
        title: "",
        icon: "",
        text: "",
      },
    ],
  }),
  components: {
    DialogModal,
  },
  computed: {
    pageCount() {
      return Math.ceil(this.rows.length / this.itemsPerPage)
    },
  },
  async created() {
    this.working = true;
    bus.emit("loadingState", true);
    this.setConditions();
  },
  methods: {
    async setConditions() {
      bus.emit("loadingState", true);
      const jobsRef = collection(firestore, "reportCharges");
      let q;

      if (this.noMore == false && this.lastDoc == null) {
        q = await query(jobsRef, orderBy("timestamp", "desc"), limit(this.itemsPerPage * 4));
        this.getJobs(q);
      } else if (this.noMore == false && this.lastDoc != null) {
        q = await query(jobsRef, orderBy("timestamp", "desc"), startAfter(this.lastDoc), limit(this.itemsPerPage * 3));
        this.getJobs(q);
      } else {
        this.working = false;
        bus.emit("loadingState", false);
      }
    },
    async getJobs(q) {
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
        this.noMore = true;
      } else {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        for (let i = 0; i < querySnapshot.docs.length; i++) {
          this.rows.push({
            name: querySnapshot.docs[i].data().name,
            items: querySnapshot.docs[i].data().items,
            user: await this.getName(querySnapshot.docs[i].data().userID, "users"),
            timestamp: await this.formatDateTime(querySnapshot.docs[i].data().timestamp, "DD MMM YYYY"),
          });
        }
      }
      this.working = false;
      bus.emit("loadingState", false);
    },
    loadMore() {
      if (this.page >= (this.pageCount - 1)) {
        this.setConditions();
      }
    },
    async getName(ID, type) {
      if (ID == null) {
        return " - ";
      } else {

        const docRef = doc(firestore, type, ID);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            return docSnap.data().name;
          } else {
            return " - ";
          }
        } catch (error) {
          this.dialogData.title = "error";
          this.dialogData.icon = "mdi-close-circle-outline";
          this.dialogData.text = error;
          this.dialogData.redirect = "/";
          this.dialog = true;
        }
      }
    },
    formatDateTime(timeStamp, format) {
      if (timeStamp != null || timeStamp != undefined) {
        var m = moment(timeStamp.toDate());
        var mFormatted = m.format(format);
        return mFormatted;
      } else {
        return " - ";
      }
    },
    goToPage(row) {
      this.$router.push({ path: `/billing/artwork/generated/${row.name}` });
    }
  }
}
</script>

<style></style>