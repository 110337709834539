<template>
    <v-card class="mt-4" color="action">
        <v-row justify="center" align="center" class="mb-2 mt-2">
            <v-col cols="12" md="8">
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-icon :color="stageObject.icon == 'mdi-step-backward-2' ? 'secondaryError' : 'success'" :icon="stageObject.icon" size="64"></v-icon>
                    </v-col>
                    <v-col cols="auto">
                        <div class="text-start">
                            <div class="text-h6 font-weight-bold">{{ stageObject.text }}</div>
                            <div>From {{ subtitleStringFrom }} to {{ subtitleStringTo }}</div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="4">
                <v-icon icon="mdi-account"></v-icon>
                {{ stageObject.user }}
                <div class="my-1"></div>
                <v-icon icon="mdi-clock-time-eight-outline"></v-icon>
                {{ stageObject.time }}
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";

export default {
    props: ["stageObject"],
    components: {
    },
    computed: {
        subtitleStringFrom() {
            if (this.stageObject.from == "artwork") return "Artwork"
            if (this.stageObject.from == "repro") return "Repro"
            if (this.stageObject.from == "osl") return "Outsource"
            if (this.stageObject.from == "vps") return "Imposition/VPS"
            if (this.stageObject.from == "ctp") return "CtP"
            if (this.stageObject.from == "plateout") return "Plateout"
            if (this.stageObject.from == "dispatch") return "Dispatch"
            if (this.stageObject.from == "completed") return "Completed"
            return ''
        },
        subtitleStringTo() {
            if (this.stageObject.to == "artwork") return "Artwork"
            if (this.stageObject.to == "repro") return "Repro"
            if (this.stageObject.to == "osl") return "Outsource"
            if (this.stageObject.to == "vps") return "Imposition/VPS"
            if (this.stageObject.to == "ctp") return "CtP"
            if (this.stageObject.to == "plateout") return "Plateout"
            if (this.stageObject.to == "dispatch") return "Dispatch"
            if (this.stageObject.to == "completed") return "Completed"
            return ''
        }
    }
}
</script>

<style></style>