<template>
  <v-card class="mt-4" color="cards">
    <v-toolbar flat density="compact" color="navBar" title="CONFIRMED"></v-toolbar>
    <v-row justify="space-between" align="center" class="mt-4 mb-6 pl-8" no-gutters>
      <v-col cols="auto" md="2">
        <div class="text-center">
          <v-icon color="success" icon="mdi-gesture-tap-button" size="96"></v-icon>
        </div>
      </v-col>
      <v-col cols="auto">
        <div>
          <p class="text-h5 font-weight-bold">JOB CONFIRMATION DONE</p>
          <p v-if="stageObject.slipID != null" class="text-h5 font-weight-bold">
            WITH SLIP ID {{ stageObject.slipID }}
          </p>
          <p class="text-subtitle-1">{{ stageObject.user }}</p>
          <p class="text-subtitle-2">{{ stageObject.time }}</p>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <div>
          <p>Delivery Note: {{ stageObject.confirmID }}</p>
        </div>
        <div v-if="stageObject.note != '' || stageObject.note != null">
          <p>Note:</p>
          {{ stageObject.note }}
        </div>
      </v-col>
    </v-row>
    <UserTimeStrip :stageObject="stageObject" />
  </v-card>

  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="slipID_Dialog">
    <v-form ref="form" :disabled="working">
      <v-card
        class="mx-auto"
        elevation="16"
        min-width="300"
        max-width="600"
        title="Redo with Slip ID"
        color="cards"
      >
        <v-divider class="my-3"></v-divider>
        <v-text-field class="mx-6" v-model="slipID" label="Slip ID"></v-text-field>
        <v-divider></v-divider>
        <div class="pa-4 text-end">
          <v-btn
            class="ml-4 text-none"
            color="success"
            min-width="92"
            rounded
            variant="flat"
            @click="validate"
          >
            Redo
          </v-btn>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import UserTimeStrip from "@/components/job/userTimeSrip";
import DialogModal from "@/components/common/dialogModal";
import { sendBack, redoJob } from "@/firebase";

export default {
  props: ["stageObject", "jobObject", "allStages"],
  data: () => ({
    working: false,
    slipID_Dialog: false,
    slipID: null,
    slipIDRules: [
      (v) => {
        if (v) return v.length >= 5 || "Must be atleast 5 characters";
        else return true;
      },
    ],
    dialog: false,
    dialogData: [
      {
        title: "",
        icon: "",
        text: "",
      },
    ],
  }),
  components: {
    DialogModal,
    UserTimeStrip,
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) this.redoFunction();
    },
    async sendBackFunction() {
      this.working = true;
      bus.emit("loadingState", true);
      this.dialogData.title = "Updating";
      this.dialogData.text = "Sending back to Dispatch";
      this.dialogData.icon = "mdi-backburger";
      this.dialog = true;

      await sendBack({
        saleOrder: this.jobObject.saleOrder,
      }).then((result) => {
        bus.emit("loadingState", false);
        this.working = false;
        this.dialogData.title = result.data.response;
        this.dialogData.icon = result.data.icon;
        this.dialogData.text = result.data.message;
        this.dialogData.redirect = "/jobs/dispatch";
        this.dialog = true;
      });
    },
    async redoFunction() {
      this.slipID_Dialog = false;
      this.working = true;
      bus.emit("loadingState", true);
      this.dialogData.title = "Updating";
      this.dialogData.text = "Setting Job for Redo";
      this.dialogData.icon = "mdi-redo-variant";
      this.dialog = true;

      await redoJob({
        saleOrder: this.jobObject.saleOrder,
        slipID: this.slipID,
      }).then((result) => {
        bus.emit("loadingState", false);
        this.working = false;
        this.dialogData.title = result.data.response;
        this.dialogData.icon = result.data.icon;
        this.dialogData.text = result.data.message;
        this.dialogData.redirect = "/jobs/completed";
        this.dialog = true;
      });
    },
  },
};
</script>

<style></style>
