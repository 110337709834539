<template>
    <v-form class="mt-4 mb-16" ref="form" :disabled="working">
        <v-card class="mt-4" color="cards">
            <v-toolbar flat color="navBar" title="DISPATCH"></v-toolbar>
            <v-row justify="center" align="center">
                <v-col cols="12" lg="4">
                    <div class="text-center mb-2">
                        <div class="text-h5 font-weight-bold mx-16">JOB CONFIRMATION</div>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="mt-6"></div>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="confirmID" label="Delivery Note"
                                :rules="requiredRule"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-if="slipID != null" v-model="slipIDNow" label="Slip ID"
                                :rules="requiredRule"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-textarea label="Note" rows="2" counter="260" v-model="note" auto-grow density="compact"></v-textarea>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-btn class="px-4" @click="sendBackFunction" prepend-icon="mdi-arrow-collapse-left" variant="tonal"
                    :loading="working" color="success">
                    Send back to Plateout
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="px-4" @click="validate" append-icon="mdi-arrow-collapse-right" variant="flat"
                    :loading="working" color="success">
                    Job Confirmation Done
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import DialogModal from "@/components/common/dialogModal";

import { firestore, addDispatch, sendBack } from "@/firebase";

export default {
    props: ["jobObject", "slipID"],
    data: () => ({
        working: false,
        confirmID: null,
        note: "",
        dialog: false,
        dialogData: [{
            title: "",
            icon: "",
            text: ""
        }],
        requiredRule: [(v) => !!v || "Number is required"],
        slipIDNow: null,
    }),
    components: {
        DialogModal,
    },
    methods: {
        async validate() {
            const { valid } = await this.$refs.form.validate();
            if (valid) this.addStageFun();
        },
        async addStageFun() {
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = "Updating";
            this.dialogData.text = "Confirming Job";
            this.dialogData.icon = "mdi-note-text";
            this.dialog = true;

            await addDispatch({
                slipID: this.slipIDNow,
                saleOrder: this.jobObject.saleOrder,
                confirmID: this.confirmID,
                note: this.note,
            }).then((result) => {
                bus.emit("loadingState", false);
                this.working = false;
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialogData.redirect = "/jobs/dispatch";
                this.dialog = true;
            });
        },
        async sendBackFunction() {
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = "Updating";
            this.dialogData.text = "Sending back to Plateout";
            this.dialogData.icon = "mdi-backburger";
            this.dialog = true;

            await sendBack({
                saleOrder: this.jobObject.saleOrder,
            }).then((result) => {
                bus.emit("loadingState", false);
                this.working = false;
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialogData.redirect = "/jobs/plateout";
                this.dialog = true;
            });
        }
    }
}
</script>

<style></style>