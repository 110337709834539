<template>
  <NoInternet v-if="connection" />
  <div v-if="noSuchDoc">
    <NoContent :componentColor="state" />
  </div>
  <div v-else>
    <JobFormArtwork v-if="job.length > 0" :jobObject="job[0]" />
    <v-row justify="center" no-gutters>
      <v-col cols="12" v-for="(stage, index) in stages" :key="index">
        <ItemCard v-if="stage.stage == 'item'" :stageObject="stage" />
        <JobCard v-if="stage.stage == 'job'" :stageObject="stage" />
      </v-col>
    </v-row>
    <spinner v-if="working" componentColor="primary" />
  </div>

  <!-- ///////////// Last Card ///////////////////// -->
  <div v-if="['Administrator', 'Manager', 'Designer'].includes(userRole) && !working">
    <AddItem :jobObject="job[0]" />
  </div>

  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import moment from "moment";
import { firestore } from "@/firebase";
import { doc, getDoc, collection, getDocs, query, orderBy } from "firebase/firestore";

// Component Imports
import NoContent from "@/components/common/noJobs";
import Spinner from "@/components/common/spinner";
import NoInternet from "@/components/common/noInternet";
import DialogModal from "@/components/common/dialogModal";
import HoldForm from "@/components/job/holdForm";
import HoldCard from "@/components/job/holdCard";
import JobFormArtwork from "@/components/artwork/jobForm";
import JobCard from "@/components/offset_stages/jobCard";
import ItemCard from "@/components/artwork/itemCard";
import ActionCard from "@/components/offset_stages/actionCard";
import TextCard from "@/components/offset_stages/textCard";
import AddItem from "@/components/artwork/addItem";

export default {
  data() {
    return {
      userRole: localStorage.userRole,
      working: true,
      connection: false,
      noSuchDoc: false,
      job: [],
      stages: [],
      dialog: false,
      dialogData: {},
      cache: {},
    };
  },
  components: {
    Spinner,
    NoContent,
    NoInternet,
    JobFormArtwork,
    ItemCard,
    JobCard,
    AddItem,
    DialogModal,
  },
  created() {
    this.userRole = localStorage.userRole;
    this.state = this.$route.path.split("/").slice(2, 3).toString();
    this.getJobDetails();
    bus.on("reload", (reloadType) => {
      this[reloadType]();
    });
  },
  methods: {
    async getJobDetails() {
      let jobRef = doc(firestore, "artworks", this.$route.params.jobID);
      const docSnap = await getDoc(jobRef);

      if (docSnap.metadata.fromCache) {
        this.connection = true;
      } else if (docSnap.exists()) {
        const data = {
          ...docSnap.data(),
          createdBy: await this.getName(docSnap.data().createdBy, "users"),
          created: await this.formatDateTime(
            docSnap.data().created,
            "DD MMM YYYY - hh:mm A"
          ),
        };
        this.job.push(data);
        this.getItems();
      } else {
        this.noSuchDoc = true;
        this.working = false;
        bus.emit("loadingState", false);
      }
    },
    async getItems() {
      const stagesRef = collection(
        firestore,
        "artworks",
        this.$route.params.jobID,
        "stages" // ------------------------------- ITEM CHANGE
      );
      const q = await query(stagesRef, orderBy("timestamp"));
      const querySnapshot = await getDocs(q);

      for (let doc of querySnapshot.docs) {
        if (doc.data().stage === "item" || doc.data().stage === "job") {
          const data = {
            ...doc.data(),
            user: await this.getName(doc.data().userID, "users"),
            time: await this.formatDateTime(
              doc.data().timestamp,
              "DD MMM YYYY - hh:mm A"
            ),
          };
          this.stages.push(data);
        }
      }
      this.working = false;
      bus.emit("loadingState", false);
    },
    async getName(document, collection) {
      if (!document) return " - ";
      const cacheKey = `${collection}${document}`;
      if (this.cache[cacheKey]) return this.cache[cacheKey];
      const docRef = doc(firestore, collection, document);
      const docSnap = await getDoc(docRef);
      this.cache[cacheKey] = docSnap.exists() ? docSnap.data().name : " - ";
      return this.cache[cacheKey];
    },
    formatDateTime(timeStamp, format) {
      return timeStamp ? moment(timeStamp.toDate()).format(format) : " - ";
    },
    reload() {
      this.working = true;
      this.connection = false;
      this.noSuchDoc = false;
      this.job = [];
      this.stages = [];
      bus.emit("loadingState", true);
      this.getJobDetails();
    },
  },
};
</script>
