<template>
  <v-row v-if="loading" justify="center">
    <v-col cols="auto">
      <v-progress-circular
        class="mt-6"
        model-value="20"
        :size="115"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-col>
  </v-row>

  <v-row>
    <v-col v-if="camera">
      <qrcode-stream
        :formats="['code_128']"
        :paused="!camera"
        @error="onError"
        @detect="onDetect"
        @camera-on="onCameraReady"
      />
    </v-col>

    <v-col
      v-if="decoded"
      :class="['full-screen-bg', stageColor]"
      :style="{ height: computedHeight + 'px' }"
    >
      <v-container fill-height>
        <v-row justify="center">
          <v-col class="text-center">
            <v-icon :icon="icon" size="80"></v-icon>
            <div class="text-h5 mb-10">{{ stage }}</div>
            <v-card color="cards" min-height="150" class="px-2">
              <v-card-text>
                <div class="text-h5">Sales Order</div>
                <div class="text-h4">{{ decodedText }}</div>
                <v-btn class="my-2" color="primary" @click="goToJobPage" block>
                  Open Job
                </v-btn>
                <div class="text-body-2 mt-10 mb-2">
                  {{ listOfSO.length }} numbers in list for verification
                </div>
                <v-autocomplete
                  label="Select Stages to verify"
                  item-title="name"
                  item-value="id"
                  :items="stages"
                  v-model="selectedStages"
                  multiple
                ></v-autocomplete>
                <v-btn color="vps" variant="tonal" @click="getReport" block>
                  Get Report
                </v-btn>
                <v-row justify="space-between" no-gutters class="mt-10">
                  <v-col cols="auto">
                    <v-btn color="error" @click="clear"> Clear </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn color="success" @click="close"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>

  <v-row v-if="report">
    <v-col cols="12" v-for="(list, title) in responseLists" :key="title">
      <div class="text-body-1">{{ list.length }} {{ title }}</div>
      <v-row no-gutters>
        <v-col cols="12" v-for="(item, i) in list" :key="i">
          <v-alert class="my-1" border variant="tonal" :color="item.currState">
            Sales Order: {{ item.so }} in {{ getStageName(item.currState) }}
          </v-alert>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" @close="dialog = false" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import { QrcodeStream } from "vue-qrcode-reader";
import { firestore, statesReport } from "@/firebase";
import { getDoc, doc } from "firebase/firestore";
import DialogModal from "@/components/common/dialogModal";

export default {
  components: { QrcodeStream, DialogModal },
  data() {
    return {
      loading: true,
      camera: true,
      decoded: false,
      report: false,
      decodedText: "",
      stage: "CTP",
      icon: "mdi-basket-unfill",
      stageColor: "bg-ctp",
      result: "",
      dialog: false,
      dialogData: {},
      computedHeight: window.innerHeight - 70,
      listOfSO: [],
      stages: [
        { name: "Repro", id: "repro" },
        { name: "Outsource", id: "osl" },
        { name: "Imposition", id: "vps" },
        { name: "CtP", id: "ctp" },
        { name: "Plate Out", id: "plateout" },
        { name: "Dispatch", id: "dispatch" },
      ],
      selectedStages: [],
      response: null,
      zoomLevel: 4.0,
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateHeight);
  },
  beforeIUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  },
  created() {
    bus.emit("loadingState", false);
  },
  computed: {
    responseLists() {
      return {
        "Other Jobs in Selected State": this.response.additionalSONumbers,
        "Jobs in Wrong State": this.response.wrongStateArray,
        "Scanned Jobs": this.response.matchedArray,
        "Job not found": this.response.missingArray,
      };
    },
  },
  methods: {
    updateHeight() {
      this.computedHeight = window.innerHeight - 70;
    },
    async onDetect(detectedCodes) {
      this.camera = false;
      this.loading = true;
      // Trigger phone vibration
      if (navigator.vibrate) {
        navigator.vibrate(200); // Vibrate for 200 milliseconds
      }
      this.result = JSON.stringify(detectedCodes.map((code) => code.rawValue));

      // Remove the square brackets and quotes by slicing the string
      const cleanedResult = this.result.slice(2, -2);

      // Split by '/' to get the first part as the decoded text
      const decodedArray = cleanedResult.split("/");
      this.decodedText = decodedArray[0];

      await this.fetchColorFromFirestore();
    },
    async fetchColorFromFirestore() {
      try {
        const jobDoc = doc(firestore, "jobs", this.decodedText);
        const docSnap = await getDoc(jobDoc);

        if (docSnap.exists()) {
          if (!this.listOfSO.includes(this.decodedText)) {
            this.listOfSO.push(this.decodedText);
          }
          this.stage = this.getStageName(docSnap.data().currState);
          this.icon = this.getStageIcon(docSnap.data().currState);
          this.stageColor = "bg-" + docSnap.data().currState || "bg-background";
        } else {
          console.error("No such document!");
        }
        this.loading = false;
        this.decoded = true;
      } catch (error) {
        console.error("Error fetching document: ", error);
        this.showDialog(
          "Error",
          "mdi-alert-circle-outline",
          "Error fetching document: " + error.message,
          "reload"
        );
      }
    },
    close() {
      this.decoded = false;
      this.camera = true;
      this.stage = "";
      this.icon = "";
      this.stageColor = "bg-background";
    },
    clear() {
      this.listOfSO = [];
      this.decoded = false;
      this.camera = true;
      this.stage = "";
      this.icon = "";
      this.stageColor = "bg-background";
    },
    async getReport() {
      if (this.listOfSO.length >= 1) {
        bus.emit("loadingState", true);
        this.loading = true;
        this.camera = false;
        this.decoded = false;

        try {
          await statesReport({
            soNumbers: this.listOfSO,
            fields: this.selectedStages,
          }).then((result) => {
            if (result.data.response == "success") {
              bus.emit("loadingState", false);
              this.loading = false;
              this.response = result.data;
              this.report = true;
            }
            console.log(result);
          });
        } catch (error) {
          console.error(error);
          this.showDialog("Error", "mdi-alert-circle-outline", error, "reload");
        }
      } else {
        this.showDialog(
          "Error",
          "mdi-alert-circle-outline",
          "No Sales Order numbers in the list",
          "close"
        );
      }
    },
    goToJobPage() {
      this.$router.push(`/jobs/completed/checklist/${this.decodedText}`);
    },
    onCameraReady() {
      this.loading = false;
    },
    onError(error) {
      this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
    },
    getStageIcon(stage) {
      if (stage == "repro") {
        return "mdi-palette-swatch";
      } else if (stage == "osl") {
        return "mdi-arrow-decision";
      } else if (stage == "vps") {
        return "mdi-format-wrap-tight";
      } else if (stage == "ctp") {
        return "mdi-gradient";
      } else if (stage == "plateout") {
        return "mdi-basket-unfill";
      } else if (stage == "dispatch") {
        return "mdi-truck-delivery";
      } else if (stage == "completed") {
        return "mdi-sticker-check-outline";
      } else {
        return;
      }
    },
    getStageName(stage) {
      if (stage == "repro") {
        return "Repro";
      } else if (stage == "osl") {
        return "Outsource List";
      } else if (stage == "vps") {
        return "Imposition";
      } else if (stage == "ctp") {
        return "CtP";
      } else if (stage == "plateout") {
        return "Plateout";
      } else if (stage == "dispatch") {
        return "Dispatch";
      } else if (stage == "completed") {
        return "Completed";
      } else {
        return "Not Found";
      }
    },
    showDialog(title, icon, message, action) {
      this.sending = false;
      this.deleting = false;
      this.dialog = false;
      this.dialogData = {
        title: title,
        icon: icon,
        text: message,
        redirect: action,
      };
      this.dialog = true;
    },
    applyZoom() {
      if (this.camera) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            const videoTrack = stream.getVideoTracks()[0];
            const capabilities = videoTrack.getCapabilities();

            if (capabilities.zoom) {
              videoTrack.applyConstraints({
                advanced: [{ zoom: this.zoomLevel }],
              });
            }
          })
          .catch((error) => {
            console.error("Error accessing camera: ", error);
            this.showDialog("Error", "mdi-alert-circle-outline", error, "reload");
          });
      }
    },
  },
};
</script>

<style scoped>
.full-screen-bg {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
