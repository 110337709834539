<template>
  <v-divider></v-divider>
  <v-row no-gutters justify="space-between" align="center" class="pa-2">
    <v-col cols="auto">
      <div class="text-caption">
        <v-icon class="mr-1" icon="mdi-account"></v-icon>{{ stageObject.user }}
      </div>
    </v-col>
    <v-col cols="auto">
      <div class="text-caption">
        {{ stageObject.time }}
        <v-icon class="ml-1" icon="mdi-clock-time-eight-outline"></v-icon>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["stageObject"],
};
</script>

<style></style>
