<template>
  <v-card class="mt-4" color="discount" variant="outlined">
    <v-row justify="space-around" align="center" class="my-2" no-gutters>
      <v-col cols="auto">
        <v-icon icon="mdi-percent" size="40"></v-icon>
      </v-col>
      <v-col cols="12" md="auto">
        <div class="mx-2">
          <p class="text-h5">
            Discount
            <strong>{{ stageObject.discount }} {{ stageObject.discountType }}</strong>
          </p>
          <p class="text-subtitle-1">
            Amount after discount <strong>{{ stageObject.afterDiscount }} USD</strong>
          </p>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="mx-2">
          <strong>Reason: </strong>
          <p class="text-subtitle-1">
            {{ stageObject.reason }}
          </p>
        </div>
      </v-col>
      <v-col cols="2">
        <div v-if="stageObject.discountApplied" class="text-body-1">Discount applied</div>
        <v-btn
          v-else
          :disabled="!discountBtn"
          color="discount"
          variant="tonal"
          @click="discountDialog = true"
        >
          Apply Discount
        </v-btn>
      </v-col>
    </v-row>
    <UserTimeStrip :stageObject="stageObject" />
  </v-card>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog
    v-model="discountDialog"
    transition="dialog-bottom-transition"
    max-width="500"
  >
    <v-form ref="form" :disabled="working">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Discount</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-row justify="center" align="center" no-gutter>
            <v-col cols="12">
              <v-select
                class="mt-4"
                label="Select type"
                colors="primary"
                v-model="discountType"
                bg-color="navBar"
                variant="outlined"
                :items="discountTypes"
                :rules="requiredRule"
                item-title="name"
                item-value="id"
              ></v-select>
            </v-col>
            <span class="text-h6"
              >Current amount: {{ stageObject.afterDiscount }} USD</span
            >
            <v-col cols="10">
              <v-text-field
                v-model="discount"
                variant="solo"
                flat
                type="number"
                :rules="[discountRule]"
                class="text-h5 inputFont"
                density="compact"
              >
                <template v-slot:prepend>
                  <v-icon color="green" @click="decrement()"> mdi-minus </v-icon>
                </template>
                <template v-slot:append-inner>
                  <div class="text-h5">{{ discountType }}</div>
                </template>
                <template v-slot:append>
                  <v-icon color="red" @click="increment()"> mdi-plus </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <span class="text-h6">New amount: {{ afterDiscount }} USD</span>
            <v-col cols="12">
              <v-textarea
                label="Reason"
                rows="3"
                counter="260"
                v-model="reason"
                :rules="requiredRule"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-5"
            color="primary"
            variant="flat"
            :loading="working"
            width="400"
            @click="validate"
          >
            Update
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" @close="dialog = false" />
  </v-dialog>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";
import DialogModal from "@/components/common/dialogModal";
import { discount } from "@/firebase";

export default {
  props: ["stageObject", "jobObject"],
  data: () => ({
    discountDialog: false,
    working: false,
    discountTypes: [
      { name: "Fixed amount", id: " USD" },
      { name: "Percentage", id: "%" },
    ],
    discountType: "%",
    discount: 0,
    reason: "",
    requiredRule: [(v) => !!v || "This is required"],
    dialog: false,
    dialogData: {},
  }),
  components: {
    UserTimeStrip,
    DialogModal,
  },
  computed: {
    discountBtn() {
      const userRole = localStorage.userRole;
      return ["Administrator", "Manager", "Moderator"].includes(userRole);
    },
    discountRule() {
      return (value) => {
        let max = this.discountType === "%" ? 100 : this.stageObject.afterDiscount;
        let min = 0;
        return (
          (value >= min && value <= max) || `The value must be between ${min} and ${max}.`
        );
      };
    },
    afterDiscount() {
      let discount =
        this.discountType == "%"
          ? (this.stageObject.afterDiscount / 100) * this.discount
          : this.discount;
      return (this.stageObject.afterDiscount - discount).toFixed(2);
    },
  },
  methods: {
    decrement() {
      this.discount--;
    },
    increment() {
      this.discount++;
    },
    async validate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) this.addDiscount();
    },
    async addDiscount() {
      this.working = true;
      try {
        const result = await discount({
          saleOrder: this.jobObject.saleOrder,
          discountType: this.discountType,
          discount: this.discount,
          reason: this.reason,
          beforeDiscount: this.stageObject.afterDiscount,
          afterDiscount: this.afterDiscount,
          docID: this.stageObject.docID,
        });
        this.discountDialog = false;
        this.showDialog(
          result.data.response,
          result.data.icon,
          result.data.message,
          "close"
        );

        this.working = false;
      } catch (error) {
        this.showDialog("error", "mdi-close-circle-outline", error.message, "close");
      }
    },
    showDialog(title, icon, message, action) {
      this.sending = false;
      this.deleting = false;
      this.dialog = false;
      this.dialogData = {
        title: title,
        icon: icon,
        text: message,
        redirect: action,
      };
      this.dialog = true;
    },
  },
};
</script>

<style>
.inputFont input {
  font-size: 50px;
  text-align: right;
}
</style>
