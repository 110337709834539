<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="8">
        PDFs
    </v-col>
  </v-row>
</template>

<script>
export default {

}
</script>

<style>

</style>