// Styles
import "vuetify/styles";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css";

// Vuetify
import { createVuetify } from "vuetify";
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi';
import { md } from 'vuetify/lib/iconsets/md';
import { VSkeletonLoader } from 'vuetify/components';
//import { VDataTable, VDataTableServer } from "vuetify/labs/VDataTable";
//import { VSkeletonLoader } from "vuetify/labs/VSkeletonLoader";

const lightTheme = {
  dark: false,
  colors: {
    primary: "#6f4ef2",
    secondary: colors.cyan.darken4,
    neutral: colors.blue.darken4,
    background: colors.grey.lighten2,
    drawer: "#ffffff",
    navBar: colors.grey.lighten3,
    cards: "#ffffff",
    loader: "#6f4ef2",
    error: colors.red.darken4,
    secondaryError: colors.red.lighten4,
    success: colors.green.darken4,
    secondarySuccess: colors.green.lighten4,
    warn: colors.orange.lighten4,
    secondaryWarn: colors.orange.darken4,
    info: colors.grey.darken4,
    artwork: colors.teal.darken4,
    repro: colors.pink.darken4,
    osl: colors.deepOrange.darken4,
    vps: colors.yellow.darken4,
    ctp: colors.green.darken4,
    plateout: colors.deepPurple.darken4,
    dispatch: colors.blue.darken4,
    completed: colors.blueGrey.darken4,
    action: colors.blueGrey.lighten4,
    store: colors.deepPurple.lighten5,
    charges: colors.teal.darken4,
    discount: colors.teal.darken4,
    plateslip: colors.yellow.lighten4,
  },
};

const darkTheme = {
  dark: true,
  colors: {
    primary: "#6f4ef2",
    secondary: colors.teal.darken1,
    neutral: colors.blue.darken3,
    background: "#131129",
    drawer: "#1d1933",
    navBar: "#1d1933",
    cards: "#281f56",
    loader: "#6f4ef2",
    error: colors.red.darken4,
    secondaryError: colors.red.darken4,
    success: "#72cb01",
    secondarySuccess: colors.green.darken4,
    warn: colors.orange.darken1,
    secondaryWarn: colors.deepOrange.darken4,
    info: colors.grey.lighten2,
    artwork: colors.purple.accent3,
    repro: colors.pink.accent3,
    osl: colors.deepOrange.accent3,
    vps: colors.yellow.accent4,
    ctp: colors.lightGreen.accent3,
    plateout: colors.teal.accent3,
    dispatch: colors.indigo.accent2,
    completed: colors.blueGrey.lighten3,
    action: colors.blueGrey.darken4,
    store: "#54561f",
    charges: colors.teal.accent4,
    discount: colors.teal.accent4,
    plateslip: "#074417",
  },
};

export default createVuetify({
  components: {
    //VDataTable,
    //VDataTableServer,
    VSkeletonLoader,
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      md,
    },
  },
  theme: {
    defaultTheme: "lightTheme",
    themes: {
      lightTheme,
      darkTheme,
    },
  },
  display: {
    mobileBreakpoint: 'md',
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280,
      xl: 1360,
    },
  },
});
