<template>
    <NoInternet v-if="!connection" />
    <div v-if="itemObject.merged">
        <v-row justify="center">
            <v-btn class="mt-12 mb-6" prepend-icon="mdi-link" color="primary" :to="link">Linked with Sales Order: {{
                itemObject.SO }}</v-btn>
        </v-row>
    </div>
    <div v-else>
        <v-form class="mt-4" ref="formArtwork" :disabled="working">
            <v-row justify="center" align="center">
                <v-col cols="12" lg="12" xl="10">
                    <v-card color="cards">
                        <v-toolbar flat color="navBar" title="ARTWORK"></v-toolbar>
                        <!---------------------------------------------------------------------------------------------------- ARTWORK STATE----->
                        <v-row justify="center" align="center" class="ma-4">
                            <v-col cols="12" md="5">
                                <v-select label="Select Task" colors="primary" v-model="task" bg-color="navBar"
                                    variant="outlined" :items="taskList" :rules="requiredRule"></v-select>
                                <v-textarea label="Note" rows="1" counter="260" v-model="note" auto-grow></v-textarea>
                            </v-col>
                            <v-col cols="12" md="7">
                                <v-col class="text-left">
                                    <span class="text-h2 font-weight-light" v-text="mins"></span>
                                    <span class="subheading font-weight-light me-1"> mins</span>
                                </v-col>
                                <v-slider v-model="mins" color="primary" track-color="grey" min="0" max="1000" :step="10"
                                    label="Artwork Charge" :rules="requiredRule">
                                    <template v-slot:prepend>
                                        <v-btn size="small" variant="text" icon="mdi-minus" color="error"
                                            @click="decrement"></v-btn>
                                    </template>

                                    <template v-slot:append>
                                        <v-btn size="small" variant="text" icon="mdi-plus" color="success"
                                            @click="increment"></v-btn>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="px-4" @click="validateArtwork" append-icon="mdi-image-plus" variant="flat"
                                :loading="working" color="success">
                                Add Artwork Charges
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <v-row justify="space-around" align="center" class="mt-16 mb-6">
            <v-col cols="12" md="3">
                <v-btn class="px-4" @click="mergeDialog = true" append-icon="mdi-merge" variant="outlined" :loading="working"
                    color="success" block>
                    Merge with SO
                </v-btn>
            </v-col>
            <v-col cols="12" md="4">
                <v-btn @click="convertDialog = true" append-icon="mdi-table-arrow-up" variant="tonal" :loading="working"
                    color="success" block disabled>
                    Convert and send to Repro
                </v-btn>
            </v-col>
        </v-row>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////// Merge Dialog //////// -->
    <v-dialog v-model="mergeDialog" min-width="300" max-width="350">
        <MergeArtwork :itemObject="itemObject" />
    </v-dialog>

    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////// Convert Dialog //////// -->
    <v-dialog v-model="convertDialog" :persistent="working">
        <v-card class="mx-auto" elevation="16" min-width="400" title="Convert Job to Sales Order" color="cards">
            <v-divider class="mt-3"></v-divider>
            <v-form ref="formConvert">
                <v-card-text>
                <v-row justify="center">
                    <v-col cols="12">
                        <v-text-field v-model="saleOrder" label="Sales Order" :rules="salesOrderRules"
                            v-on:keypress="NumbersOnly"></v-text-field>
                    </v-col>
                </v-row>
                <v-checkbox label="QC Checked" :rules="requiredRule"></v-checkbox>
            </v-card-text>
            </v-form>
            <v-card-actions>
                <v-btn color="success" variant="outlined" @click="convertDialog = false" :loading="working">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" variant="flat" @click="validateConvert" :loading="working">
                    Convert
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////// DialogModal //////// -->
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" @close="dialog = false" />
    </v-dialog>
</template>
 
<script>
import moment from "moment";
import bus from "vue3-eventbus";
import DialogModal from "@/components/common/dialogModal";
import NoInternet from "@/components/common/noInternet";
import MergeArtwork from "@/components/artwork/mergeArtwork";

import { firestore, addArtwork, convertArtwork } from "@/firebase";
import { collection, query } from "firebase/firestore";
import { doc, getDocs, getDoc, where, orderBy, limit } from "firebase/firestore";

export default {
    props: ["itemObject"],
    data: () => ({
        connection: true,
        working: false,
        dialog: false,
        dialogData: [{
            title: "",
            icon: "",
            text: ""
        }],
        requiredRule: [(v) => !!v || "This is required"],
        taskList: ['New artwork', 'Total Change', 'Text Change', 'Cutter Change'],
        task: null,
        note: "",
        mins: 0,
        mergeDialog: false,
        convertDialog: false,
        saleOrder: "",
        salesOrderRules: [
            (v) => !!v || "Sales Order number is required",
            (v) => (v && v.length == 6) || "Must be 6 characters",
        ],
        link: "/jobs/search/",
    }),
    components: {
        DialogModal,
        NoInternet,
        MergeArtwork,
    },
    created() {
        this.working = false;
        this.link = "/jobs/search/" + this.itemObject.SO;
    },
    methods: {
        NumbersOnly(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        async getName(ID, type) {
            if (ID == null) {
                return " - ";
            } else {

                const docRef = doc(firestore, type, ID);
                try {
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        return docSnap.data().name;
                    } else {
                        return " - ";
                    }
                } catch (error) {
                    this.dialogData.title = "error";
                    this.dialogData.icon = "mdi-close-circle-outline";
                    this.dialogData.text = error;
                    this.dialog = true;
                }
            }
        },
        formatDateTime(timeStamp, format) {
            if (timeStamp != null) {
                var m = moment(timeStamp.toDate());
                var mFormatted = m.format(format);
                return mFormatted;
            } else {
                return " - ";
            }
        },
        decrement() {
            this.mins--
        },
        increment() {
            this.mins++
        },
        async validateArtwork() {
            const { valid } = await this.$refs.formArtwork.validate();
            if (valid) this.addStageFun();
        },
        async addStageFun() {
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = "Updating";
            this.dialogData.text = "Adding Artwork Charges";
            this.dialogData.icon = "mdi-image-plus";
            this.dialog = true;

            await addArtwork({
                jobID: this.itemObject.jobID,
                docID: this.itemObject.docID,
                task: this.task,
                note: this.note,
                mins: this.mins,
            }).then((result) => {
                bus.emit("loadingState", false);
                this.working = false;
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialogData.redirect = "reloadCards";
                this.dialog = true;
            });
        },
        async validateConvert() {
            const { valid } = await this.$refs.formConvert.validate();
            if (valid) this.convert();
        },
        async convert() {
            if (this.saleOrder.length == 6) {
                this.working = true;
                bus.emit("loadingState", true);
                this.dialogData.title = "Converting";
                this.dialogData.text = "Converting Job ID to Sales Order and sending for Repro";
                this.dialogData.icon = "mdi-table-arrow-up";
                this.dialog = true;

                await convertArtwork({
                    jobID: this.itemObject.jobID,
                    saleOrder: this.saleOrder,
                }).then((result) => {
                    bus.emit("loadingState", false);
                    this.working = false;
                    this.dialogData.title = result.data.response;
                    this.dialogData.icon = result.data.icon;
                    this.dialogData.text = result.data.message;
                    this.dialogData.redirect = "/artworks/artwork";
                    this.dialog = true;
                });
            } else {
                this.dialogData.title = "Error";
                this.dialogData.icon = "mdi-close-circle-outline";
                this.dialogData.text = "Error in Sales Order number";
                this.dialogData.redirect = "Close";
                this.dialog = true;
            }

        }
    }
}
</script>
 
<style>
</style>