<template>
  <v-row justify="center" class="fullHeight" align="center">
    <v-col cols="auto">
      <v-progress-circular :color="this.componentColor" indeterminate :size="120" :width="8"></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>

export default {
    props: ["componentColor"],

}
</script>

<style scoped>
.fullHeight {
  height: 30vh;
}
</style>