<template>
  <v-form class="mt-4" ref="formPlateout" :disabled="working">
    <v-row justify="center" align="center">
      <v-col cols="12" lg="12" xl="12">
        <v-card color="cards">
          <v-toolbar flat color="navBar" title="PLATE OUT"
            ><Completer :jobObject="jobObject"
          /></v-toolbar>
          <v-progress-linear indeterminate v-if="working"></v-progress-linear>
          <NoInternet v-if="!connection" />
          <v-row justify="center" no-gutters class="mt-4">
            <v-col cols="12" md="2">
              <v-autocomplete
                label="Stores Location"
                colors="primary"
                v-model="location"
                bg-color="navBar"
                variant="outlined"
                :items="locations"
                :loading="working"
                density="compact"
                item-title="name"
                :rules="requiredRule"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="auto" v-for="item in checkItems" :key="item">
              <v-checkbox
                class="mx-4"
                density="compact"
                v-model="item.state"
                :label="item.label"
                :rules="item.rule"
                color="success"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                label="Select Plate Size"
                colors="primary"
                v-model="plateSize"
                bg-color="navBar"
                density="compact"
                variant="outlined"
                :items="plateSizes"
                :loading="working"
                item-title="name"
                :rules="requiredRule"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="mx-6" no-gutters>
            <v-col cols="12" md="12">
              <div class="text-h6 mb-2">USED MATERIAL</div>
              <div v-for="(use, i) in used" :key="i">
                <v-row justify="space-between" align="end" no-gutters class="mb-5">
                  <v-col cols="12" md="3">
                    <v-select
                      label="Select material"
                      colors="primary"
                      v-model="use.id"
                      item-value="id"
                      :loading="working"
                      bg-color="navBar"
                      variant="outlined"
                      :items="materials"
                      item-title="name"
                      :hint="use.description"
                      persistent-hint
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-btn
                      variant="text"
                      icon="mdi-minus"
                      color="error"
                      @click="decrementUsed(i)"
                    ></v-btn>
                    <span class="text-h3 font-weight-light" v-text="use.count"></span>
                    <v-btn
                      variant="text"
                      icon="mdi-plus"
                      color="success"
                      @click="incrementUsed(i)"
                    ></v-btn>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-card color="plateout" rounded class="rounded-xl" variant="tonal">
                      <v-row justify="space-between" align="center" no-gutters>
                        <GripperSVG :height="55"></GripperSVG>
                        <v-col cols="12" md="8">
                          <v-slider
                            class="mt-5"
                            v-model="use.gripper"
                            color="primary"
                            track-color="grey"
                            min="0"
                            max="80"
                            :step="1"
                            label="Gripper (Measured)"
                            :rules="requiredRule"
                          >
                            <template v-slot:prepend>
                              <v-btn
                                size="small"
                                variant="text"
                                icon="mdi-minus"
                                color="error"
                                @click="decrementGripper(i)"
                              ></v-btn>
                            </template>

                            <template v-slot:append>
                              <v-btn
                                size="small"
                                variant="text"
                                icon="mdi-plus"
                                color="success"
                                @click="incrementGripper(i)"
                              ></v-btn>
                            </template>
                          </v-slider>
                        </v-col>
                        <div class="text-h4 font-weight-light mr-3">
                          {{ use.gripper }} mm
                        </div>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div v-if="used.length == 0" v-bind:style="{ height: '102px' }"></div>
              <v-row justify="center" align="center" no-gutters>
                <v-col cols="12" md="3">
                  <v-btn
                    class="mb-2"
                    block
                    size="large"
                    @click="removeMaterial('used')"
                    rounded="6"
                    color="secondarySuccess"
                    :disabled="used.length == 0"
                    prepend-icon="mdi-minus"
                  >
                    Remove Used
                  </v-btn>
                  <v-btn
                    block
                    size="large"
                    @click="addMore('used')"
                    rounded="6"
                    color="secondarySuccess"
                    prepend-icon="mdi-plus"
                    :disabled="used.length == 10"
                  >
                    Add Used
                  </v-btn>
                </v-col>
                <v-col cols="12" md="9">
                  <v-sheet
                    v-if="uvVarnishFlexo || specialGold"
                    class="customBackground ml-4"
                    border
                  >
                    <v-row align="center" justify="center" no-gutters>
                      <v-col cols="8" class="mt-2">
                        <v-radio-group
                          v-if="uvVarnishFlexo"
                          v-model="uvFilm"
                          inline
                          label="UV Varnish Film"
                        >
                          <v-radio
                            class="mx-1"
                            label="Available"
                            value="yes"
                            color="success"
                            density="compact"
                          ></v-radio>
                          <v-radio
                            class="mx-1"
                            label="Missing"
                            value="no"
                            color="error"
                            density="compact"
                          ></v-radio>
                          <v-radio
                            class="mx-1"
                            label="Blanket"
                            value="blanket"
                            color="warn"
                            density="compact"
                          ></v-radio>
                          <v-radio
                            class="mx-1"
                            label="Screen"
                            value="screen"
                            color="warn"
                            density="compact"
                          ></v-radio>
                        </v-radio-group>
                        <v-radio-group
                          v-if="specialGold"
                          v-model="goldFilm"
                          inline
                          label="Special Gold Film"
                        >
                          <v-radio
                            class="mx-1"
                            label="Available"
                            value="yes"
                            color="success"
                            density="compact"
                          ></v-radio>
                          <v-radio
                            class="mx-1"
                            label="Missing"
                            value="no"
                            color="error"
                            density="compact"
                          ></v-radio>
                          <v-radio
                            class="mx-1"
                            label="Blanket"
                            value="blanket"
                            color="warn"
                            density="compact"
                          ></v-radio>
                          <v-radio
                            class="mx-1"
                            label="Screen"
                            value="screen"
                            color="warn"
                            density="compact"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="4">
                        <v-row justify="start" no-gutters>
                          <v-col cols="12" class="mt-4">
                            <v-select
                              :items="jobObject.jobID"
                              v-model="jobID"
                              class="mr-4"
                              label="Job ID"
                              density="compact"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row align="center" justify="space-between" no-gutters>
                          <v-col cols="6">
                            <v-select
                              :items="alphabetArray"
                              v-model="rack"
                              label="Rack"
                              class="mr-4"
                              :disabled="rackBagInput"
                              density="compact"
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              :items="numberArray"
                              v-model="shelf"
                              label="Bag"
                              class="mr-4"
                              :disabled="rackBagInput"
                              density="compact"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="px-4" no-gutters>
            <v-col cols="12" md="6">
              <div class="text-h6 mb-4">WASTE MATERIAL</div>
              <div v-for="(waste, i) in wasted" :key="i">
                <v-row align="center" no-gutters class="my-2">
                  <v-col cols="8">
                    <v-select
                      label="Select material"
                      colors="primary"
                      :loading="working"
                      bg-color="navBar"
                      v-model="waste.id"
                      item-value="id"
                      variant="outlined"
                      :items="materials"
                      item-title="name"
                      :hint="waste.description"
                      persistent-hint
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      variant="text"
                      icon="mdi-minus"
                      color="error"
                      @click="decrementWasted(i)"
                    ></v-btn>
                    <span class="text-h3 font-weight-light" v-text="waste.count"></span>
                    <v-btn
                      variant="text"
                      icon="mdi-plus"
                      color="success"
                      @click="incrementWasted(i)"
                    ></v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col>
                  <v-btn
                    block
                    size="large"
                    @click="removeMaterial('wasted')"
                    color="secondaryError"
                    prepend-icon="mdi-minus"
                    rounded="6"
                    :disabled="wasted.length == 0"
                  >
                    Remove Waste
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    block
                    size="large"
                    @click="addMore('wasted')"
                    color="secondaryError"
                    prepend-icon="mdi-plus"
                    rounded="6"
                    :disabled="wasted.length == 10"
                  >
                    Add Waste
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                class="ml-3 pt-4"
                label="Note"
                rows="3"
                counter="260"
                v-model="note"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="px-4"
              @click="sendToCTP"
              prepend-icon="mdi-arrow-collapse-left"
              variant="tonal"
              rounded="6"
              :loading="working"
              color="success"
            >
              Send back to CtP
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="px-4"
              @click="saveToPlateout"
              prepend-icon="mdi-pin"
              variant="outlined"
              rounded="6"
              :loading="working"
              color="success"
            >
              Save to List
            </v-btn>
            <v-btn
              class="px-4"
              @click="sendFromPlateout"
              append-icon="mdi-arrow-collapse-right"
              variant="flat"
              rounded="6"
              :loading="working"
              color="success"
            >
              Send for Dispatch
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import NoInternet from "@/components/common/noInternet";
import DialogModal from "@/components/common/dialogModal";
import GripperSVG from "@/components/job/gripperSVG";
import Completer from "@/components/job/completer";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import bus from "vue3-eventbus";

import { firestore, addPlateout, sendBack } from "@/firebase";
import { doc, collection, query, getDoc, getDocs, orderBy } from "firebase/firestore";

export default {
  props: ["jobObject", "uvVarnishFlexo", "specialGold", "plateOutExist", "storeExist"],
  data: () => ({
    working: false,
    connection: true,
    dialog: false,
    dialogData: [
      {
        title: "",
        icon: "",
        text: "",
      },
    ],
    checkItems: [
      { label: "Plate Density", state: false, rule: [] },
      { label: "Plate Size", state: false, rule: [] },
      { label: "Print Size", state: false, rule: [] },
    ],
    locations: [],
    location: null,
    plateSize: null,
    plateSizes: [],
    materials: [],
    used: [],
    wasted: [],
    note: "",
    alphabetArray: Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)),
    numberArray: Array.from({ length: 20 }, (_, i) =>
      (i + 1).toString().padStart(2, "0")
    ),
    jobID: null,
    rack: null,
    shelf: null,
    uvFilm: null,
    goldFilm: null,
  }),
  components: {
    DialogModal,
    NoInternet,
    GripperSVG,
    Completer,
  },
  computed: {
    requiredRule() {
      return [
        (v) =>
          this.used.length > 0 || this.wasted.length > 0
            ? !!v || "This is required"
            : true,
      ];
    },
    rackBagInput() {
      if (this.uvVarnishFlexo && !this.specialGold) {
        return this.uvFilm !== "yes";
      } else if (!this.uvVarnishFlexo && this.specialGold) {
        return this.goldFilm !== "yes";
      } else if (this.uvVarnishFlexo && this.specialGold) {
        return this.uvFilm !== "yes" && this.goldFilm !== "yes";
      } else {
        return false;
      }
    },
  },
  watch: {
    uvFilm: {
      handler(newValue, oldValue) {
        if (this.uvFilm == "yes" || this.goldFilm == "yes") {
          //
        } else {
          this.rack = null;
          this.shelf = null;
        }
      },
    },
    goldFilm: {
      handler(newValue, oldValue) {
        if (this.uvFilm == "yes" || this.goldFilm == "yes") {
          //
        } else {
          this.rack = null;
          this.shelf = null;
        }
      },
    },
  },
  async created() {
    this.working = true;
    this.getLocations();
    this.getMaterial();
    for (let i = 1; i < this.checkItems.length; i++) {
      this.checkItems[i].rule = this.requiredRule;
    }
  },
  methods: {
    async getLocations() {
      const q = query(collection(firestore, "locations"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      // Fetch user document first
      const docRef = doc(firestore, "users", localStorage.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists) {
        // Get the user's location array
        const userLocations = docSnap.data().locations || [];

        // Create a map for quick lookup of userLocations order
        const locationOrder = new Map();
        userLocations.forEach((locId, index) => {
          locationOrder.set(locId, index);
        });

        // Collect locations that match the userLocations and sort them by the order in userLocations
        this.locations = querySnapshot.docs
          .map((doc) => {
            return {
              name: doc.data().name,
              id: doc.data().id,
            };
          })
          .filter((data) => userLocations.includes(data.id))
          .sort((a, b) => locationOrder.get(a.id) - locationOrder.get(b.id));

        if (this.locations.length == 0) {
          this.locations = querySnapshot.docs.map((doc) => {
            return {
              name: doc.data().name,
              id: doc.data().id,
            };
          });
        }

        // Set the location field to the first location in the sorted list
        if (this.locations.length > 0) {
          this.location = this.locations[0].id;
        }
      }

      return;
    },
    async getMaterial() {
      const materialRef = collection(firestore, "materials");
      let q;

      q = await query(materialRef, orderBy("name"));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.metadata.fromCache) {
        this.connection = false;
      } else {
        for (let i = 0; i < querySnapshot.docs.length; i++) {
          const data = {
            name: querySnapshot.docs[i].data().name,
            description: querySnapshot.docs[i].data().description,
            id: querySnapshot.docs[i].data().id,
          };
          this.materials.push(data);
        }
      }
      this.getPlateSizes();
    },
    async getPlateSizes() {
      const q = query(collection(firestore, "plateSizes"), orderBy("name"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = {
          name: doc.data().name,
          id: doc.data().id,
          description: doc.data().description,
        };
        this.plateSizes.push(data);
      });
      this.working = false;
    },
    decrementUsed(i) {
      this.used[i].count--;
    },
    incrementUsed(i) {
      this.used[i].count++;
    },
    decrementGripper(i) {
      this.used[i].gripper--;
    },
    incrementGripper(i) {
      this.used[i].gripper++;
    },
    decrementWasted(i) {
      this.wasted[i].count--;
    },
    incrementWasted(i) {
      this.wasted[i].count++;
    },
    addMore(type) {
      if (type == "used") {
        this[type].push({ count: 0, id: null, gripper: null });
      } else {
        this[type].push({ count: 0, id: null });
      }
    },
    removeMaterial(type) {
      this[type].splice(-1);
    },
    async sendFromPlateout() {
      let plateDetails = await this.plateCheck();

      if (plateDetails) {
        let UVdetails = await this.UVCheck();

        if (UVdetails == null) {
          if (this.storeExist) {
            this.send();
          } else {
            toast.error("Film Details required");
          }
        } else if (UVdetails) {
          this.send();
        }
      } else if (plateDetails == null) {
        let UVdetails = await this.UVCheck();

        if (UVdetails == null) {
          if (this.plateOutExist && this.storeExist) {
            this.send();
          } else {
            toast.info("Nothing to Add");
          }
        } else if (UVdetails) {
          if (this.plateOutExist) {
            this.send();
          } else {
            toast.error("Film Details required");
          }
        }
      }
    },
    async saveToPlateout() {
      let plateDetails = await this.plateCheck();

      if (plateDetails) {
        let UVdetails = await this.UVCheck();

        if (UVdetails == null) {
          this.save();
        } else if (UVdetails) {
          this.save();
        }
      } else if (plateDetails == null) {
        let UVdetails = await this.UVCheck();

        if (UVdetails == null) {
          toast.info("Nothing to Add");
        } else if (UVdetails) {
          this.save();
        }
      }
    },
    async plateCheck() {
      if (this.used.length == 0 && this.wasted.length == 0) {
        return null;
      } else {
        let plateDetailsValid = await this.validatePlateout();
        if (plateDetailsValid) {
          let plateDetailesDuplicate = await this.duplicateCheck();
          if (plateDetailesDuplicate) {
            return true;
          } else {
            toast.error("Same material added twice\nඑකම ද්රව්යය දෙවරක් එකතු කර ඇත");
            return false;
          }
        } else {
          toast.error("Materials quantity cannot be zero\nද්‍රව්‍ය ගණන බිංදු විය නොහැක");
          return false;
        }
      }
    },
    async UVCheck() {
      let results = null;

      if (this.uvVarnishFlexo && !this.specialGold) {
        results = await this.spotUVCheck();
      } else if (!this.uvVarnishFlexo && this.specialGold) {
        results = await this.specialGoldCheck();
      } else if (!this.uvVarnishFlexo && !this.specialGold) {
        results = true;
      } else if (this.uvVarnishFlexo && this.specialGold) {
        let uv = await this.spotUVCheck();
        let gold = await this.specialGoldCheck();

        if (uv == null && gold == null) {
          results = null;
        } else if (uv == null) {
          toast.error("UV Film details required");
          results = false;
        } else if (gold == null) {
          toast.error("Gold Film details required");
          results = false;
        } else if (uv == true && gold == true) {
          results = true;
        } else {
          results = false;
        }
      }
      return results;
    },
    async plateForm() {
      if (this.used.length == 0 && this.wasted.length == 0) {
        return null;
      } else {
        return true;
      }
    },
    async validatePlateout() {
      // Check if any of the arrays have count 0
      const materialsZero =
        this.used.some((item) => item.count === 0) ||
        this.wasted.some((item) => item.count === 0);
      const { valid } = await this.$refs.formPlateout.validate();

      if (valid && materialsZero) {
        return false;
      } else if (valid && !materialsZero) {
        return true;
      }
    },
    duplicateCheck() {
      const usedFiltered = this.used.filter(
        (obj, index) => this.used.findIndex((item) => item.id === obj.id) === index
      );
      const wasteFiltered = this.wasted.filter(
        (obj, index) => this.wasted.findIndex((item) => item.id === obj.id) === index
      );

      if (
        usedFiltered.length == this.used.length &&
        wasteFiltered.length == this.wasted.length
      ) {
        return true;
      } else {
        return false;
      }
    },
    async spotUVCheck() {
      if (this.uvFilm == null) {
        return null;
      } else {
        let storeLocation = await this.filmLocationCheck(this.uvFilm);
        return storeLocation;
      }
    },
    async specialGoldCheck() {
      if (this.goldFilm == null) {
        return null;
      } else {
        let storeLocation = await this.filmLocationCheck(this.goldFilm);
        return storeLocation;
      }
    },
    filmLocationCheck(state) {
      if (state == "yes") {
        if (this.jobID == null) {
          toast.error("Job ID required\nJob අංකය අවශ්‍යයි");
          return false;
        } else if (this.rack == null) {
          toast.error("Rack number required\nරාක්ක අංකය අවශ්‍යයි");
          return false;
        } else if (this.shelf == null) {
          toast.error("Bag number required\nබෑග් අංකය අවශ්‍යයි");
          return false;
        } else {
          return true;
        }
      } else if (state == "no" || state == "blanket" || state == "screen") {
        if (this.jobID == null) {
          toast.error("Job ID required\nJob අංකය අවශ්‍යයි");
          return false;
        } else {
          return true;
        }
      }
    },
    async send() {
      if (this.working == false) {
        this.working = true;
        bus.emit("loadingState", true);
        this.dialogData.title = "Updating";
        this.dialogData.text = "Sending for Dispatch";
        this.dialogData.icon = "mdi-arrow-right-box";
        this.dialog = true;

        await addPlateout({
          push: true,
          location: this.location,
          saleOrder: this.jobObject.saleOrder,
          checkItems: this.checkItems,
          plateSize: this.plateSize,
          used: this.used,
          wasted: this.wasted,
          note: this.note,
          film: this.uvFilm,
          gold: this.goldFilm,
          jobID: this.jobID,
          rack: this.rack,
          shelf: this.shelf,
        }).then((result) => {
          bus.emit("loadingState", false);
          this.working = false;
          this.dialogData.title = result.data.response;
          this.dialogData.icon = result.data.icon;
          this.dialogData.text = result.data.message;
          this.dialogData.redirect = "/jobs/plateout";
          this.dialog = true;
        });
      }
    },
    async save() {
      if (this.working == false) {
        this.working = true;
        bus.emit("loadingState", true);
        this.dialogData.title = "Updating";
        this.dialogData.text = "Adding plate details";
        this.dialogData.icon = "mdi-note-plus";
        this.dialog = true;

        await addPlateout({
          push: false,
          location: this.location,
          saleOrder: this.jobObject.saleOrder,
          checkItems: this.checkItems,
          plateSize: this.plateSize,
          used: this.used,
          wasted: this.wasted,
          note: this.note,
          film: this.uvFilm,
          gold: this.goldFilm,
          jobID: this.jobID,
          rack: this.rack,
          shelf: this.shelf,
        }).then((result) => {
          bus.emit("loadingState", false);
          this.working = false;
          this.dialogData.title = result.data.response;
          this.dialogData.icon = result.data.icon;
          this.dialogData.text = result.data.message;
          this.dialogData.redirect = "/jobs/plateout";
          this.dialog = true;
        });
      }
    },
    async sendToCTP() {
      if (this.working == false) {
        this.working = true;
        bus.emit("loadingState", true);
        this.dialogData.title = "Updating";
        this.dialogData.text = "Sending back to CTP";
        this.dialogData.icon = "mdi-backburger";
        this.dialog = true;

        await sendBack({
          saleOrder: this.jobObject.saleOrder,
        }).then((result) => {
          bus.emit("loadingState", false);
          this.working = false;
          this.dialogData.title = result.data.response;
          this.dialogData.icon = result.data.icon;
          this.dialogData.text = result.data.message;
          this.dialogData.redirect = "/jobs/ctp";
          this.dialog = true;
        });
      }
    },
  },
};
</script>

<style scoped>
.customBackground {
  background-color: rgb(var(--v-theme-action));
}
</style>
