import {
  createRouter,
  createWebHistory,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
} from "vue-router";
import { getCurrentUser } from "@/firebase";

import PDF from "../views/pdf";

import Login from "../views/login/login";
import Dashboard from "../views/dashboard/dashboard";
import JobSearch from "../views/jobs/search";
import Password from "../views/login/password";
import MyAccount from "../views/users/myAccount";

import AutomationInvoice from "../views/automation/invoice";
import AutomationReports from "../views/automation/reports";

import Scanner from "../views/scanner/scan";
import Logs from "../views/logs";
import Page404 from "../views/404";

import OffsetCreate from "../views/jobs/offset/create";
import OffsetWorkTicket from "../views/jobs/offset/workticket";

import Artworks from "../views/jobs/list";
import OffsetListRepro from "../views/jobs/list";
import OffsetListOSL from "../views/jobs/list";
import OffsetListVPS from "../views/jobs/list";
import OffsetListCTP from "../views/jobs/list";
import OffsetListPlateout from "../views/jobs/list";
import OffsetListDispatch from "../views/jobs/list";
import OffsetListCompleted from "../views/jobs/list";

import Artwork from "../views/jobs/artwork";
import ItemCheckList from "../views/jobs/itemsChecklist";

import OffsetChecklistRepro from "../views/jobs/checklist";
import OffsetChecklistOSL from "../views/jobs/checklist";
import OffsetChecklistVPS from "../views/jobs/checklist";
import OffsetChecklistCTP from "../views/jobs/checklist";
import OffsetChecklistPlateout from "../views/jobs/checklist";
import OffsetChecklistDispatch from "../views/jobs/checklist";
import OffsetChecklistCompleted from "../views/jobs/checklist";

import Flexo from "../views/jobs/flexo/view";
import FlexoCreate from "../views/jobs/flexo/create";

import Plates from "../views/inventory/plates";
import PlatesLog from "../views/inventory/logs";
import Films from "../views/inventory/films";

import ViewUser from "../views/users/view";
import CreateUser from "../views/users/createEdit";
import EditUser from "../views/users/createEdit";
import Customers from "../views/variables/customers";
import bulkUpload from "../views/variables/bulkUpload";
import Agents from "../views/variables/variables";
import Factories from "../views/variables/variables";
import Locations from "../views/variables/variables";
import Materials from "../views/variables/variables";
import Machines from "../views/variables/variables";
import Types from "../views/variables/variables";
import PlateSizes from "../views/variables/variables";

import Charges from "../views/billing/artwork/charges";
import ChargesGenerated from "../views/billing/artwork/generated";
import ChargesView from "../views/billing/artwork/view";
import CreateSales from "../views/billing/plates/create";
import ApproveSales from "../views/billing/plates/approve";
import SentSales from "../views/billing/plates/sent";
import UploadPurchase from "../views/procurement/invoice/upload";
import VerifyPurchase from "../views/procurement/invoice/verify";
import ApprovePurchase from "../views/procurement/invoice/approve";
import SentPurchase from "../views/procurement/invoice/sent";

import SendEmail from "../views/email/send";
import Outbox from "../views/email/outbox";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pdf",
    name: "PDF",
    component: PDF,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/myaccount",
    name: "My Account",
    component: MyAccount,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/changepassword",
    name: "Change Password",
    component: Password,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/create",
    name: "Create Offset Jobs",
    component: OffsetCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/worktickets",
    name: "Work Tickets",
    component: OffsetWorkTicket,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/scanner",
    name: "Scanner",
    component: Scanner,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/outbox",
    name: "Outbox",
    component: Outbox,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/search/:searchTerm",
    name: "Search Results",
    component: JobSearch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/email/send",
    name: "Compose Email",
    component: SendEmail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/artworks",
    name: "Artworks",
    component: Artworks,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/artworks/:jobID",
    name: "Artwork Details",
    component: Artwork,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/artworks/:jobID/item/:itemID",
    name: "Item Details",
    component: ItemCheckList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/repro",
    name: "Repro",
    component: OffsetListRepro,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/repro/checklist/:jobID",
    name: "Repro Checklist",
    component: OffsetChecklistRepro,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/osl",
    name: "Outsourced items",
    component: OffsetListOSL,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/osl/checklist/:jobID",
    name: "Outsourced items Checklist",
    component: OffsetChecklistOSL,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/vps",
    name: "Imposition/VPS",
    component: OffsetListVPS,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/vps/checklist/:jobID",
    name: "Imposition/VPS Checklist",
    component: OffsetChecklistVPS,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/ctp",
    name: "CtP",
    component: OffsetListCTP,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/ctp/checklist/:jobID",
    name: "CtP Checklist",
    component: OffsetChecklistCTP,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/plateout",
    name: "Plateout",
    component: OffsetListPlateout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/plateout/checklist/:jobID",
    name: "Plateout Checklist",
    component: OffsetChecklistPlateout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/dispatch",
    name: "Dispatch",
    component: OffsetListDispatch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/dispatch/checklist/:jobID",
    name: "Dispatch Checklist",
    component: OffsetChecklistDispatch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/completed",
    name: "Completed Jobs",
    component: OffsetListCompleted,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/completed/checklist/:jobID",
    name: "Completed Summary",
    component: OffsetChecklistCompleted,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/flexo",
    name: "Flexo Jobs",
    component: Flexo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/create/flexo",
    name: "Create Flexo Job",
    component: FlexoCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inventory/plates",
    name: "Plates Inventory",
    component: Plates,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inventory/plates/log/:location/:material",
    name: "Plates Inventory Log",
    component: PlatesLog,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inventory/films",
    name: "Films Inventory",
    component: Films,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: ViewUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/create",
    name: "Create User",
    component: CreateUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/edit/:userid",
    name: "Edit User",
    component: EditUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bulkupload",
    name: "Customers Bulk upload",
    component: bulkUpload,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agents",
    name: "Agents",
    component: Agents,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/factories",
    name: "Factories / Plants",
    component: Factories,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/locations",
    name: "CtP Locations",
    component: Locations,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/materials",
    name: "Materials",
    component: Materials,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/machines",
    name: "Print Machines",
    component: Machines,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/types",
    name: "Job Types",
    component: Types,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/plateSizes",
    name: "Plate Sizes",
    component: PlateSizes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs",
    name: "Logs",
    component: Logs,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/artwork/charges",
    name: "Prepress Charges",
    component: Charges,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/artwork/generated",
    name: "Generated Reports",
    component: ChargesGenerated,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/artwork/generated/:reportID",
    name: "View Reports",
    component: ChargesView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/automation/invoice",
    name: "Invoice",
    component: AutomationInvoice,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/automation/reports",
    name: "Reports",
    component: AutomationReports,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/plates/create",
    name: "Create Plate Invoices",
    component: CreateSales,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/plates/approve",
    name: "Approve Plate Invoices",
    component: ApproveSales,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/plates/sent",
    name: "Sent Plate Invoices",
    component: SentSales,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/procurement/invoice/upload",
    name: "Upload Invoices",
    component: UploadPurchase,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/procurement/invoice/verify",
    name: "Verify Invoices",
    component: VerifyPurchase,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/procurement/invoice/approve",
    name: "Approve Invoices",
    component: ApprovePurchase,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/procurement/invoice/sent",
    name: "Approved Invoices",
    component: SentPurchase,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)",
    name: "Page not found",
    component: Page404,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Nav Guard
router.beforeEach(async (to, from, next) => {
  const currUser = await getCurrentUser();
  // Check for requiresAuth guard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if NO logged user
    if (currUser == null) {
      // Go to login
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      // Proceed to route
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    // Check if NO logged user
    if (currUser != null) {
      // Go to login
      next({
        path: "/",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      // Proceed to route
      next();
    }
  } else {
    // Proceed to route
    next();
  }
});

export default router;
