<template>
  <v-data-table
    :headers="headers"
    :items="emails"
    :loading="loading"
    :server-items-length="totalEmails"
    :items-per-page="pageSize"
    :items-per-page-options="[20, 50, 100]"
    @update:page="loadMore"
    :mobile="this.$vuetify.display.mobile"
  >
    <template v-slot:[`item.delivery.startTime`]="{ item }">
      {{ formatDateWithTime(item.delivery.startTime.__time__) }}
    </template>
    <template v-slot:[`item.delivery.endTime`]="{ item }">
      {{
        item.delivery.state === "ERROR"
          ? "N/A"
          : formatDateWithTime(item.delivery.endTime?.__time__)
      }}
    </template>
    <template v-slot:[`item.delivery.state`]="{ item }">
      <v-chip :color="chipColor(item.delivery.state)" dark>
        {{ item.delivery.state }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import bus from "vue3-eventbus";
import { firestore } from "@/firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import moment from "moment";

export default {
  data() {
    return {
      emails: [],
      lastDoc: null,
      loading: false,
      totalEmails: 0,
      pageSize: 14,
      allLoaded: false,
      isInitialLoad: true,
      headers: [
        { title: "To", value: "to" },
        { title: "Subject", value: "message.subject" },
        { title: "Delivery Start", value: "created" },
        { title: "Delivery End", value: "delivered" },
        { title: "State", value: "delivery.state" },
      ],
    };
  },
  created() {
    bus.emit("loadingState", true);
    this.loadEmails();
  },
  methods: {
    chipColor(status) {
      if (status == "SUCCESS") {
        return "success";
      } else if (status == "PROCESSING") {
        return "warn";
      } else if (status == "ERROR") {
        return "error";
      } else {
        return "error";
      }
    },
    formatDateWithTime(timeStamp) {
      return moment(timeStamp).format("DD-MM-YYYY - HH:MM a");
    },
    async loadEmails() {
      if (this.allLoaded) return; // Stop if all documents are loaded

      this.loading = true;
      try {
        // Determine the limit based on whether it's the initial load
        const itemsLimit = this.isInitialLoad ? this.pageSize * 5 : this.pageSize;

        let q = query(
          collection(firestore, "outbox"),
          orderBy("delivery.startTime", "desc"),
          limit(itemsLimit)
        );

        if (this.lastDoc) {
          q = query(q, startAfter(this.lastDoc));
        }

        const querySnapshot = await getDocs(q);

        if (querySnapshot.size < itemsLimit) {
          this.allLoaded = true; // Set the flag if fewer documents are returned than requested
        }

        querySnapshot.forEach((doc) => {
          const data = doc.data();

          this.emails.push({
            ...data,
            created: this.formatDateWithTime(data.delivery.startTime.toDate()),
            delivered:
              data.delivery.endTime == undefined
                ? ""
                : this.formatDateWithTime(data.delivery.endTime.toDate()),
          });
        });

        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        // Switch to the normal load size after the initial load
        if (this.isInitialLoad) {
          this.isInitialLoad = false;
        }

        // Optional: Get total count of documents if needed
        this.totalEmails += querySnapshot.size;
      } catch (error) {
        console.error("Error loading emails:", error);
      } finally {
        this.loading = false;
        bus.emit("loadingState", false);
      }
    },
    loadMore() {
      if (!this.loading && !this.allLoaded) {
        this.loadEmails();
        bus.emit("loadingState", true);
      }
    },
  },
};
</script>
