<template>
    <v-card v-if="stageObject.stage == 'hold'" class="mt-4" color="action">
        <v-row justify="center" class="mt-4">
            <v-col cols="12" lg="5" xl="5">
                <div class="text-center">
                    <v-icon class="mb-2" color="error" icon="mdi-alert-octagon" size="64"></v-icon>
                    <div class="text-h6 font-weight-bold mx-16">JOB ON HOLD</div>
                </div>
            </v-col>
            <v-col cols="12" lg="5" xl="5">
                <div class="text-center">{{ stageObject.reason }}</div>
            </v-col>
        </v-row>
        <UserTimeStrip :stageObject="stageObject" />
    </v-card>
    <v-card v-if="stageObject.stage == 'unhold'" class="mt-4" color="action">
        <v-row justify="center" class="mt-4">
            <v-col cols="12" lg="5" xl="5">
                <div class="text-center">
                    <v-icon class="mb-2" color="success" icon="mdi-play-box" size="64"></v-icon>
                    <div class="text-h6 font-weight-bold mx-16">JOB RELEASED</div>
                </div>
            </v-col>
            <v-col cols="12" lg="5" xl="5">
                <div class="text-center">{{ stageObject.reason }}</div>
            </v-col>
        </v-row>
        <UserTimeStrip :stageObject="stageObject" />
    </v-card>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";
export default {
    props: ["stageObject"],
    components: {
        UserTimeStrip
    },
}
</script>

<style></style>
/*
<v-toolbar flat color="action" title="HOLD"></v-toolbar>