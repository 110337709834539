<template>
  <v-form class="mt-4" ref="formItem" :disabled="working">
    <v-row justify="center" align="center">
      <v-col cols="12" lg="12" xl="10">
        <v-card :class="{ hidden: !showCard, 'zoom-in': zoomInAnimation }" color="cards">
          <v-toolbar
            flat
            color="navBar"
            :title="'Add Item - ' + nextItemNumber"
          ></v-toolbar>
          <!---------------------------------------------------------------------------------------------------- ADD ITEM ----->
          <v-row justify="center" align="center" class="ma-4">
            <v-col cols="12" md="5">
              <v-combobox
                multiple
                label="Structual ID"
                v-model="structualID"
                :rules="structualIDRules"
              >
                <template v-slot:selection="data">
                  <v-chip :selected="data.selected" class="chip-class" size="large">
                    {{ data.item.title }}
                  </v-chip>
                </template></v-combobox
              >
            </v-col>
            <v-col cols="12" md="7">
              <v-textarea
                label="Description"
                auto-grow
                rows="1"
                counter="260"
                :rules="requiredRule"
                v-model="description"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="px-4"
              append-icon="mdi-image-plus"
              variant="flat"
              :loading="working"
              color="primary"
              @click="validate"
            >
              Create Item
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!showCard" justify="space-around" align="center" no-gutters>
      <v-col cols="12" md="4">
        <v-btn
          class="mt-4 mb-16"
          @click="showAddItem"
          append-icon="mdi-table-arrow-up"
          variant="tonal"
          color="primary"
          block
        >
          Add Item
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import bus from "vue3-eventbus";

import { firestore, addItem } from "@/firebase";
export default {
  props: ["jobObject"],
  data: () => ({
    working: false,
    showCard: false,
    zoomInAnimation: false,
    nextItemNumber: "0000",
    structualID: [],
    description: "",
    requiredRule: [(v) => !!v || "This is required"],
  }),
  computed: {
    structualIDRules() {
      return [
        (value) =>
          value.every((v) => v.length >= 4 && v.length <= 6) ||
          "All numbers must be 6 characters long.",
      ];
    },
  },
  created() {
    this.nextItemNumber = (this.jobObject.items + 1).toString().padStart(2, "0");
  },
  methods: {
    showAddItem() {
      this.showCard = true;
      this.zoomInAnimation = true;
      setTimeout(() => {
        this.zoomInAnimation = false;
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      }, 500); // duration of the animation
    },
    async validate() {
      const { valid } = await this.$refs.formItem.validate();
      if (valid) this.addStageFun();
    },
    async addStageFun() {
      try {
        this.working = true;
        bus.emit("loadingState", true);

        await addItem({
          jobID: this.jobObject.jobID,
          structualID: this.structualID,
          description: this.description,
        }).then((result) => {
          this.working = false;
          this.showCard = false;
          bus.emit("reload", "reload");
          bus.emit("loadingState", false);
        });
      } catch (error) {
        this.handleError(error);
      }
    },
    handleError(error) {
      this.dialogData = {
        title: "error",
        icon: "mdi-close-circle-outline",
        text: error,
        redirect: "/",
      };
      this.dialog = true;
    },
  },
};
</script>

<style>
@keyframes zoomIn {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.hidden {
  display: none;
}

.zoom-in {
  animation: zoomIn 0.5s ease;
  display: block;
  /* Ensure the element is shown when the animation is applied */
}
</style>
