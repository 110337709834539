<template>
    <ComPDFViewer :pdfUrl="selectRandomUrl" />
</template>

<script>
import ComPDFViewer from '@/components/common/pdf/comPDFViewer';
export default {
    data() {
        return {
            urls: [
                "https://firebasestorage.googleapis.com/v0/b/ppm-workflow-management.appspot.com/o/Temp%2FUPVC%20SOLVENT%20CEMENT%20LABELS%202.pdf?alt=media&token=6cb9d2a8-215b-4064-bd00-f433179fc744",
                "https://firebasestorage.googleapis.com/v0/b/ppm-workflow-management.appspot.com/o/Temp%2FST01%20Biscuit%20Tin.pdf?alt=media&token=d1cc50f9-9b6f-4fe1-8669-520fafd404cf",
                "https://firebasestorage.googleapis.com/v0/b/ppm-workflow-management.appspot.com/o/Temp%2F69110V1.2738_MixTee_Christmas_Ass_40E-01.pdf?alt=media&token=d1f494eb-4118-4344-8133-f54245d132da",
                "https://firebasestorage.googleapis.com/v0/b/ppm-workflow-management.appspot.com/o/Temp%2FCS.QXR8251U.pdf?alt=media&token=32ab0fec-d9a9-4cc4-949e-1b22f187e657",
            ],
        }
    },
    components: {
        ComPDFViewer
    },

    computed: {
        selectRandomUrl() {
            const randomIndex = Math.floor(Math.random() * this.urls.length);
            return this.urls[randomIndex];
        },
    },

}
</script>

<style></style>