<template>
  <v-row justify="start">
    <v-col cols="12" md="7">
      <v-row justify="space-between">
        <v-col cols="auto">
          <v-btn class="mb-2" color="error" @click="deleteInvoices" variant="tonal">
            Delete
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="btnVisible">
          <v-btn class="mb-2" color="primary" @click="sendInvoices">
            Sign and Send
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="invoices"
        v-model:page="page"
        :loading="loading"
        item-key="docID"
        item-value="docID"
        class="elevation-1"
        @page-count="pageCount = $event"
        :items-per-page-options="[10, 20, 25, 50]"
        @click:row="onRowClick"
        show-select
        v-model="selectedInvoices"
        @update:page="loadMore"
        :mobile="this.$vuetify.display.mobile"
      />
    </v-col>
    <v-col cols="12" md="5" class="image-container">
      <div class="image-wrapper">
        <v-progress-circular
          v-if="imageLoading"
          indeterminate
          color="primary"
          class="spinner"
          size="100"
          :width="10"
        />
        <img
          v-if="imageUrl"
          :src="imageUrl"
          alt="Invoice Image"
          @click="showLightbox = true"
          class="clickable-image"
        />
        <vue-easy-lightbox
          :visible="showLightbox"
          :imgs="[imageUrl]"
          @hide="showLightbox = false"
        />
      </div>
    </v-col>
    <v-dialog v-model="deleting" persistent>
      <v-card color="cards" class="mx-auto">
        <Vue3Lottie
          class="pt-10"
          :animationData="Delete"
          :height="200"
          :width="300"
          :speed="1"
        />
        <v-card-text class="text-h5 font-weight-bold text-center">
          Deleting invoices
        </v-card-text>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sending" persistent>
      <v-card color="cards" class="mx-auto">
        <Vue3Lottie
          class="pt-10"
          :animationData="Signature"
          :height="200"
          :width="300"
          :speed="1"
        />
        <v-card-text class="text-h5 font-weight-bold text-center">
          Signing and sending invoices
        </v-card-text>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </v-card>
    </v-dialog>
  </v-row>
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" @close="dialog = false" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import VueEasyLightbox from "vue-easy-lightbox";
import moment from "moment";
import DialogModal from "@/components/common/dialogModal";
import Signature from "@/assets/animjson/signature.json";
import Delete from "@/assets/animjson/delete.json";
import { firestore, storage, approvedInvoice, deleteInvoice } from "@/firebase";
import { query, where, orderBy, startAfter, limit } from "firebase/firestore";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";

export default {
  data() {
    return {
      userRole: localStorage.userRole,
      invoices: [],
      selectedInvoices: [],
      headers: [
        { title: "Created", value: "created" },
        { title: "Filename/Invoice No", value: "filename" },
        { title: "Customer", value: "customer" },
        { title: "Created By", value: "createdBy" },
      ],
      page: 1,
      pageCount: 0,
      loading: false,
      imageLoading: false,
      imageUrl: null,
      showLightbox: false,
      lastDoc: null,
      noMore: false,
      cache: {},
      dialog: false,
      dialogData: {},
      sending: false,
      deleting: false,
      Signature,
      Delete,
    };
  },
  components: {
    VueEasyLightbox,
    DialogModal,
  },
  computed: {
    totalSelectedSize() {
      return this.selectedInvoices.reduce((total, docID) => {
        const invoice = this.invoices.find((inv) => inv.docID === docID);
        return invoice && !isNaN(invoice.fileSize) ? total + invoice.fileSize : total;
      }, 0);
    },
    maxSizeReached() {
      return this.totalSelectedSize >= 20 * 1024 * 1024; // 20 MB limit
    },
    formattedTotalSelectedSize() {
      if (this.totalSelectedSize >= 1024 * 1024) {
        return (this.totalSelectedSize / (1024 * 1024)).toFixed(2) + " MB";
      } else if (this.totalSelectedSize >= 1024) {
        return (this.totalSelectedSize / 1024).toFixed(2) + " KB";
      } else {
        return this.totalSelectedSize + " bytes";
      }
    },
  },
  async mounted() {
    await this.fetchInvoices();
  },
  created() {
    this.userRole = localStorage.userRole;
    this.btnVisible = ["Administrator", "Manager"].includes(this.userRole);
  },
  methods: {
    async fetchInvoices() {
      if (this.noMore) return;

      this.loading = true;
      bus.emit("loadingState", true);

      let q = query(
        collection(firestore, "invoices"),
        where("state", "==", "uploaded"),
        orderBy("created", "desc"),
        limit(300)
      );

      if (this.lastDoc) {
        q = query(q, startAfter(this.lastDoc));
      }

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        this.noMore = true;
      } else {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const newInvoices = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const createdByName = await this.getName(data.createdBy, "users");
            const customerName = await this.getCustomer(data.customer);
            const fileSize = parseInt(data.fileSize);

            return {
              docID: doc.id,
              ...data,
              created: this.formatDateWithTime(data.created.toDate()),
              customer: customerName,
              createdBy: createdByName,
              fileSize: isNaN(fileSize) ? 0 : fileSize,
            };
          })
        );

        this.invoices = [...this.invoices, ...newInvoices];
      }

      this.loading = false;
      bus.emit("loadingState", false);
    },
    async onRowClick(_, row) {
      this.imageLoading = true;
      this.imageUrl = null;
      try {
        const imageRef = ref(storage, row.item.uploadedURL);
        this.imageUrl = await getDownloadURL(imageRef);
      } catch (error) {
        console.error("Error fetching image URL:", error);
      } finally {
        this.imageLoading = false;
      }
    },
    async sendInvoices() {
      if (this.maxSizeReached) {
        this.showDialog(
          "Error",
          "mdi-file-import",
          "The total size of " +
            this.formattedTotalSelectedSize +
            " selected invoices exceeds 20MB. Please reduce your selection.",
          "close"
        );
        return;
      }

      try {
        this.sending = true;

        const result = await approvedInvoice({
          selectedInvoices: this.selectedInvoices,
        });

        if (result.data.response === "success") {
          this.showDialog(
            "success",
            "mdi-check-circle-outline",
            "Invoices approved successfully.",
            "close"
          );
          this.invoices = [];
          (this.selectedInvoices = []), (this.lastDoc = null);
          this.noMore = false;
          this.page = 1;
          this.pageCount = 0;
          this.imageUrl = null;
          await this.fetchInvoices();
        } else {
          this.showDialog(
            "error",
            result.data.icon,
            result.data.message || "An error occurred while approving invoices.",
            "close"
          );
        }
      } catch (error) {
        console.error("Error during approval process:", error);
        this.showDialog(
          "error",
          "mdi-close-circle-outline",
          "An unexpected error occurred. Please try again.",
          "close"
        );
      }
    },
    async deleteInvoices() {
      try {
        this.deleting = true;

        const result = await deleteInvoice({
          invoiceType: "invoices",
          selectedInvoices: this.selectedInvoices,
        });

        if (result.data.response === "success") {
          this.showDialog(
            "success",
            "mdi-check-circle-outline",
            result.data.message,
            "close"
          );
          this.invoices = [];
          (this.selectedInvoices = []), (this.lastDoc = null);
          this.noMore = false;
          this.page = 1;
          this.pageCount = 0;
          this.imageUrl = null;
          await this.fetchInvoices();
        } else {
          this.showDialog(
            "error",
            result.data.icon,
            result.data.message || "An error occurred while approving invoices.",
            "close"
          );
        }
      } catch (error) {
        console.error("Error during approval process:", error);
        this.showDialog(
          "error",
          "mdi-close-circle-outline",
          "An unexpected error occurred. Please try again.",
          "close"
        );
      }
    },
    async getName(document, collection) {
      if (!document) return " - ";

      if (this.cache[document]) return this.cache[document];

      const docRef = doc(firestore, collection, document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) return " - ";

        this.cache[document] = docSnap.data().name;
        return this.cache[document];
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      }
    },
    async getCustomer(document) {
      if (!document) return " - ";

      if (this.cache[document]) return this.cache[document];

      const docRef = doc(firestore, "automation", "invoice", "customers", document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) return " - ";

        this.cache[document] = docSnap.data().name;
        return this.cache[document];
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      }
    },
    showDialog(title, icon, message, action) {
      this.sending = false;
      this.deleting = false;
      this.dialog = false;
      this.dialogData = {
        title: title,
        icon: icon,
        text: message,
        redirect: action,
      };
      this.dialog = true;
    },
    formatDateWithTime(timeStamp) {
      return moment(timeStamp).format("DD-MM-YYYY - hh:mm:ss a");
    },
    loadMore() {
      if (this.page >= this.pageCount - 1) {
        this.fetchInvoices();
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84vh;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clickable-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
  display: block;
  margin: auto;
}
</style>
