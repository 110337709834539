<template>
  <v-row justify="center" class="pt-2">
    <v-col cols="auto">
      <div class="text-caption py-2">
        {{ stageObject.user }} {{ stageObject.text }} ----------------
        {{ stageObject.time }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["stageObject"],
  components: {},
};
</script>

<style></style>
