<template>
  <v-card class="mt-4" color="cards">
    <v-toolbar flat density="compact" color="navBar" title="REPRO" />
    <v-row justify="space-between" align="center" class="ma-3" no-gutters>
      <v-col cols="12" md="3">
        <div class="text-center">
          <div class="text-h6 font-weight-bold">
            <v-icon
              color="success"
              icon="mdi-checkbox-marked-circle-outline"
              size="64"
            ></v-icon
            >REPRO PASSED
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <v-row justify="center" align="center" no-gutter>
          <v-col cols="12" md="auto">
            <div class="text-center">
              <div class="text-h6 font-weight-light">Repro charges</div>
            </div>
          </v-col>
          <v-col cols="12" md="auto">
            <div class="text-center">
              <div class="text-h4 font-weight-light">{{ stageObject.mins }} mins</div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <UserTimeStrip :stageObject="stageObject" />
  </v-card>
</template>

<script>
import UserTimeStrip from "@/components/job/userTimeSrip";

export default {
  props: ["stageObject"],
  components: {
    UserTimeStrip,
  },
};
</script>

<style></style>
