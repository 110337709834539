<template>
  <v-hover v-slot="{ isHovering, props }">
    <div :style="isHovering ? 'opacity: 0.8;' : 'opacity: 1;'" v-bind="props">
      <v-alert
        class="mt-4"
        :color="stageObject.merged ? 'navBar' : 'cards'"
        border
        @click="gotoItem"
      >
        <v-row justify="space-between" align="center">
          <v-col cols="12" sm="12" lg="4">
            <div class="text-h4" style="opacity: 0.5">
              <v-icon
                size="35"
                :icon="stageObject.merged ? 'mdi-lock' : 'mdi-lock-open-variant-outline'"
              ></v-icon>
              ITEM {{ stageObject.itemCode.toString().padStart(2, "0") }}
            </div>
          </v-col>
          <v-col cols="12" sm="12" lg="4">
            <p class="text-body-2"><strong>Sales Order: </strong>{{ stageObject.SO }}</p>
            <p class="text-body-2">
              <strong>Structual ID: </strong>{{ stageObject.structualID }}
            </p>
            <p class="text-body-2">
              <strong>Description: </strong>{{ stageObject.description }}
            </p>
          </v-col>
          <v-col cols="12" sm="12" lg="4" class="text-right">
            <br />
            <p class="text-caption">{{ stageObject.user }}</p>
            <p class="text-caption">{{ stageObject.time }}</p>
          </v-col>
        </v-row>
      </v-alert>
    </div>
  </v-hover>
</template>

<script>
import router from "@/router";
export default {
  props: ["stageObject"],
  methods: {
    gotoItem() {
      router.push(this.$route.path + "/item/" + this.stageObject.docID);
    },
  },
};
</script>

<style></style>
