<template>
  <v-container class="background" fill-height fluid :style="{ backgroundImage: 'url(' + backgroundImgUrl + ')' }">
    <v-row align="center" justify="end" class="fullPage">
      <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="4">
        <v-card class="mx-2 pa-5 rounded-xl">
          <v-row>
            <v-col>
              <v-img :width="300" aspect-ratio="16/9" cover src="~@/assets/ppmlogo.png"></v-img>
              <div class="button">PRINTCARE PREMEDIA SERVICE</div>
              <h2>Workflow Management System</h2>
              <v-form v-model="form" @submit.prevent="onSubmit">
                <v-text-field class="pt-4" density="compact" variant="solo-filled" rounded flat v-model="email"
                  :readonly="loading" :rules="[required]" label="Email" type="email" color="primary" />
                <v-text-field density="compact" variant="solo-filled" rounded flat v-model="password"
                  :readonly="loading" :rules="[required]" label="Password" type="password" color="primary" />
                <v-btn variant="text" block class="text-overline text-decoration-none text-right text-primary"
                  @click="Modal = true">
                  Forgot password?</v-btn>
                <v-btn size="large" block rounded :disabled="!form" :loading="loading" type="submit"
                  color="primary">Login</v-btn>
                <p class="pt-4 text-center text-red-lighten-1">
                  <strong>{{ errorTxt }}</strong>
                </p>
              </v-form>
            </v-col>
          </v-row>
        </v-card></v-col><v-col cols="1" xs="0" sm="0" md="0" lg="1" xl="1"></v-col>
    </v-row>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="Modal">
      <v-form ref="form" :disabled="working">
        <v-card class="mx-auto" elevation="16" min-width="600" max-width="1000" title="Forgot Password" color="cards">
          <v-divider class="my-3"></v-divider>
          <v-text-field class="pt-4 px-2" density="compact" variant="solo-filled" rounded flat v-model="email"
            :readonly="loading" :rules="[required]" label="Email" type="email" color="primary" />
          <v-divider></v-divider>
          <div class="pa-4 text-end">
            <v-btn class="ml-4 text-none" color="success" min-width="92" rounded variant="flat"
              @click="sendPasswordReset">
              Send password request Email
            </v-btn>
          </div>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog" persistent>
      <v-card class="mx-auto" elevation="16" min-width="300" max-width="600" :title="dialogData.title" color="cards">
        <v-divider class="mt-3"></v-divider>
        <div class="py-12 text-center">
          <v-icon class="mb-6" :color="dialogData.title" :icon="dialogData.icon" size="128"></v-icon>
          <div class="text-h4 font-weight-bold mx-16">{{ dialogData.text }}</div>
        </div>
        <v-divider></v-divider>
        <div class="pa-4 text-end">
          <v-btn class="text-none" :color="dialogData.title" min-width="92" :loading="working" rounded variant="flat"
            @click="dialog = false">
            Okay
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { login as firebaseLogin } from "@/firebase";
import { firestore, passwordReset } from "@/firebase";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";

export default {
  name: "login",
  data: () => ({
    working: false,
    backgroundImgUrl: "",
    form: false,
    email: null,
    password: null,
    loading: false,
    errorTxt: "",
    Modal: false,
    dialog: false,
    dialogData: [{
      title: "",
      icon: "",
      text: ""
    }],
    locked: false,
  }),
  mounted() {
    const apiKey = "34464713-d6983b6f7516f75f27cdcbb6b";
    axios
      .get(`https://pixabay.com/api/?key=${apiKey}&q=pantone&image_type=photo`)
      .then((response) => {
        const images = response.data.hits;
        const randomIndex = Math.floor(Math.random() * images.length);
        this.backgroundImgUrl = images[randomIndex].largeImageURL;
      });
  },
  methods: {
    required(v) {
      return !!v || "Field is required";
    },
    async onSubmit() {
      if (!this.form) return;
      this.loading = true;
      this.errorTxt = "";

      await firebaseLogin(this.email, this.password)
        .then(async (userCredential) => {

          const docRef = doc(firestore, "users", userCredential.user.uid);
          const docSnap = await getDoc(docRef);
          const docData = docSnap.data();
          localStorage.email = docData.email;
          localStorage.userRole = docData.role;
          localStorage.userName = docData.name;
          // Signed in
          // ...

        }).then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          this.errorTxt = errorMessage.split('/')[1].slice(0, -2);
          this.loading = false;
        });
    },
    async sendPasswordReset() {
      if (this.locked == false) {
        this.Modal = false;
        this.locked = true;
        this.working = true;
        this.dialogData.title = "Password Reset";
        this.dialogData.text = "Sending password reset email";
        this.dialogData.icon = "mdi-email-newsletter";
        this.dialog = true;

        try {
          await passwordReset({
            email: this.email,
          }).then((result) => {
            this.working = false;
            this.dialog = false;
            this.dialogData.title = result.data.response;
            this.dialogData.icon = result.data.icon;
            this.dialogData.text = result.data.message;
            this.dialog = true;
          });
        } catch (error) {
          console.error(error);
          this.working = false;
          this.dialogData.title = "error";
          this.dialogData.icon = "mdi-close-circle-outline";
          this.dialogData.text = error;
          this.dialog = true;
        }
        this.delayedFunction();
      } else {
        this.working = false;
        this.Modal = false;
        this.dialogData.title = "error";
        this.dialogData.icon = "mdi-close-circle-outline";
        this.dialogData.text = "Please wait 10 seconds before trying again";
        this.dialog = true;
      }
    },
    delayedFunction() {
      setTimeout(() => {
        this.locked = false;
      }, 30000); // Delay of 10 seconds
    }
  },
};
</script>

<style scoped>
.background {
  background-color: rgb(0, 0, 0, 0.6);
  /* Tint color */
  background-blend-mode: darken;
  /* background-image: linear-gradient(to right, #2abb9c , #138e93);*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fullPage {
  width: 100vw;
  height: 99.1vh;
}
</style>
