<template>
  <v-card class="mt-4" color="plateslip">
    <v-row justify="start" align="center" no-gutters>
      <v-col cols="auto" md="3" class="ml-3 mt-3">
        <div class="text-subtitle-1">Plate Slip: {{ stageObject.plateSlip }}</div>
        <div class="text-body-2">Reason: {{ stageObject.reason }}</div>
      </v-col>
      <v-col cols="auto">
        <v-row justify="center" align="center" no-gutters>
          <v-col
            v-for="plate in platesWithColors"
            :key="plate.name"
            cols="auto"
            class="d-flex align-center"
          >
            <v-chip :color="plate.color" class="ma-2" label variant="flat">
              {{ plate.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" variant="flat">ADD PO Number</v-btn>
    </v-card-actions>
    <UserTimeStrip :stageObject="stageObject" />
  </v-card>
</template>

<script>
import bus from "vue3-eventbus";
import UserTimeStrip from "@/components/job/userTimeSrip";
export default {
  props: ["stageObject"],
  components: {
    UserTimeStrip,
  },
  computed: {
    platesWithColors() {
      const colorMapping = {
        Cyan: "cyan",
        Yellow: "yellow",
        Magenta: "purple",
        Black: "black",
        // Add any other specific mappings here
      };

      return this.stageObject.selectedPlates.map((plate) => ({
        name: plate,
        color: colorMapping[plate] || "grey", // Default to grey if no mapping is found
      }));
    },
  },
};
</script>

<style></style>
