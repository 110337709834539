<template>
    <v-row justify="space-between" align="center" no-gutters>
        <v-col cols="12" md="7" xl="5">
            <v-form v-if="userRole == 'Administrator' || userRole == 'Manager' || userRole == 'Moderator'" ref="form"
                :disabled="working">
                <v-text-field label=" Enter report name" density="compact" :rules="nameRules" v-model="reportName">
                    <template v-slot:prepend>
                        <div class="text-h6 px-6">{{ selected.length }} Selected</div>
                    </template>
                    <template v-slot:append>
                        <v-btn variant="tonal" color="primary" @click="validate">Generate Report</v-btn>
                    </template>
                </v-text-field>
            </v-form>
        </v-col>
        <v-col cols="12" md="3">
            <v-menu v-model="datePicker" :close-on-content-click="false" transition="scale-transition" location="bottom"
                :disabled="working" offset-y min-width="auto">
                <template v-slot:activator="{ props }">
                    <v-text-field v-model="dateRangeText" label="Select date range" prepend-icon="mdi-calendar" readonly
                        :disabled="working" v-bind="props"></v-text-field>
                </template>
                <v-date-picker multiple="range" v-model="dates" @update:model-value="dateChanged"
                    :disabled="working"></v-date-picker>
            </v-menu>
        </v-col>
    </v-row>
    <v-data-table :height="tableHeight" class="bg-navBar my-data-table" :headers="headers" :items="jobs"
        v-model:items-per-page="itemsPerPage" item-key="SO" :loading="working" v-model:page="page"
        @update:page="loadMore" item-selectable="selectable" fixed-header
        :items-per-page-options="[{ value: 5, title: '5' }, { value: 10, title: '10' }, { value: 20, title: '20' }, { value: 50, title: '50' }, { value: 100, title: '100' }]"
        item-value="name" hover return-object show-select v-model="selected" :items-per-page-text="footertext">

        <template v-slot:[`item.amount`]="{ item }">
            <div class="text-end">
                {{ item.amount }} USD
            </div>
        </template>

        <template v-slot:[`item.included`]="{ item }">
            <v-chip v-for="report in item.included" :key="report" color="success" size="small" class="text-caption">{{
                report }}</v-chip>
        </template>
    </v-data-table>
    <!-- //////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="generatingDialog" persistent>
        <v-card class="mx-auto" elevation="16" min-width="300" max-width="600"
            :title="'Generating Report ' + reportName" color="cards">
            <v-progress-linear class="mt-3" color="info" indeterminate></v-progress-linear>
            <div class="py-12 text-center">
                <v-icon class="mb-6" color="info" icon="mdi-sync" size="128"></v-icon>
                <div class="text-h6 font-weight-bold mx-16">Please wait while we generate your report...</div>
            </div>
        </v-card>
    </v-dialog>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import moment from "moment";
import DialogModal from "@/components/common/dialogModal";

import { firestore, charges } from "@/firebase";
import { collection, query, orderBy, limit, startAfter, getDocs, getAggregateFromServer, sum } from "firebase/firestore";
import { doc, where, getDoc } from "firebase/firestore";

export default {
    data: () => ({
        working: true,
        userRole: localStorage.userRole,
        tableHeight: window.innerHeight * 0.74,
        datePicker: false,
        dates: [],
        headers: [
            { title: 'Included', key: 'included', width: 300 },
            { title: 'Created', key: 'created', width: 110 },
            { title: 'Completed', key: 'modified', width: 100 },
            { title: 'Sales Order', key: 'SO' },
            { title: 'Job ID', key: 'jobID' },
            { title: 'PO', key: 'PO' },
            { title: 'Description', key: 'description', width: 300 },
            { title: 'Agent', key: 'agent' },
            { title: 'Customer', key: 'customer', width: 220 },
            { title: 'Factory', key: 'factory' },
            { title: 'Location', key: 'location' },
            { title: 'Total Mins', key: 'totalMins' },
            { title: 'Rate (USD)', key: 'rate' },
            { title: 'Billable Mins', key: 'billableMins' },
            { title: 'Amount', key: 'amount', width: 100 },
        ],
        factories: [],
        agents: [],
        locations: [],
        jobs: [],
        page: 1,
        itemsPerPage: 10,
        noMore: false,
        lastDoc: null,
        selected: [],
        footertext: "",
        reportName: null,
        nameRules: [
            (v) => !!v || "Report name is required",
            (v) => /^[^\s/]*$/.test(v) || 'No spaces, slashes or special characters allowed',
            (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
            (v) => (v && v.length >= 7) || "Name must be more than 7 characters",
        ],
        generatingDialog: false,
        dialog: false,
        dialogData: [
            {
                title: "",
                icon: "",
                text: "",
            },
        ],
        cache: {},
    }),
    components: {
        DialogModal,
    },
    computed: {
        pageCount()
        {
            return Math.ceil(this.jobs.length / this.itemsPerPage)
        },
        dateRangeText()
        {
            return moment(this.dates[0]).format(' DD MMM YYYY') + ' - ' + moment(this.dates[this.dates.length - 1]).format(' DD MMM YYYY');
        },
    },
    async created()
    {
        this.working = true;
        bus.emit("loadingState", true);
        this.userRole = localStorage.userRole;
        var todayDate = new Date();
        for (let i = 2; i >= 0; i--)
        {
            let dateValue = new Date();
            dateValue.setTime(todayDate.getTime() - 86400000 * i);
            this.dates.push(dateValue);
        }
        await this.getArrays("factories");
        await this.getArrays("agents");
        await this.getArrays("locations");
        this.setConditions();
    },
    methods: {
        dateChanged()
        {
            if (this.dates.length >= 2)
            {
                this.footertext = "";
                this.noMore = false;
                this.lastDoc = null;
                this.selected = [];
                this.jobs = [];
                this.setConditions();
            }
        },
        async getArrays(type)
        {
            const q = query(collection(firestore, type), orderBy("name"));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.docs.length === 0 || querySnapshot.empty == true)
            {
                return;
            } else
            {
                querySnapshot.forEach((doc) =>
                {
                    const data = {
                        name: doc.data().name,
                        id: doc.data().id,
                    };
                    this[type].push(data);
                });
                return;
            }
        },
        async setConditions()
        {
            this.working = true;
            bus.emit("loadingState", true);
            const jobsRef = collection(firestore, "jobs");
            let q;

            if (this.noMore == false && this.lastDoc == null)
            {
                q = await query(jobsRef, where("currState", "==", "completed"), where("modified", ">=", this.dates[0]), where("modified", "<=", this.dates[this.dates.length - 1]),
                    orderBy("modified", "desc"));
                this.getJobs(q);
            } else if (this.noMore == false && this.lastDoc != null)
            {
                q = await query(jobsRef, where("currState", "==", "completed"), where("modified", ">=", this.dates[0]), where("modified", "<=", this.dates[this.dates.length - 1]),
                    orderBy("modified", "desc"), startAfter(this.lastDoc));
                this.getJobs(q);
            } else
            {
                this.working = false;
                bus.emit("loadingState", false);
                this.footertext = "No more items to Load";
            }
        },
        async getJobs(q)
        {
            const querySnapshot = await getDocs(q);

            if (querySnapshot.docs.length === 0 || querySnapshot.empty == true)
            {
                this.noMore = true;
            } else
            {
                this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

                for (let i = 0; i < querySnapshot.docs.length; i++)
                {

                    const minutesArray = await this.getMins(querySnapshot.docs[i].data().SO);

                    const data = {
                        selectable: minutesArray[1] == 0 ? false : true,
                        SO: querySnapshot.docs[i].data().SO,
                        type: querySnapshot.docs[i].data().type,
                        description: querySnapshot.docs[i].data().description,
                        jobID: querySnapshot.docs[i].data().jobID.toString(),
                        PO: querySnapshot.docs[i].data().PO.toString(),
                        customer: await this.getName(querySnapshot.docs[i].data().customer, "customers"),
                        agent: await this.getFromArray("agents", querySnapshot.docs[i].data().agent),
                        factory: await this.getFromArray("factories", querySnapshot.docs[i].data().factory, "factories"),
                        location: await this.getFromArray("locations", querySnapshot.docs[i].data().location, "locations"),
                        created: this.formatDateTime(querySnapshot.docs[i].data().created, "DD MMM YYYY"),
                        modified: this.formatDateTime(querySnapshot.docs[i].data().modified, "DD MMM YYYY"),
                        totalMins: minutesArray[0],
                        rate: 0.42,
                        billableMins: minutesArray[1],
                        amount: parseFloat(minutesArray[1] * 0.42).toFixed(2),
                        included: querySnapshot.docs[i].data().included == undefined ? [] : querySnapshot.docs[i].data().included,
                    };
                    this.jobs.push(data);
                }
            }

            this.working = false;
            bus.emit("loadingState", false);
        },
        getFromArray(type, ID)
        {
            let foundObject = this[type].find(item => item.id === ID);
            if (foundObject)
            {
                return foundObject.name
            } else
            {
                return "error";
            }
        },
        async getName(document, collection)
        {
            if (!document)
            {
                return " - ";
            }

            // Check if the data is in the cache first
            if (this.cache[document])
            {
                return this.cache[document];
            }

            const docRef = doc(firestore, collection, document);
            try
            {
                const docSnap = await getDoc(docRef);
                if (!docSnap.exists())
                {
                    return " - ";
                }

                // Store the retrieved data in the cache
                this.cache[document] = docSnap.data().name;
                return this.cache[document];
            } catch (error)
            {
                this.dialogData = {
                    title: "error",
                    icon: "mdi-close-circle-outline",
                    text: error,
                    redirect: "/",
                };
                this.dialog = true;
            }
        },
        formatDateTime(timeStamp, format)
        {
            if (timeStamp != null || timeStamp != undefined)
            {
                var m = moment(timeStamp.toDate());
                var mFormatted = m.format(format);
                return mFormatted;
            } else
            {
                return " - ";
            }
        },
        loadMore()
        {
            if (this.page >= (this.pageCount - 1))
            {
                this.setConditions();
            }
        },
        async getMins(saleOrder)
        {
            try
            {
                const coll = collection(firestore, "jobs", saleOrder, "stages");
                const queryTotal = query(coll, where("stage", "in", ["vps", "artwork", "repro"]));
                const queryBillable = query(coll);
                const snapshotTotal = await getAggregateFromServer(queryTotal, {
                    totalMins: sum('mins')
                });
                const snapshotBillable = await getAggregateFromServer(queryBillable, {
                    totalMins: sum('mins')
                });
                return [parseInt(snapshotTotal.data().totalMins), parseInt(snapshotBillable.data().totalMins)];
            } catch (error)
            {
                return "ERROR";
            }
        },
        async validate()
        {
            const { valid } = await this.$refs.form.validate();
            if (valid && this.selected.length != 0) this.generateReport();
            else "No items selected";
        },
        async generateReport()
        {
            bus.emit("loadingState", true);
            this.generatingDialog = true;
            console.log(this.selected);
            await charges({
                report: this.reportName,
                selected: this.selected,
            }).then((result) =>
            {
                bus.emit("loadingState", false);
                this.generatingDialog = false;
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialogData.redirect = "/charges/reports/" + this.reportName;
                this.dialog = true;
            });
        }
    },
}
</script>

<style scoped>
.my-data-table {
    font-size: 12px;
    /* Adjust this value to your preference */
}

tr.v-data-table__selected {
    background: #7d92f5 !important;
}
</style>