<template>
  <v-form class="mt-4" ref="formOSL" :disabled="working">
    <v-row justify="center" align="center">
      <v-col cols="12" lg="12" xl="10">
        <v-card color="cards">
          <v-toolbar flat color="navBar" title="FILM OUTSOURCE LIST"><Completer :jobObject="jobObject" /></v-toolbar>
          <v-row justify="start" align="center" class="px-4 pt-4 d-flex flex-wrap" no-gutters>
            <v-col cols="auto" class="mx-3">
              <v-radio-group v-model="dieLines" inline label="Die Lines" :rules="requiredRule">
                <v-radio label="N/A" value="n/a"></v-radio>
                <v-radio label="Old" value="old" color="warn"></v-radio>
                <v-radio label="New" value="new" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="auto" class="mx-3">
              <v-radio-group v-model="emDeboss" inline label="Emboss & Deboss" :rules="requiredRule">
                <v-radio label="N/A" value="n/a"></v-radio>
                <v-radio label="Old" value="old" color="warn"></v-radio>
                <v-radio label="New" value="new" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="auto" class="mx-3">
              <v-radio-group v-model="foil" inline label="Foil" :rules="requiredRule">
                <v-radio label="N/A" value="n/a"></v-radio>
                <v-radio label="Old" value="old" color="warn"></v-radio>
                <v-radio label="New" value="new" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="auto" class="mx-3">
              <v-radio-group v-model="varnish" inline label="Varnish" :rules="requiredRule">
                <v-radio label="N/A" value="n/a"></v-radio>
                <v-radio label="Old" value="old" color="warn"></v-radio>
                <v-radio label="New" value="new" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="auto" class="mx-3">
              <v-radio-group v-model="specialGold" inline label="Special Gold" :rules="requiredRule">
                <v-radio label="N/A" value="n/a"></v-radio>
                <v-radio label="Old" value="old" color="warn"></v-radio>
                <v-radio label="New" value="new" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="auto" class="mx-3">
              <v-radio-group v-model="uvVarnishFlexo" inline label="UV Varnish Flexo" :rules="requiredRule">
                <v-radio label="N/A" value="n/a"></v-radio>
                <v-radio label="Old" value="old" color="warn"></v-radio>
                <v-radio label="New" value="new" color="success"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="auto" class="mx-3">
              <v-radio-group v-model="uvVarnishDigital" inline label="UV Varnish Digital" :rules="requiredRule">
                <v-radio label="N/A" value="n/a"></v-radio>
                <v-radio label="Old" value="old" color="warn"></v-radio>
                <v-radio label="New" value="new" color="success"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn v-if="jobObject.type == 'New Job'" class="px-4" @click="sendBackFunction" prepend-icon="mdi-arrow-collapse-left" variant="tonal"
            :loading="working" color="success">
              Send back to Repro
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="px-4" @click="validate" append-icon="mdi-arrow-collapse-right" variant="flat" :loading="working" color="success">
              Send for Imposition/VPS
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import DialogModal from "@/components/common/dialogModal";
import Completer from "@/components/job/completer";

import { firestore, storage, sendBack, addOSL } from "@/firebase";

export default {
  props: ["jobObject"],
  data: () => ({
    working: false,
    dialog: false,
    dialogData: [{
      title: "",
      icon: "",
      text: ""
    }],
    requiredRule: [(v) => !!v || "This is required"],
    dieLines: null,
    emDeboss: null,
    foil: null,
    uvVarnishFlexo: null,
    uvVarnishDigital: null,
    varnish: null,
    specialGold: null,
  }),
  components: {
    DialogModal,
    Completer
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.formOSL.validate();
      if (valid) this.addStageFun();
    },
    async addStageFun() {
      this.working = true;
      bus.emit("loadingState", true);
      this.dialogData.title = "Updating";
      this.dialogData.text = "Sending job for Imposition/VPS";
      this.dialogData.icon = "mdi-note-text";
      this.dialog = true;

      await addOSL({
        saleOrder: this.jobObject.saleOrder,
        dieLines: this.dieLines,
        emDeboss: this.emDeboss,
        foil: this.foil,
        uvVarnishFlexo: this.uvVarnishFlexo,
        uvVarnishDigital: this.uvVarnishDigital,
        varnish: this.varnish,
        specialGold: this.specialGold,
      }).then((result) => {
        bus.emit("loadingState", false);
        this.working = false;
        this.dialogData.title = result.data.response;
        this.dialogData.icon = result.data.icon;
        this.dialogData.text = result.data.message;
        this.dialogData.redirect = "/jobs/vps";
        this.dialog = true;
      });
    },
    async sendBackFunction() {
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = "Updating";
            this.dialogData.text = "Sending back to Repro";
            this.dialogData.icon = "mdi-backburger";
            this.dialog = true;

            await sendBack({
                saleOrder: this.jobObject.saleOrder,
            }).then((result) => {
                bus.emit("loadingState", false);
                this.working = false;
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialogData.redirect = "/jobs/repro";
                this.dialog = true;
            });
        },
  }

}
</script>

<style></style>