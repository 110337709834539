<template>
  <v-row justify="center">
    <v-col cols="12" md="3" xl="2">
      <v-select v-model="selectedType" :items="types" item-value="id" item-title="name" label="Select Type" outlined />
    </v-col>

    <v-col cols="12" md="6" xl="4">
      <v-file-input multiple v-model="files" label="Select Scanned PDF Files" accept="application/pdf"
        @change="handleFileSelection" @click:clear="clearFiles" />
    </v-col>

    <v-col cols="12" md="3" xl="2">
      <v-btn block color="primary" size="x-large" @click="uploadFiles" :disabled="isUploadDisabled">
        Upload Files
      </v-btn>
    </v-col>

    <v-col cols="12">
      <v-data-table :headers="headers" :items="fileTable" items-per-page="50"
        :items-per-page-options="paginationOptions" :mobile="$vuetify.display.mobile">
        <template v-slot:[`item.animation`]="{ item }">
          <v-card elevation="0">
            <LottieAnim :height="50" :width="230" :lottieFile="getAnimation(item.status)" />
          </v-card>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getStatusColor(item.status)" dark>{{ item.status }}</v-chip>
        </template>

        <template v-slot:[`item.progress`]="{ item }">
          <v-progress-linear v-if="item.status !== 'Uploaded'" color="primary" :indeterminate="item.progress" />
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" persistent>
      <DialogModal :dialog="dialogData" />
    </v-dialog>
  </v-row>
</template>

<script>
import bus from "vue3-eventbus";
import { firestore, uploadPurchaseInvoice } from "@/firebase";
import DialogModal from "@/components/common/dialogModal";
import LottieAnim from "@/components/common/lottieAnim";
import { doc, onSnapshot } from "firebase/firestore";

export default {
  data() {
    return {
      types: ["Plates", "Films", "Consumables", "Services"],
      selectedType: null,
      files: [],
      fileTable: [],
      headers: [
        { title: "File Name", value: "name", width: "100px" },
        { title: "", value: "animation", width: "520" },
        { title: "Status", value: "status", width: "300px" },
        { title: "Progress", value: "progress", width: "200px" },
      ],
      paginationOptions: [
        { value: 50, title: "50" },
        { value: 100, title: "100" },
        { value: 200, title: "200" },
      ],
      dialog: false,
      dialogData: {},
      uploading: false,
    };
  },
  components: {
    DialogModal,
    LottieAnim,
  },
  created() {
    bus.emit("loadingState", false);
  },
  computed: {
    isUploadDisabled() {
      return this.uploading || !this.files.length;
    },
  },
  methods: {
    handleFileSelection() {
      this.fileTable = this.files.map((file) => ({
        name: file.name,
        file,
        status: "Ready",
        progress: false,
      }));
    },
    clearFiles() {
      this.files = [];
      this.fileTable = [];
    },
    async uploadFiles() {
      try {
        this.uploading = true;

        const uploadPromises = this.fileTable.map((fileEntry, index) =>
          this.processFileUpload(fileEntry, index)
        );
        await Promise.all(uploadPromises);

        this.uploading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    async processFileUpload(fileEntry, index) {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            fileEntry.status = "Uploading";
            const docID = this.generateRandomKey();

            const monitorPromise = this.monitorUploadStatus(fileEntry, docID);
            const uploadPromise = this.uploadFile(fileEntry, docID);

            await Promise.all([monitorPromise, uploadPromise])
              .then(([monitorResult, uploadResult]) =>
                this.handleUploadSuccess(fileEntry, uploadResult, monitorResult)
              )
              .catch((error) => this.handleUploadError(fileEntry, error));

            resolve();
          } catch (error) {
            reject(error);
          }
        }, index * 20000); // 20 seconds delay for each file
      });
    },
    async uploadFile(fileEntry, docID) {
      const fileData = await this.readFileAsBase64(fileEntry.file);
      return uploadPurchaseInvoice({
        fileName: fileEntry.name,
        fileData,
        docId: docID,
        type: this.selectedType,
      });
    },
    handleUploadSuccess(fileEntry, uploadResult, monitorResult) {
      if (uploadResult.data.response === "success") {
        fileEntry.status = "Uploaded";
      } else {
        fileEntry.status = `Failed: ${uploadResult.data.message}`;
      }
      monitorResult.unsubscribe?.();
    },
    updateFileStatus(fileEntry, status, state) {
      fileEntry.progress = state;
    },
    async monitorUploadStatus(fileEntry, docID) {
      const uploadStatusRef = doc(firestore, "purchaseInvoices", docID);

      return new Promise((resolve, reject) => {
        const unsubscribe = onSnapshot(uploadStatusRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const status = docSnapshot.data().status || "In Progress";
            fileEntry.status = status;
          } else {
            this.retryMonitoring(fileEntry, docID, resolve, reject);
          }
        });

        resolve({ unsubscribe });
      });
    },
    retryMonitoring(
      fileEntry,
      docID,
      resolve,
      reject,
      retryCount = 0,
      maxRetries = 2
    ) {
      if (retryCount < maxRetries) {
        setTimeout(
          () =>
            this.monitorUploadStatus(fileEntry, docID)
              .then(resolve)
              .catch(reject),
          3000
        );
      } else {
        reject(new Error(`Max retries reached for document ID: ${docID}`));
      }
    },
    readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]); // Get base64 data
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    generateRandomKey() {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      return Array.from({ length: 20 }, () =>
        chars.charAt(Math.floor(Math.random() * chars.length))
      ).join("");
    },
    handleUploadError(fileEntry, error) {
      console.error("Upload failed:", error);
      this.updateFileStatus(
        fileEntry,
        "error",
        `Failed: ${error.message}`,
        false
      );
    },
    getAnimation(status) {
      switch (status) {
        case "Extracting using OpenAI":
          return "openAI";
        case "Extracting using Google":
          return "gemini";
        case "Checking for Duplicates":
        case "Verifying":
        case "Adding header to document":
        case "Saving PDF":
          return "verifying";
        case "Uploaded":
          return "tick";
        default:
          return "blank";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case "Extracting using OpenAI":
          return "primary";
        case "Extracting using Google":
          return "primary";
        case "Checking for Duplicates":
        case "Verifying":
        case "Adding header to document":
        case "Saving PDF":
          return "info";
        case "Uploaded":
          return "success";
        default:
          return "";
      }
    },
    handleError(error) {
      console.error("Error during upload:", error);
      this.uploading = false;
      this.dialogData = {
        title: "Error",
        icon: "mdi-close-circle-outline",
        text: error.message,
        redirect: "/",
      };
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.blue--text {
  color: blue !important;
}

.green--text {
  color: green !important;
}

.red--text {
  color: red !important;
}
</style>
