<template>
  <AddItem v-if="jobObject.state == 'artwork'" :jobObject="jobObject" />
  <ReproForm v-if="jobObject.state == 'repro'" :jobObject="jobObject" />
  <OSLForm v-if="jobObject.state == 'osl'" :jobObject="jobObject" />
  <VPSForm v-if="jobObject.state == 'vps'" :jobObject="jobObject" />
  <CTPForm v-if="jobObject.state == 'ctp'" :jobObject="jobObject" />
  <PlateoutForm
    v-if="jobObject.state == 'plateout'"
    :jobObject="jobObject"
    :uvVarnishFlexo="uvVarnishFlexo"
    :specialGold="specialGold"
    :plateOutExist="plateOutExist"
    :storeExist="storeExist"
  />
  <DispatchForm
    v-if="jobObject.state == 'dispatch'"
    :jobObject="jobObject"
    :slipID="slipID"
  />
  <CompletedForm
    v-if="jobObject.state == 'completed' || jobObject.state == 'charges'"
    :jobObject="jobObject"
  />
</template>

<script>
/* eslint-disable vue/no-unused-components */
import AddItem from "@/components/artwork/addItem";
import ReproForm from "@/components/offset_stages/reproForm";
import OSLForm from "@/components/offset_stages/oslForm";
import VPSForm from "@/components/offset_stages/vpsForm";
import CTPForm from "@/components/offset_stages/ctpForm";
import PlateoutForm from "@/components/offset_stages/plateoutForm";
import DispatchForm from "@/components/offset_stages/dispatchForm";
import CompletedForm from "@/components/offset_stages/completedForm";

export default {
  props: [
    "jobObject",
    "uvVarnishFlexo",
    "specialGold",
    "plateOutExist",
    "storeExist",
    "slipID",
  ],
  components: {
    AddItem,
    ReproForm,
    OSLForm,
    VPSForm,
    CTPForm,
    PlateoutForm,
    DispatchForm,
    CompletedForm,
  },
};
</script>

<style></style>
