<template>
    <section v-if="!isDark">
        <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 73 90" enable-background="new 0 0 100 100" xml:space="preserve" :height="height">
            <g>
                <path fill="none" d="M60.52,41.48h-4.59v14.89c1.44-0.33,2.97-0.77,4.59-1.36V41.48z" />
                <path fill="none" d="M34.67,50.56c-5.63-8.39-4.41-22.01,2.67-29.75c6.65-7.28,16.88-8.04,28.15-2.13V13.2
		c-4.99-1.75-9.71-2.62-14.05-2.62c-8.66,0-15.79,3.45-20.46,10.14c-7.11,10.21-6.69,25.35,0.97,34.46
		c2.92,3.48,8.53,7.91,17.97,7.65v-4.69C41.42,58.25,36.87,53.83,34.67,50.56z" />
                <path fill="none" d="M55.92,40.48h5.59v15.24l-0.33,0.12c-1.87,0.69-3.62,1.2-5.27,1.56v4.69c3.19-0.67,6.71-1.83,10.61-3.61V36.04
		H55.92V40.48z" />
                <path fill="#231F20" d="M49.28,35.04v6.44h0.64v15.66C41.86,57.23,37.57,53.08,35.5,50c-5.39-8.03-4.21-21.1,2.57-28.52
		c3.8-4.15,12.44-9.88,27.67-1.54l0.74,0.41V12.5l-0.33-0.12c-15.55-5.58-28.67-2.75-36,7.77c-7.36,10.56-6.91,26.23,1.02,35.68
		c4.43,5.27,10.62,7.94,17.95,7.94c0.26,0,0.53-0.02,0.79-0.03v12.4h-6.97l9.97,17.27l9.97-17.27h-6.97V63.05
		c3.6-0.72,7.39-1.97,11.32-3.8l0.29-0.13V35.04H49.28z M55.92,41.48h4.59v13.54c-1.63,0.58-3.15,1.02-4.59,1.36V41.48z
		 M31.95,55.18c-7.65-9.12-8.08-24.25-0.97-34.46c4.66-6.69,11.79-10.14,20.46-10.14c4.34,0,9.06,0.87,14.05,2.62v5.47
		c-11.28-5.91-21.5-5.14-28.15,2.13c-7.07,7.74-8.29,21.37-2.67,29.75c2.2,3.27,6.75,7.69,15.25,7.58v4.69
		C40.48,63.09,34.87,58.66,31.95,55.18z M66.53,58.48c-3.9,1.79-7.42,2.94-10.61,3.61V57.4c1.64-0.37,3.39-0.88,5.27-1.56l0.33-0.12
		V40.48h-5.59v-4.44h10.61V58.48z" />
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    </section>
    <section v-else>
        <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 73 90" enable-background="new 0 0 100 100" xml:space="preserve" :height="height">
            <path fill="none" d="M177.87,26.03h-4.59v14.89c1.44-0.33,2.97-0.77,4.59-1.36V26.03z" />
            <path fill="none" d="M152.02,35.11c-5.63-8.39-4.41-22.01,2.67-29.75c6.65-7.28,16.88-8.04,28.15-2.13v-5.47
	c-4.99-1.75-9.71-2.62-14.05-2.62c-8.66,0-15.79,3.45-20.46,10.14c-7.11,10.21-6.69,25.35,0.97,34.46
	c2.92,3.48,8.53,7.91,17.97,7.65v-4.69C158.78,42.8,154.22,38.38,152.02,35.11z" />
            <path fill="none" d="M173.28,25.03h5.59v15.24l-0.33,0.12c-1.87,0.69-3.62,1.2-5.27,1.56v4.69c3.19-0.67,6.71-1.83,10.61-3.61V20.59
	h-10.61V25.03z" />
            <path fill="#FFFFFF" d="M53.05,33.55v6.44h0.64v15.66c-8.06,0.09-12.35-4.05-14.42-7.13c-5.39-8.03-4.21-21.1,2.57-28.52
	c3.8-4.15,12.44-9.88,27.67-1.54l0.74,0.41v-7.85l-0.33-0.12c-15.55-5.58-28.67-2.75-36,7.77c-7.36,10.56-6.91,26.23,1.02,35.68
	c4.43,5.27,10.62,7.94,17.95,7.94c0.26,0,0.53-0.02,0.79-0.03v12.4h-6.97l9.97,17.27l9.97-17.27h-6.97V61.56
	c3.6-0.72,7.39-1.97,11.32-3.8l0.29-0.13V33.55H53.05z M59.69,39.99h4.59v13.54c-1.63,0.58-3.15,1.02-4.59,1.36V39.99z M35.72,53.69
	c-7.65-9.12-8.08-24.25-0.97-34.46c4.66-6.69,11.79-10.14,20.46-10.14c4.34,0,9.06,0.87,14.05,2.62v5.47
	c-11.28-5.91-21.5-5.14-28.15,2.13c-7.07,7.74-8.29,21.37-2.67,29.75c2.2,3.27,6.75,7.69,15.25,7.58v4.69
	C44.25,61.6,38.65,57.17,35.72,53.69z M70.3,56.99c-3.9,1.79-7.42,2.94-10.61,3.61v-4.69c1.64-0.37,3.39-0.88,5.27-1.56l0.33-0.12
	V38.99h-5.59v-4.44H70.3V56.99z" />
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    </section>
</template>

<script>
export default {
    props: ["height"],
    data() {
        return {
            isDark: false,
        }
    },
    created() {
        localStorage.darkmode == "true" ? this.isDark = true : this.isDark = false
    }

}
</script>