<template>
  <v-hover v-slot="{ isHovering, props }">
    <v-alert
      class="mb-2"
      border="start"
      border-color="cardColor"
      variant="tonal"
      :color="isHovering ? 'info' : cardColor"
      :subtitle="jobObject.customer"
      v-bind="props"
      :style="jobObject.open == 0 ? { opacity: 0.4 } : { opacity: 1 }"
      @click="openJob(jobObject)"
    >
      <v-row justify="space-between" align="center">
        <v-col cols="12" sm="12" lg="3">
          <p class="text-h6">
            <strong>{{ mainText }}</strong>
          </p>
          <p class="text-caption">{{ jobObject.customer }}</p>
          <p class="text-body-2"><strong>Agent: </strong>{{ jobObject.agent }}</p>
        </v-col>
        <v-col cols="12" sm="12" lg="3">
          <p class="text-body-2"><strong>Created on: </strong>{{ jobObject.created }}</p>
          <p class="text-body-2"><strong>Factory: </strong>{{ jobObject.factory }}</p>
          <p class="text-body-2">
            <strong>CtP Location: </strong>{{ jobObject.location }}
          </p>
        </v-col>
        <v-col cols="12" sm="12" lg="3" class="d-flex flex-column">
          <p class="text-body-2">
            <strong>Number of Items: </strong>{{ jobObject.items }}
          </p>
          <p class="text-body-2">
            <strong>Description: </strong>{{ jobObject.description }}
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="auto" align="end">
          <JobTag :jobType="jobObject.type" />
        </v-col>
      </v-row>
    </v-alert>
  </v-hover>
</template>

<script>
import router from "@/router";
import JobTag from "@/components/job/jobTag";

export default {
  props: ["jobObject", "cardColor"],
  components: {
    JobTag,
  },
  data: () => ({
    jobID: "",
    mainText: "",
    userRole: localStorage.userRole,
  }),
  created() {
    this.jobID = this.jobObject.jobID;
    this.mainText = "Job ID : " + this.jobID;
    this.userRole = localStorage.userRole;
  },
  methods: {
    openJob(jobObject) {
      router.push("/artworks/" + this.jobID);
    },
  },
};
</script>

<style></style>
