<template>
  <NoInternet v-if="connection" />
  <v-row v-if="!connection" justify="end" align="start">
    <v-col cols="auto">
      <v-btn color="primary" @click="createNew">Create New</v-btn>
    </v-col>
  </v-row>
  <NoContent
    v-if="empty"
    componentColor="primary"
    icon="mdi-alert-box"
    text="No Reports"
  />
  <Spinner v-if="working && reports.length == 0" componentColor="primary" />
  <v-row justify="center">
    <v-col cols="12" v-for="(report, index) in reports" :key="index">
      <v-card class="mx-auto" border flat color="cards">
        <v-card-text class="text-medium-emphasis pa-6">
          <v-row justify="space-between" align="center" no-gutters>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="report.name"
                :disabled="!report.editing"
                label="report Name"
                variant="outlined"
                :rules="[rules.required, rules.minLength(2)]"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="6" xl="4">
              <v-row
                v-if="report.scheduled"
                justify="space-between"
                align="center"
                no-gutters
              >
                <v-col> </v-col>
                <v-col cols="12">
                  <v-combobox
                    chips
                    multiple
                    variant="outlined"
                    label="Run on days"
                    bg-color="navBar"
                    :item-title="(day) => day.name"
                    :item-value="(day) => day.num"
                    v-model="report.runOnDays"
                    :items="days"
                    :disabled="!report.editing"
                  >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-col>
            <v-divider></v-divider>
          </v-row>
          <v-row justify="center" align="start">
            <v-col cols="12" md="5">
              <v-combobox
                chips
                multiple
                rounded
                variant="outlined"
                label="Email To"
                v-model="report.to"
                :disabled="!report.editing"
              >
                <template v-slot:selection="data">
                  <v-chip :selected="data.selected" class="chip-class" size="large">
                    {{ data.item.title }}
                  </v-chip>
                </template>
              </v-combobox>
              <v-combobox
                chips
                multiple
                rounded
                variant="outlined"
                label="Email CC"
                v-model="report.cc"
                :disabled="!report.editing"
              >
                <template v-slot:selection="data">
                  <v-chip :selected="data.selected" class="chip-class" size="large">
                    {{ data.item.title }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="7">
              <v-text-field
                rounded
                label="Email Subject"
                variant="outlined"
                v-model="report.subject"
                :disabled="!report.editing"
                :rules="[rules.required]"
              ></v-text-field>
              <v-row justify="space-between" align="center">
                <v-col cols="auto">
                  <v-switch
                    v-model="report.active"
                    inset
                    color="success"
                    :label="report.active ? 'ACTIVE' : 'DISABLED'"
                    :disabled="!report.editing"
                  ></v-switch> </v-col
                ><v-col cols="auto" v-if="report.scheduled">
                  <div class="text-overline">{{ report.note }}</div>
                  <div class="text-caption">Last ran on {{ report.lastRun }}</div>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="!report.editing"
                    color="primary"
                    variant="flat"
                    @click="editReport(report)"
                    >Edit</v-btn
                  >
                  <v-btn
                    v-else
                    color="primary"
                    variant="tonal"
                    @click="editReport(report)"
                    >Close</v-btn
                  >
                  <v-btn
                    class="ml-2"
                    color="success"
                    variant="flat"
                    @click="report.isNew ? saveNew(report) : updateReport(report)"
                    :disabled="!report.editing"
                  >
                    {{ report.isNew ? "Save" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import Spinner from "@/components/common/spinner";
import NoContent from "@/components/common/noJobs";
import NoInternet from "@/components/common/noInternet";
import DialogModal from "@/components/common/dialogModal";
import moment from "moment";

import { firestore } from "@/firebase";
import { collection, getDocs, doc, addDoc, updateDoc } from "firebase/firestore";

export default {
  data: () => ({
    working: true,
    connection: false,
    empty: false,
    reports: [],
    dialog: false,
    dialogData: {},
    rules: {
      required: (value) => !!value || "Required.",
      minLength: (min) => (value) => value.length >= min || `Min ${min} characters`,
    },
    days: [
      { name: "Sunday", num: 0 },
      { name: "Monday", num: 1 },
      { name: "Tuesday", num: 2 },
      { name: "Wednesday", num: 3 },
      { name: "Thursday", num: 4 },
      { name: "Friday", num: 5 },
      { name: "Saturday", num: 6 },
    ],
  }),
  components: {
    Spinner,
    NoContent,
    NoInternet,
    DialogModal,
  },
  created() {
    bus.emit("loadingState", true);
    this.getReports();
  },
  methods: {
    async getReports() {
      try {
        const reportRef = collection(firestore, "automation", "reports", "report");
        const reportSnapshot = await getDocs(reportRef);

        if (reportSnapshot.metadata.fromCache) {
          this.connection = true;
        } else if (reportSnapshot.empty) {
          this.empty = true;
        } else {
          this.reports = reportSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            lastRun: doc.data().lastRun
              ? this.formatDateWithTime(doc.data().lastRun.toDate())
              : "",
            editing: false, // Add editing state
          }));
        }
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      } finally {
        this.working = false;
        bus.emit("loadingState", false);
      }
    },
    editReport(report) {
      report.editing = !report.editing;
    },
    async updateReport(report) {
      if (!report.name || report.name.length < 2) {
        this.showDialog(
          "Error",
          "mdi-alert-circle-outline",
          "Name cannot be empty or less than 2 characters."
        );
        return;
      }
      if (!report.subject) {
        this.showDialog("Error", "mdi-alert-circle-outline", "Subject cannot be empty.");
        return;
      }

      this.showDialog("Updating", "mdi-cloud-sync", "Updating report details");
      try {
        this.working = true;

        // Get a reference to the report document
        const reportDocRef = doc(firestore, "automation", "reports", "report", report.id);

        // Update the report data in Firestore
        await updateDoc(reportDocRef, {
          name: report.name,
          to: report.to,
          cc: report.cc,
          subject: report.subject,
          active: report.active,
          runOnDays: report.runOnDays,
        });

        // Disable editing mode after successful update
        report.editing = false;
        this.dialog = false;

        bus.emit("loadingState", false);
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      } finally {
        this.working = false;
      }
    },
    createNew() {
      const newDoc = {
        name: "",
        to: [],
        cc: [],
        subject: "",
        active: true,
        editing: true,
        isNew: true,
      };
      this.reports.unshift(newDoc);
    },
    async saveNew(report) {
      if (!report.name || report.name.length < 2) {
        this.showDialog(
          "Error",
          "mdi-alert-circle-outline",
          "Name cannot be empty or less than 2 characters."
        );
        return;
      }
      if (!report.subject) {
        this.showDialog("Error", "mdi-alert-circle-outline", "Subject cannot be empty.");
        return;
      }

      this.showDialog("Saving", "mdi-cloud-sync", "Saving New report details");
      try {
        this.working = true;

        // Add the new report data to Firestore
        const reportRef = collection(firestore, "automation", "reports", "report");
        await addDoc(reportRef, {
          name: report.name,
          to: report.to,
          cc: report.cc,
          subject: report.subject,
          active: report.active,
        });

        // Disable editing mode after successful save
        report.editing = false;
        report.isNew = false;
        this.dialog = false;

        bus.emit("loadingState", false);
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      } finally {
        this.working = false;
      }
    },
    showDialog(title, icon, message, action = null) {
      this.dialogData = {
        title: title,
        icon: icon,
        text: message,
        redirect: action,
      };
      this.dialog = true;
    },
    formatDateWithTime(timeStamp) {
      var m = moment(timeStamp);
      var mFormatted = m.format("DD-MM-YYYY @ hh:mm a");
      return mFormatted;
    },
  },
};
</script>
