<template>
    <v-row justify="center" no-gutters>
        <v-col cols="12" lg="12" xl="12">
            <v-form ref="formHold">
                <v-text-field v-model="reason" :bg-color="jobObject.hold ? 'secondaryError' : 'secondarySuccess'"
                    variant="solo" :label="jobObject.hold ? 'Type note for releasing job' : 'Type reason for holding job'"
                    single-line :rules="holdRules">
                    <template v-slot:append-inner>
                        <v-btn class="mb-1" variant="flat" :color="jobObject.hold ? 'green-darken-4' : 'error'"
                            @click="validateHold" :text="jobObject.hold ? 'release job' : 'hold job'">
                        </v-btn>
                    </template>
                </v-text-field>
            </v-form>
        </v-col>
    </v-row>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import bus from "vue3-eventbus";
import DialogModal from "@/components/common/dialogModal";

import { firestore, storage, holdJob } from "@/firebase";

export default {
    props: ["jobObject"],
    components: {
        DialogModal,
    },
    data: () => ({
        sendData: null,
        reason: "",
        holdRules: [
            (v) => !!v || "Job reason is required",
            (v) =>
                (v && v.length >= 6 && v.length <= 250) ||
                "Must be between 6 to 250 characters",
        ],
        dialog: false,
        dialogData: [
            {
                title: "",
                icon: "",
                text: "",
            },
        ],
    }),
    methods: {
        async validateHold() {
            const { valid } = await this.$refs.formHold.validate();
            if (valid) this.holdJob();
        },
        async holdJob() {
            var text;
            if (this.jobObject.hold) {
                text = "Releasing ";
            } else {
                text = "Holding ";
            }
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = text;
            this.dialogData.icon = "mdi-lock-reset";
            this.dialogData.text = text + "Job";
            this.dialog = true;

            if (this.jobObject.state == 'artwork') {
                this.sendData = {
                    situation: "artworks",
                    docID: this.jobObject.jobID,
                    reason: this.reason,
                }
            } else {
                this.sendData = {
                    situation: "jobs",
                    docID: this.jobObject.saleOrder,
                    reason: this.reason,
                }
            }

            console.log(this.sendData);

            await holdJob(this.sendData).then((result) => {
                bus.emit("loadingState", false);
                this.working = false;
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialogData.redirect = "/";
                this.dialog = true;
            });
        },
    }

}
</script>

<style></style>