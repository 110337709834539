<template>
  <v-row justify="start">
    <v-col cols="12" md="12">
      <v-row justify="space-between">
        <v-col cols="auto">
          <v-btn class="mb-2" color="error" @click="deleteInvoices" variant="tonal">
            Delete
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="btnVisible">
          <v-btn class="mb-2" color="primary" @click="sendInvoices">
            Sign and Send
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="invoices" v-model:page="page" :loading="loading" item-key="docID"
        item-value="docID" class="elevation-1" @page-count="pageCount = $event"
        :items-per-page-options="[10, 20, 25, 50]" @click:row="onRowClick" show-select v-model="selectedInvoices"
        @update:page="loadMore" :mobile="this.$vuetify.display.mobile">
        <template v-slot:[`item.verifiedByName`]="{ item }">
          <span :class="getverifiedByNameClass(item)">{{ item.verifiedByName }}</span>
        </template>
        <template v-slot:[`item.totalAmount`]="{ item }">
          <span :class="getTotalAmountColorClass(item)">{{ item.totalAmount }}</span>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="slideUp" transition="dialog-bottom-transition" fullscreen>
      <v-card color="cards">
        <v-toolbar color="navBar">
          <v-btn icon="mdi-close" @click="onRowClose"></v-btn>

          <v-toolbar-title>{{ slideUpTitle }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn text="Delete" variant="text" color="error" @click="deleteInvoices()"></v-btn>
            <v-btn text="Sign and Send" variant="tonal" color="green" @click="sendInvoices"></v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-row no-gutters>
          <v-col cols="12" md="12">
            <ComPDFViewer :pdfUrl="selectedPdfUrl" />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleting" persistent>
      <v-card color="cards" class="mx-auto" max-width="300">
        <v-card-text class="text-h5 font-weight-bold text-center">
          <LottieAnim :height="300" :width="230" lottieFile="delete" />
          Deleting invoice
        </v-card-text>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sending" persistent>
      <v-card color="cards" class="mx-auto" max-width="300">
        <v-card-text class="text-h5 font-weight-bold text-center">
          <LottieAnim :height="300" :width="230" lottieFile="signature" />
          Signing and sending invoices
        </v-card-text>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </v-card>
    </v-dialog>
  </v-row>
  <v-dialog v-model="dialog" persistent>
    <DialogModal :dialog="dialogData" @close="dialog = false" />
  </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import moment from "moment";
import DialogModal from "@/components/common/dialogModal";
import ComPDFViewer from '@/components/common/pdf/comPDFViewer';
import LottieAnim from '@/components/common/lottieAnim';
import { firestore, storage, approvePurchaseInvoice, deleteInvoice } from "@/firebase";
import { query, where, orderBy, startAfter, limit } from "firebase/firestore";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";

export default {
  data() {
    return {
      userRole: localStorage.userRole,
      invoices: [],
      selectedInvoices: [],
      headers: [
        { title: "Purchase Order", value: "purchaseOrder" },
        { title: "Supplier Name", value: "supplierName" },
        { title: "Created", value: "created" },
        { title: "Created By", value: "createdBy" },
        { title: "Verified By", value: "verifiedByName" },
        { title: "Total", value: "totalAmount" },
        { title: "Currency", value: "currency" },
      ],
      page: 1,
      pageCount: 0,
      loading: false,
      imageUrls: [],
      imageLoading: false,
      showLightbox: false,
      lastDoc: null,
      noMore: false,
      cache: {},
      dialog: false,
      dialogData: {},
      slideUp: false,
      slideUpTitle: "",
      slideUpDocID: null,
      sending: false,
      deleting: false,
    };
  },
  components: {
    DialogModal,
    ComPDFViewer,
    LottieAnim,
  },
  computed: {
    totalSelectedSize() {
      return this.selectedInvoices.reduce((total, docID) => {
        const invoice = this.invoices.find((inv) => inv.docID === docID);
        return invoice && !isNaN(invoice.fileSize) ? total + invoice.fileSize : total;
      }, 0);
    },
    maxSizeReached() {
      return this.totalSelectedSize >= 20 * 1024 * 1024; // 20 MB limit
    },
    formattedTotalSelectedSize() {
      if (this.totalSelectedSize >= 1024 * 1024) {
        return (this.totalSelectedSize / (1024 * 1024)).toFixed(2) + " MB";
      } else if (this.totalSelectedSize >= 1024) {
        return (this.totalSelectedSize / 1024).toFixed(2) + " KB";
      } else {
        return this.totalSelectedSize + " bytes";
      }
    },
  },
  async mounted() {
    await this.fetchInvoices();
  },
  created() {
    this.userRole = localStorage.userRole;
    this.btnVisible = ["Administrator", "Manager"].includes(this.userRole);
  },
  methods: {
    async fetchInvoices() {
      if (this.noMore) return;

      this.loading = true;
      bus.emit("loadingState", true);

      let q = query(
        collection(firestore, "purchaseInvoices"),
        where("state", "==", "approve"),
        orderBy("created", "desc"),
        limit(300)
      );

      if (this.lastDoc) {
        q = query(q, startAfter(this.lastDoc));
      }

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        this.noMore = true;
      } else {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const newInvoices = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const createdByName = await this.getName(data.createdBy, "users");
            const verifiedByName = await this.getName(data.verifiedBy, "users");
            const fileSize = parseInt(data.fileSize);

            return {
              docID: doc.id,
              ...data,
              created: this.formatDateWithTime(data.created.toDate()),
              createdBy: createdByName,
              verifiedByName: verifiedByName,
              fileSize: isNaN(fileSize) ? 0 : fileSize,
            };
          })
        );

        this.invoices = [...this.invoices, ...newInvoices];
      }

      this.loading = false;
      bus.emit("loadingState", false);
    },
    async onRowClick(_, row) {
      this.slideUpTitle = "Purchase Order " + row.item.purchaseOrder;
      this.slideUpDocID = row.item.docId;
      this.selectedPdfUrl = row.item.PDF;
      this.selectedInvoices = [row.item.docId];
      this.slideUp = true;
    },
    onRowClose() {
      this.slideUp = false;
      this.slideUpDocID = null;
      this.selectedInvoices = null;
    },
    async sendInvoices() {
      if (this.maxSizeReached) {
        this.showDialog(
          "Error",
          "mdi-file-import",
          "The total size of " +
          this.formattedTotalSelectedSize +
          " selected invoices exceeds 20MB. Please reduce your selection.",
          "close"
        );
        return;
      }

      try {
        this.sending = true;

        const result = await approvePurchaseInvoice({
          selectedInvoices: this.selectedInvoices,
        });

        if (result.data.response === "success") {
          this.showDialog(
            "success",
            "mdi-check-circle-outline",
            "Invoices approved successfully.",
            "close"
          );
          this.invoices = [];
          this.onRowClose();
          this.noMore = false;
          this.page = 1;
          this.pageCount = 0;
          this.imageUrl = null;
          await this.fetchInvoices();
        } else {
          this.showDialog(
            "error",
            result.data.icon,
            result.data.message || "An error occurred while approving invoices.",
            "close"
          );
        }
      } catch (error) {
        console.error("Error during approval process:", error);
        this.showDialog(
          "error",
          "mdi-close-circle-outline",
          "An unexpected error occurred. Please try again.",
          "close"
        );
      }
    },
    async deleteInvoices() {
      try {
        this.deleting = true;

        const result = await deleteInvoice({
          invoiceType: "purchaseInvoices",
          selectedInvoices: this.selectedInvoices,
        });

        if (result.data.response === "success") {
          this.showDialog(
            "success",
            "mdi-check-circle-outline",
            result.data.message,
            "close"
          );
          this.invoices = [];
          (this.selectedInvoices = []), (this.lastDoc = null);
          this.noMore = false;
          this.page = 1;
          this.pageCount = 0;
          this.imageUrl = null;
          await this.fetchInvoices();
        } else {
          this.showDialog(
            "error",
            result.data.icon,
            result.data.message || "An error occurred while approving invoices.",
            "close"
          );
        }
      } catch (error) {
        console.error("Error during approval process:", error);
        this.showDialog(
          "error",
          "mdi-close-circle-outline",
          "An unexpected error occurred. Please try again.",
          "close"
        );
      }
    },
    async getName(document, collection) {
      if (!document) return " - ";

      if (document == "AI System") return document;

      if (this.cache[document]) return this.cache[document];

      const docRef = doc(firestore, collection, document);
      try {
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) return " - ";

        this.cache[document] = docSnap.data().name;
        return this.cache[document];
      } catch (error) {
        this.showDialog("Error", "mdi-alert-circle-outline", error.message, "reload");
      }
    },
    showDialog(title, icon, message, action) {
      this.sending = false;
      this.deleting = false;
      this.dialog = false;
      this.dialogData = {
        title: title,
        icon: icon,
        text: message,
        redirect: action,
      };
      this.dialog = true;
    },
    formatDateWithTime(timeStamp) {
      return moment(timeStamp).format("DD-MM-YYYY - hh:mm:ss a");
    },
    loadMore() {
      if (this.page >= this.pageCount - 1) {
        this.fetchInvoices();
      }
    },
    getTotalAmountColorClass(item) {
      const totalAmount = item.totalAmount ? parseFloat(String(item.totalAmount).replace(/,/g, '')) : 0;

      if (isNaN(totalAmount)) {
        return '';
      }
      if (item.currency === "LKR") {
        if (totalAmount < 50000) {
          return 'text-green';
        } else if (totalAmount >= 50000 && totalAmount < 100000) {
          return 'text-amber';
        } else if (totalAmount >= 100000) {
          return 'text-red';
        }
      } else if (item.currency === "USD") {
        if (totalAmount <= 166) {
          return 'text-green';
        } else if (totalAmount > 166 && totalAmount < 300) {
          return 'text-amber';
        } else if (totalAmount >= 300) {
          return 'text-red';
        }
      }
      return '';
    },
    getverifiedByNameClass(value){
      if(value.note){
        return "text-red";
      } else if(value.verifiedByName == "AI System"){
        return "text-green";
      } else {
        return "text-amber";
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84vh;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clickable-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
  display: block;
  margin: auto;
}

.text-green {
  color: green;
}

.text-amber {
  color: #ffbf00;
  /* Amber color */
}

.text-red {
  color: red;
}
</style>
