<template>
    <v-row justify="center" align="center">
        <v-col cols="11" xs="11" sm="11" md="8" lg="8" xl="6">
            <v-card color="cards">
                <v-row justify="space-between">
                    <v-col cols="9">
                        <v-row>
                            <v-col cols="3">
                                <vue-avatar class="ma-3" :size="100" :username="userName" />
                            </v-col>
                            <v-col cols="9">
                                <v-card-title class="text-h4">
                                    {{ userName }}
                                </v-card-title>
                                <v-card-subtitle>{{ userEmail }}</v-card-subtitle>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto">
                        <v-chip class="ma-3" color="secondary" density="compact" variant="flat">
                            {{ userRole }}
                        </v-chip>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-btn color="primary" variant="outlined" @click="sendPasswordReset" :loading="working">
                        Send password reset link
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            <v-card class="mt-3" color="cards" v-if="userRole">
                <v-card-title>Signature</v-card-title>
                <v-card-text>
                    <v-file-input v-model="fileInput" ref="fileInput" label="Upload Signature" accept="image/png"
                        @change="handleFileSelection" prepend-icon="mdi-upload" hint="Maximum png size 650 x 300 pixels" persistent-hint></v-file-input>
                    <div class="d-flex justify-center mt-4">
                        <div class="image-holder" v-if="signatureURL">
                            <v-img :src="signatureURL"></v-img>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="flat" @click="updateUser" :loading="working">
                        Update Signature
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent>
        <DialogModal :dialog="dialogData" @close="dialog = false" />
    </v-dialog>
</template>

<script>
import bus from "vue3-eventbus";
import { getAuth } from "firebase/auth";
import VueAvatar from "@webzlodimir/vue-avatar";
import DialogModal from "@/components/common/dialogModal";
import "@webzlodimir/vue-avatar/dist/style.css";
import { passwordReset, firestore, storage } from "@/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc, getDoc } from "firebase/firestore";

export default {
    data() {
        return {
            userEmail: localStorage.email,
            userRole: localStorage.userRole,
            userName: localStorage.userName,
            working: false,
            fileInput: null,
            signatureFile: null,
            signatureURL: '',
            dialog: false,
            dialogData: {
                title: "",
                icon: "",
                text: ""
            }
        };
    },
    components: {
        VueAvatar,
        DialogModal,
    },
    created() {
        bus.emit("loadingState", false);
        const auth = getAuth();
        const user = auth.currentUser;
        this.userEmail = user.email;

        if (user) {
            const userDocRef = doc(firestore, "users", user.uid);
            getDoc(userDocRef).then(async (docSnap) => {
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    if (userData.signatureURL) {
                        const storageRef = ref(storage, userData.signatureURL);
                        try {
                            const downloadURL = await getDownloadURL(storageRef);
                            this.signatureURL = downloadURL;
                            this.signatureFile = null;
                        } catch (error) {
                            console.error("Error getting download URL:", error);
                        }
                    }
                }
            }).catch((error) => {
                console.error("Error getting user document:", error);
            });
        }
    },
    methods: {
        handleFileSelection(event) {
            const file = event.target.files[0];
            if (file && file.type === "image/png") {
                const img = new Image();
                img.onload = () => {
                    if (img.width <= 650 && img.height <= 300) {
                        this.signatureFile = file;
                        this.signatureURL = URL.createObjectURL(file);
                    } else {
                        this.clearFileInput();
                        this.showDialog("mdi-move-resize", "Error", "Invalid dimensions. Image must be 650x300 pixels or smaller.");
                    }
                };
                img.onerror = () => {
                    this.clearFileInput();
                    this.showErrorDialog("mdi-file-image", "Failed to load image. Please try again.");
                };
                img.src = URL.createObjectURL(file);
            } else {
                this.clearFileInput();
                this.showErrorDialog("mdi-close-circle-outline", "Please upload a valid PNG file.");
            }
        },
        clearFileInput() {
            this.signatureFile = null;
            this.signatureURL = '';
            this.fileInput = null;
            if (this.$refs.fileInput) {
                this.$refs.fileInput.value = null;
            }
        },
        async updateUser() {
            this.working = true;
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                this.showErrorDialog("mdi-close-circle-outline", "No user is currently signed in.");
                return;
            }

            try {
                if (this.signatureFile) {
                    const storageRef = ref(storage, `users/${user.uid}/signature.png`);
                    const uploadTaskSnapshot = await uploadBytes(storageRef, this.signatureFile);
                    const filePath = uploadTaskSnapshot.ref.fullPath;

                    await updateDoc(doc(firestore, "users", user.uid), {
                        signatureURL: filePath
                    });

                    this.showDialog("mdi-check-circle-outline", "Success", "Signature uploaded and user profile updated successfully.");
                } else {
                    this.showDialog("mdi-close-circle-outline", "Error", "No signature file selected.");
                }
            } catch (error) {
                console.error('Error updating user:', error);
                this.showDialog("mdi-close-circle-outline", "Error", error.message);
            } finally {
                this.working = false;
                bus.emit("loadingState", false);
            }
        },
        async sendPasswordReset() {
            bus.emit("loadingState", true);
            this.working = true;

            try {
                const result = await passwordReset({ email: this.userEmail });
                this.showDialog(result.data.icon, result.data.response, result.data.message);
            } catch (error) {
                console.error(error);
                this.showDialog("mdi-close-circle-outline", "Error", error.message);
            } finally {
                bus.emit("loadingState", false);
                this.working = false;
            }
        },
        showErrorDialog(icon, message) {
            this.dialogData = {
                title: "Error",
                icon: icon,
                text: message,
                redirect: "reload"
            };
            this.dialog = true;
        },
        showDialog(icon, title, message) {
            this.dialogData = {
                title: title,
                icon: icon,
                text: message,
                redirect: "close"
            };
            this.dialog = true;
        }
    }
};
</script>


<style scoped>
.image-holder {
    background-color: #a6a6a6;
    width: 300px;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.image-holder img {
    max-width: 100%;
    max-height: 100%;
}
</style>
