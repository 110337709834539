<template>
    <v-row justify="end" class="ma-1">
        <v-col cols="auto">
            <v-btn large color="primary" prepend-icon="mdi-plus" @click="newClick">
                New Customer
            </v-btn>
        </v-col>
    </v-row>
    <v-row justify="center">
        <v-dialog v-model="dialogPopup" transition="dialog-bottom-transition" max-width="600">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                </v-toolbar>
                <v-list class="mx-4 mt-6">
                    <v-text-field label="Customer name" required v-model="name" :rules="nameRules"></v-text-field>
                    <v-text-field label="Code" required v-model="code" :rules="nameRules"></v-text-field>
                    <v-text-field label="Sales organization" required v-model="organization" :rules="nameRules"></v-text-field>
                </v-list>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-sheet :color="enabled ? 'success' : 'error'" class="rounded-xl">
                            <v-switch class="mx-8" :label="enabled ? 'Shown to users' : 'Not shown to users'" hide-details inset v-model="enabled"></v-switch>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-btn color="primary" @click="dialogBtn" variant="outlined" class="mx-16 my-6" :loading="working">
                    {{ buttonText }}
                </v-btn>
            </v-card>
        </v-dialog>
        <v-col cols="11" xs="11" sm="11" md="8" lg="11" xl="8">
            <v-data-table :headers="headers" :items="customersList" v-model:items-per-page="itemsPerPage"
                :items-per-page-options="[{ value: 10, title: '10' }, { value: 25, title: '25' }, { value: 50, title: '50' }]"
                @update:page="setConditions" item-value="name" class="elevation-1" hover>
                <template v-slot:[`item.enabled`]="{ item }">
                    <v-icon size="small" color="success" v-if="item.enabled">
                        mdi-circle
                    </v-icon>
                    <v-icon size="small" color="error" v-if="!item.enabled">
                        mdi-circle
                    </v-icon>
                </template>
                <template
                    v-slot:[`item.actions`]="{ item }">
                    <v-icon size="small" class="me-2" @click="editVariable(item)">
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Dialog //////// -->
    <v-dialog v-model="dialog">
        <DialogModal :dialog="dialogData" />
    </v-dialog>
</template>

<script>
import DialogModal from "@/components/common/dialogModal";
import bus from "vue3-eventbus";

import { firestore, addCustomers } from "@/firebase";
import { collection, query } from "firebase/firestore";
import { doc, setDoc, getDocs, updateDoc } from "firebase/firestore";
import { orderBy, limit, startAfter } from "firebase/firestore";

export default {
    data: () => ({
        working: false,
        dialogPopup: false,
        dialogTitle: "",
        dialog: false,
        dialogData: [{
            title: "",
            icon: "",
            text: ""
        }],
        buttonText: "",
        name: "",
        code: "",
        organization: "",
        enabled: true,
        nameRules: [
            (v) => !!v || "This is required",
            (v) => (v && v.length >= 2) || "Must be more than 1 characters",
        ],
        customersList: [],
        headers: [
            {
                align: "start",
                key: "name",
                sortable: true,
                title: "Name",
            },
            { title: "Code", key: "code", sortable: true },
            { title: "Sales Organization", key: "organization", sortable: true },
            { title: "Enabled", key: "enabled", sortable: false },
            { title: "Actions", key: "actions", sortable: false },
        ],
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        lastDoc: null,
        noMore: false,
        editID: null,
    }),
    components: {
        DialogModal,
    },
    created() {
        this.working = true;
        bus.emit("loadingState", true);
        this.dialogTitle = "New Customer";
        this.noMore = false;
        this.lastDoc = null;
        this.customersList = [];
        this.setConditions();
    },
    methods: {
        newClick() {
            this.dialogTitle = "New Customer";
            this.name = "";
            this.code = "";
            this.organization = "";
            this.enabled = true;
            this.buttonText = "Add";
            this.dialogPopup = true;
        },
        async setConditions() {
            const variableRef = collection(firestore, "customers");
            let q;

            if (this.noMore == false && this.lastDoc == null) {
                q = await query(variableRef, orderBy("name"), limit(50));
                this.getVariables(q);
            } else if (this.noMore == false && this.lastDoc != null) {
                q = query(
                    variableRef,
                    orderBy("name"),
                    startAfter(this.lastDoc),
                    limit(50)
                );
                this.getVariables(q);
            } else {
                console.log("No more");
            }
        },
        async getVariables(q) {
            const querySnapshot = await getDocs(q);

            if (querySnapshot.docs.length === 0 || querySnapshot.empty == true) {
                this.noMore = true;
            } else {
                this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc) => {
                    const data = {
                        name: doc.data().name,
                        code: doc.data().code,
                        organization: doc.data().organization,
                        enabled: doc.data().enabled,
                        id: doc.data().id,
                    };
                    this.customersList.push(data);
                });
            }

            this.working = false;
            bus.emit("loadingState", false);
        },
        dialogBtn() {
            if (this.dialogTitle == "Edit Customer") {
                this.addCustomersFunc("Updating", this.editID);
            } else {
                this.addCustomersFunc("Adding", null);
            }
        },
        async addCustomersFunc(nameType, ID) {
            this.working = true;
            bus.emit("loadingState", true);
            this.dialogData.title = nameType;
            this.dialogData.text = nameType + " Variable";
            this.dialogData.icon = "mdi-backburger";
            this.dialog = true;

            await addCustomers({
                id: ID,
                name: this.name.toLocaleUpperCase(),
                code: this.code,
                organization: this.organization.toLocaleUpperCase(),
                enabled: this.enabled,
            }).then((result) => {
                this.dialogData.title = result.data.response;
                this.dialogPopup = false;
                this.name = "";
                this.code = "";
                this.organization = "";
                this.enabled = true;
                this.noMore = false;
                this.lastDoc = null;
                this.customersList = [];
                this.setConditions();
                this.dialogData.title = result.data.response;
                this.dialogData.icon = result.data.icon;
                this.dialogData.text = result.data.message;
                this.dialog = true;
                setTimeout(() => this.dialog = false, 3000);
            });
        },
        editVariable(item) {
            this.dialogTitle = "Edit Customer";
            this.buttonText = "Update";
            this.name = item.name;
            this.code = item.code;
            this.organization = item.organization;
            this.enabled = item.enabled;
            this.dialogPopup = true;
            this.editID = item.id;
        },
    },
};
</script>

<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform 0.2s ease-in-out;
}
</style>