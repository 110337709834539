<template>
    <v-row justify="center">
        <v-col>
            <div class="py-12 text-center">
                <v-icon class="mb-6" color="error" icon="mdi-wifi-off" size="128"></v-icon>
                <div class="text-h4 font-weight-bold">Problem connecting</div>
                <div class="text-h6">to server. please check your internet connection</div>
                <div class="text-body-1">or try reloading the page</div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {

}
</script>

<style></style>