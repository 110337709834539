import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import eventBus from "vue3-eventbus";

import Vue3Lottie from 'vue3-lottie'
import VueGtag from "vue-gtag";
import Toast from "vue3-toastify";
import VueApexCharts from 'vue3-apexcharts';

loadFonts();

createApp(App)
  .use(router)
  .use(vuetify)
  .use(eventBus)
  .use(Vue3Lottie)
  .use(VueGtag, {
    config: { id: "G-17YWJSVDCY" }, // measurement ID - G-TS2C8NPF0Q
  })
  .use(Toast, {
    autoClose: 6000,
    position: 'top-right',
    theme: "colored",
  })
  .use(VueApexCharts)
  .mount("#app");
